@import '/src/theme/colors';
.edit_module-modal {
  &-button {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: $primaryColor;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &-icon {
      font-size: 24px;
    }
    &-text {
      font-size: 14px;
    }
  }
  &-button:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  &-content {
    border-radius: 10px;
    max-height: 80%;
    overflow-y: scroll;
    color: black;
    display: flex;
    flex-direction: column;
    background-color: rgb(232 235 240);
    padding: 30px;
    width: 50%;
    max-width: 400px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
    &-close_button {
      color: rgb(184 187 202);
      text-align: end;
      cursor: pointer;
      &:hover {
        color: grey;
      }
    }
    &-title {
      font-weight: bolder;
      font-size: 20px;
      margin-block-end: 10px;
    }
    &-submit {
      margin-block-start: 10px;
      color: #fff;
      background-color: $primaryColor;
      font-size: 14px;
      padding-inline: 10px;
      padding-block: 5px;
      border-radius: 5px;
      border: 1px solid $primaryColor;
      cursor: pointer;
    }
    
  }
  &-content input {
    margin: 0;
    margin-block-end: 15px;
    background: white;
    padding: 10px 15px;
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
  }
  &-content label {
    font-weight: bold;
    font-size: 14px;
    margin-block-end: 5px;
  }
}
@media screen and (max-width: 450px) {
  .modal-content {
    width: 80%;
  }
}

.inputfile-box {
  position: relative;
}

.inputfile {
  display: none;
}

.container {
  display: inline-block;
  width: 100%;
}

.file-box {
  font-weight: lighter;
  font-size: 15px;
  display: inline-block;
  width: 100%;
  padding: 5px 0px 5px 5px;
  box-sizing: border-box;
  height: 35px;
  background: white;
}

.file-button {
  background: rgb(137 147 205);
  padding-inline: 10px;
  padding-block: 8px;
  position: absolute;
  top: 5px;
  right: 0px;
}

.dropdown-container {
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  .input-dropdown {
    margin: 0;
    background: white;
    padding: 10px 15px;
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
      &:first-child {
        flex: 1;
      }

      &:last-child {
        margin-left: 8px; /* Adjust margin as needed */
      }
    }
  }

  .dropdown-list {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    z-index: 9999;
    .dropdown-item {
      text-align: center;
      padding: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
#icon-pencil {
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s, color 0.2s;
}

#icon-pencil:hover {
  transform: scale(1.2);
  color: #007bff;
}


@media screen and (max-width: 600px) {
  .edit_module-modal {
    &-content {
      width: 85%;
      max-height: 80%;
      padding: 5%;
      &-close_button{
        font-size: 14px;
      }
      &-title{
        font-size: 18px;
      }
    }
  }
}