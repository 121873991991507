@import '/src/theme/colors';
.mock_test_quiz {
  box-shadow: 1px 7px 11px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 5%;
  margin-block-start: 10%;
  height: max-content;
  overflow-y: auto;
  width: inherit;
  color: $fontColor;
  &-title {
    width: 100%;
    padding: 3%;
    background-color: #edeffc;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    &-text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80%;
      font-weight: 600;
      font-size: 28px;
    }
    &-details {
      font-size: 14px;
      width: 100%;
      height: 20%;
      display: flex;
      align-items: center;
      // justify-content: space-around;
      justify-content: end;
    }
  }
  &-body {
    background-color: white;
    border-radius: 10px;
    padding: 5%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    &-top {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-module_name {
        color: #5e5e5e;
        font-weight: bolder;
        font-size: 16px;
      }
      &-question {
        font-weight: bold;
        font-size: 14px;
      }
      &-answer {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 5px;
        &-option {
          display: flex;
          align-items: center;
        }
        &-option input[type="radio"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: #edeffc;
          padding: 7px 7px;
          margin: 10px 20px;
          width: 15px;
          height: 15px;
          border: 2px solid $primaryColor;
          border-radius: 50%;
          outline: none;
          margin-right: 8px;
          cursor: pointer;
          vertical-align: middle;
        }
        &-option input[type="radio"]:checked {
          background-color: $primaryColor;
        }
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      &-progress {
        width: 90%;
        display: flex;
        align-items: center;
        &-container {
          height: 10px;
          width: 100%;
          border-radius: 10px;
          background-color: #edeffc;
          &-filler {
            height: 100%;
            // width: 30%;
            border-radius: 10px;
            background-color: $primaryColor;
            border-radius: "inherit";
            text-align: right;
          }
        }
      }
      &-button {
        display: flex;
        justify-content: end;
        gap: 5px;
      }
      &-button button {
        // flex-wrap: wrap;
        display: flex;
        color: #fff;
        background-color: $primaryColor;
        font-size: 12px;
        font-weight: bold;
        border-radius: 5px;
        border: 1px solid $primaryColor;
        outline: none;
        letter-spacing: 1px;
        cursor: pointer;
        padding-inline: 10px;
        padding-block: 5px;
      }
    }
  }
}
