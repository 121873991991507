.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.card {
  height: 300px;
  width: 100%;
  //   width: 500px;
  position: relative;
  border-radius: 10px;
}

.cardFront,
.cardBack {
  font-size: 22px;
  font-weight: 500;
  padding: 10%;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  transition: transform 0.5s ease;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #05177d;
  overflow-y: auto;
}

.cardBack {
  background-color: #edeffc;
  transform: perspective(1000px) rotateY(180deg);
  font-size: 20px;
  display: flex;
  align-items: center;
}

.cardBack.flipped {
  transform: perspective(1000px) rotateY(0deg);
}

.cardFront {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #2f386a;
  transform: perspective(1000px) rotateY(0deg);
}

.cardFront.flipped {
  transform: perspective(1000px) rotateY(-180deg);
}

.card-content {
  text-align: center;
  max-height: 90%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
}

.card-icon {
  position: absolute;
  bottom: 25px;
  right: 25px;
  color:white;
}
