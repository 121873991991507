@import "/src/theme/colors";
.admin-credits {
  &-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: $titleColor;
    font-weight: 600;
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-purchase {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 10px;
      padding: 2%;
      &-title {
        margin-bottom: 35px;
        &-top {
          font-size: 18px;
          color: black;
          font-weight: 500;
        }
        &-bottom {
          font-size: 11px;
          color: #a3a5b9;
          // font-weight: 500;
        }
      }
      &-body {
        display: flex;
        justify-content: space-between;
        &-left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-block-end: 10px;
          &-ques {
            font-weight: 500;
            font-size: 15px;
            color: #060606;
          }
          &-input {
            display: flex;
            align-items: center;
            gap: 15px;
            &-box {
              width: 30%;
            }
            &-box input {
              border: 1px solid rgb(5 23 125);
              background: white;
              margin: 0;
              text-align: center;
              color: rgb(2 2 2);
              font-size: 18px;
              font-weight: 500;
              padding: 0;
              padding-block: 4px;
            }
            &-text {
              width: 60%;
              color: black;
              font-weight: 500;
              font-size: 14px;
            }
          }
          &-summary {
            display: flex;
            margin-block: 5px;
            gap: 10px;
            &-key {
              color: #05177d;
              font-weight: 500;
            }
            &-value {
              color: #faa70e;
              font-weight: 500;
            }
          }
        }
        &-right {
          padding-inline-end: 20px;
          &-text {
            text-align: center;
            font-weight: 600;
            font-size: 32px;
          }
          &-value {
            text-align: center;
            color: #faa70e;
            font-weight: 600;
            font-size: 40px;
          }
        }
      }
      &-bottom {
        &-button {
          width: 140px;
          height: 30px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          background-color: $primaryColor;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          &-icon {
            font-size: 24px;
          }
          &-text {
            font-size: 14px;
          }
        }
      }
    }
    &-history {
      background-color: white;
      border-radius: 10px;
      padding: 0;
      overflow: auto;
      &-table {
        min-width: 560px;
        font-size: 14px;
        &-title {
          font-size: 16px;
          font-weight: 600;
          color: black;
          border-bottom: 2px solid #f2f2f2;
          padding-block: 15px;
          padding-inline: 15px;
        }
        &-head {
          font-weight: 500;
          color: #5a5a5a;
          border-bottom: 2px solid #f2f2f2;
          padding-block: 15px;
          padding-inline: 15px;
          display: flex;
          &-item {
            flex: 1;
          }
          &-item:nth-child(1) {
            flex: 0.5;
          }
          &-item:nth-child(2) {
            flex: 1;
          }
          &-item:nth-child(3) {
            flex: 2;
          }
          &-item:nth-child(4) {
            flex: 1.5;
          }
        }
        &-body {
          font-weight: 500;
          color: #5a5a5a;
          &-row {
            display: flex;
            border-bottom: 2px solid #f2f2f2;
            font-weight: 500;
            color: #5a5a5a;
            padding-block: 15px;
            padding-inline: 15px;
            &-item {
              flex: 1;
            }
            &-item:nth-child(1) {
              flex: 0.5;
            }
            &-item:nth-child(2) {
              flex: 1;
            }
            &-item:nth-child(3) {
              flex: 2;
            }
            &-item:nth-child(4) {
              flex: 1.5;
            }
          }
          &-empty{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &-message{
              font-weight: 500;
            }
            & p{
              margin-block-start: 5px;
              color: black;
            }
            & img {
              max-width: 100px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .admin-credits {
    &-title {
      font-size: 20px;
    }
    &-body {
      &-purchase {
        padding: 5%;
        &-body {
          &-left {
            &-ques {
              font-size: 16px;
            }
          }
          &-right {
            display: none;
            &-text {
              font-size: 22px;
            }
            &-value {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}
