.bar-graph-container {
}

.bar-graph {
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: flex-end;
}

.grid-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.grid-line {
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  height: 20%;
  &-value {
    position: absolute;
    bottom: -12px;
    left: -30px;
    font-weight: 500;
    color: black;
    font-size: 18px;
  }
  &:nth-child(1) {
    height: 0%;
  }
  &:nth-child(2) {
    height: 20%;
  }
  &:nth-child(3) {
    height: 40%;
  }
  &:nth-child(4) {
    height: 60%;
  }
  &:nth-child(5) {
    height: 80%;
  }
  &:nth-child(6) {
    height: 100%;
  }
}

.bars {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: flex-end;
  height: 100%;
}

.bar {
  z-index: 1;
  width: 40px;
  background-color: #fff0d3;
  border: 1px solid #fdb01b;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  font-weight: bold;
  border-radius: 25px 25px 0 0;
  position: relative;
  height: 0;
  transition: height 1s ease-out;

  &-key {
    color: black;
    font-weight: 500;
    transform: rotate(-90deg);
    position: absolute;
    bottom: -85px;
    text-align: end;
    color: black;
    font-weight: 500;
    width: 90px;
  }
  &-value {
    position: absolute;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: orange;
  }
}
.bar.loaded {
  height: auto;
}
