@import '/src/theme/colors';
.policy_admin-navbar {
  
  box-shadow: 1px 7px 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 100vh;
  width: 200px;
  color: $fontColor;
  background-color: #fff;
  // padding: 100px 0;
  overflow-y: scroll;
  // animation: fade-in 500ms forwards;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-logo{
      width: 100%;
      padding: 20px 25px;
      &-img img {
        width: 100%;
        padding-block-start: 5px;
      }
      &-tab{
        display: none;
      }
    }
    &-tab {
      border-radius: 5px;
      padding: 1rem;
      display: flex;
      cursor: pointer;
      height: 72px;
      &:hover {
        background-color: $buttonHoverColor;
      }
      &.selected {
        background-color: $secondaryColor;
        color: $fontColor;
      }
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        font-size: 25px;
      }
      &-icon img {
        width: 100%;
      }
      &-text {
        display: flex;
        align-items: center;
        font-weight: 500;
        width: 80%;
        gap: 10px;
        font-size: 20px;
        padding-left: 24px;
      }
      &-dropdown {
        display: flex;
        flex-direction: column;

        &-item {
          justify-content: center;
          border-radius: 5px;
          font-weight: 500;
          padding: 1rem;
          display: flex;
          cursor: pointer;
          font-size: 20px;
          &:hover {
            background-color: rgb(228 231 245);
          }
          &.selected {
            background-color: rgb(228 231 245);
          }
          span {
            padding-left: 10px;
          }
        }
      }
    }
  }
}


@keyframes fade-in {
  0%{
    transform: scaleX(0);
  }
  100%{
    transform: scaleX(100%);
  }
}
@media screen and (min-width: 500px) and (max-width: 820px) {
  .policy_admin-navbar {
    max-width: 65px;
    padding: 0 10px;
    &-content {
      gap: 25px;
      padding-block-start: 10px;
      &-logo{
        padding: 5px 0 20px;
        &-img{
          display: none;
        }
        &-tab{
          display: block;
          width: 100%;
          img{
            width: 100%;
          }
        }
      }
      &-tab {
        padding: 0;
        flex-direction: column;
        &-icon {
          width: 100%;
          font-size: 25px;
        }
        &-text {
          width: 100%;
          padding: 0;
          font-size: 10px;
          justify-content: center;
          gap: 2px;
        }
        &-dropdown {
          gap: 10px;
          &-item {
            padding: 0;
            flex-direction: column;
            svg {
              width: 100%;
              font-size: 25px;
            }
            span {
              text-align: center;
              width: 100%;
              padding: 0;
              font-size: 10px;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 500px) 
{
  .policy_admin-navbar {
    background-color: rgb(255 255 255 / 95%);
    position: fixed;
    max-width: 200px;
    z-index: 1;
    padding: 10px;
    left: -65%;
    transition: 0.5s ease-in-out;
    &-content{
      &-logo{
        display: none;
      }
      &-tab{
        flex-direction: row;
        &-icon{
          font-size: 30px;
        }
        &-text{
          font-size: 16px;
          justify-content: flex-start;
        }
        &-dropdown{
          &-item{
            svg{
              font-size: 18px;
            }
            span{
              font-size: 16px;
            }

          }
        }
      }
    }
  }
  .show-navbar{
    left: 0;
  }
}
