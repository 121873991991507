.stripe_setup_session{
    display: flex;
    flex-direction: column;
    justify-content: center;
    button{
        width: 140px;
        height: 30px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: #579bd6;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }
}