@import './colors';
.primary-button{
    font-weight: 600;
    width: 90px;
    height: 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: $primaryButtonColor;
    color: #ffffff;
}
.secondary-button{
    font-weight: 600;
    width: 90px;
    height: 35px;
    border-radius: 4px;
    cursor: pointer;
    color: $primaryButtonColor;
    border: 1px solid $primaryButtonColor;
    background: none;
}
.tertiary-button{
    font-weight: 600;
    width: 90px;
    height: 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: $primaryColor;
    color: #ffffff;
}
.start-button{
    width: 250px;
    height: 40px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    gap: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ffffff;
    background: $startButtonColor;
    cursor: pointer;
}
.back-button{
    font-weight: 600;
    width: 90px;
    height: 35px;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    border: 1px solid $backButtonColor;
    color: $backButtonColor;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.finish-button{
    font-weight: 600;
    width: 90px;
    height: 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: $finishButtonColor;
    color: #ffffff;
}
.disabled-button{
    font-weight: 600;
    width: 90px;
    height: 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #95A0AF;
    color: #ffffff;
}