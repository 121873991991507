@import "/src/theme/colors";
.password-reset {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  &-logo {
    padding: 10px;
    display: flex;
    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
    }
    &-img img {
      height: 100%;
      width: 100px;
    }
    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 22px;
      color: $fontColor;
      &-text {
        font-size: 22px;
        font-weight: 600;
      }
      &-subtext {
        font-weight: 300;
        font-size: 22px;
        letter-spacing: 1px;
      }
    }
  }

  &-body {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
    color: $primaryColor;
    border-radius: 10px;
    margin: 40px auto;
    max-width: 585px;
    padding: 70px;
    display: flex;
    flex-direction: column;
    &-title {
      display: flex;
      justify-content: center;
      font-size: 30px;
      margin-block-end: 10px;
      font-weight: 600;
    }
    &-text {
      &-top {
        color: $tertiaryColor;
        font-weight: 5 00;
        text-align: center;
        margin-bottom: 10px;
        font-size: 20px;
      }
      &-bottom {
        color: black;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
  }

  &-body input {
    margin: 0;
    margin-bottom: 10px;
    background-color: #edeffc;
    padding: 10px 15px;
    width: 100%;
    border-radius: 5px;
    border: none;
  }
  &-body button {
    color: #fff;
    background-color: $primaryColor;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $primaryColor;
    cursor: pointer;
  }
  &-error {
    text-align: center;
    margin-block-end: 8px;
    color: red;
  }
}

@media screen and (max-width: 820px) {
  .password-reset {
    &-body {
      width: 500px;
      padding: 50px;
      margin: 15px auto;
      &-title {
        font-size: 24px;
      }
      &-text-top {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .password-reset {
    &-body {
      width: 85%;
      padding: 30px;
      margin: 10px auto;
      &-title {
        font-size: 20px;
        margin-block-end: 5px;
      }
      &-text {
        &-top {
          font-size: 16px;
        }
        &-bottom {
          font-size: 14px;
        }
      }
    }
    &-body button {
      font-size: 12px;
    }
    &-logo {
      justify-content: center;
    }
  }
}
