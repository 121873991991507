.app-scorm_popup{
    // display: none;
    width: 90%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    background: #fafbff;
    padding: 20px;
    box-shadow: 1px 7px 10px 1px rgba(0, 0, 0, 0.1);
    transition: 1s;
}