@import '/src/theme/colors';

@property --progress-value {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

@property --progress-color {
  syntax: "<color>";
  initial-value: #ff8612;
  inherits: false;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $fontColor;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(closest-side,rgb(226 241 255) 79%, transparent 80% 100%),
              conic-gradient(var(--progress-color) calc(var(--progress-value) * 1%), #ccc 0);
}
@media screen and (max-width: 820px) {
  .progress-bar{
    width: 80px;
    height: 80px;
  }
}
