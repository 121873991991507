.app-pdf-viewer {
  box-shadow: 1px 1px 9px 1px #0000002b;
  border-radius: 0 0 10px 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  &-loader {
    height: 500px;
  }
  &-controls {
    position: relative;
    width: fit-content;
    bottom: 5%;
    left: 50%;
    background: white;
    // opacity: 0;
    transform: translate(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 1px 1px 9px 1px #0000002b;
    border-radius: 10px;
    z-index: 2;
    span {
      padding: 0 10px;
    }
  }
  &-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 10px;
  }
}
// .app-pdf-viewer:hover .app-pdf-viewer-controls{
//     opacity: 1;
// }
.app-pdf-viewer-controls button:enabled:hover,
.app-pdf-viewer-controls button:enabled:focus {
  background-color: #e6e6e6;
}
.app-pdf-viewer-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.app-pdf-viewer-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.app-pdf-viewer .react-pdf__Document .react-pdf__Page .react-pdf__Page__canvas {
  padding: 10px;
}
