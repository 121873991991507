.slider-container{
    display: flex;
    justify-content: center;
}

.slider-container .container {
    // padding-left: 0 !important;
    padding-inline: 5%;
  }

.slick-dots{
    margin-bottom: 10px;
}
@media screen and (max-width:600px) {
    .slider-container .container {
        padding: 3%;
      } 
}

@media screen and (max-width:450px) {
    .slider-container .container {
        padding: 2%;
      } 
}

@media screen and (max-width:375px) {
    .slider-container .container {
        padding: 0;
      }
  }