@import '/src/theme/colors';
.view_answer_popup-button{
  margin-top: 10px;
  font-size: 14px;
  padding: 5px 5px;
  cursor: pointer;
  text-decoration: underline;
}

.view_answer_popup {
  &-link {
    color: $fontColor;
    text-decoration: underline;
    cursor: pointer;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }
  &-body {
    max-height: 80%;
    overflow: auto;
    width: 400px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    z-index: 1001;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
    &-module_name{
      color: grey;
      text-align: left;
      font-size: 15px;
      margin-block-end:2px;
      font-weight: 600;
    }
    &-question {
      font-weight: bolder;
      font-size: 14px;
      margin-block-end: 10px;
      color: black;
      text-align: left;
    }
    &-answers{
      max-height: 380px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c0bbbb;
        border-radius: 4px;
      }
      &-answer {
        height: min-content;
        padding-inline-start: 10px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        margin-block-end: 5px;
        color: black;
        text-align: left;
      }
    }
    
    &-close {
      margin-top: 10px;
      width: 100%;
      font-size: 14px;
    }
  }

  &-overlay.popup-open {
    transform: translateY(0) scale(1);
  }

  &-body.popup-open {
    transform: scale(1);
  }
}
