@import "/src/theme/colors";
.settings {
  height: max-content;
  overflow-y: auto;
  width: inherit;
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: $fontColor;
  &-profile {
    width: 40%;
    background-color: #edeffc;
    padding-block: 3%;
    padding-inline: 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    &-user {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        &-name {
          text-align: center;
          font-weight: 600;
        }
        &-role {
          text-align: center;
          color: orangered;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
    }
    &-course-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 25px;
      background-color: white;
      &-title {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
      }
      &-list {
        padding-block: 15px;
        max-height: 100px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 5px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c0bbbb;
          border-radius: 4px;
        }
        &-item {
          text-align: center;
          font-size: 14px;
        }
      }
      &-button button {
        margin-block-start: 10px;
        color: #fff;
        background-color: $primaryColor;
        font-size: 14px;
        padding-inline: 10px;
        padding-block: 5px;
        border-radius: 10px;
        border: 1px solid $primaryColor;
        cursor: pointer;
      }
    }
    &-change-password {
      color: orangered;
      text-align: center;
      cursor: pointer;
      padding-block-start: 10px;
      font-size: 14px;
    }
  }
  &-info {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-personal {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
      &-title {
        display: flex;
        width: 100%;
        padding-inline: 20px;
        padding-block: 10px;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        &-text {
          font-weight: bold;
        }
        &-icon {
          max-width: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        &-icon img {
          width: 100%;
        }
      }
      &-body {
        font-size: 14px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        &-details {
          margin-block-end: 15px;
          display: flex;
          justify-content: space-between;
          &-key {
            display: flex;
            align-items: center;
            font-weight: 400;
            width: 50%;
          }
          &-value {
            width: 50%;
            text-align: start;

            &-courses {
              overflow-x: scroll;
              display: flex;
              gap: 10px;
              padding-bottom: 5px;
              &::-webkit-scrollbar {
                height: 2px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #c0bbbb;
                border-radius: 4px;
              }
            }
            &-courses span {
              background-color: #edeffc;
              padding: 5px;
              font-size: 12px;
              font-weight: bold;
              border-radius: 5px;
            }
          }
        }
        &-error {
          margin-block-end: 5px;
          text-align: center;
          color: red;
        }
        &-action {
          display: flex;
          gap: 10px;
          justify-content: flex-end;
          &-update {
            color: #fff;
            background-color: $primaryColor;
            border: 1px solid $primaryColor;
          }
          &-cancel {
            color: black;
            background-color: #e3e3e3;
            border: 1px solid #e3e3e3;
            font-weight: bold;
          }
        }
        &-action button {
          font-size: 12px;
          padding-inline: 10px;
          padding-block: 5px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      &-body input {
        margin: 0;
        background: #edeffc;
        padding: 5px 10px;
        width: 100%;
        border-radius: 5px;
        border: none;
        outline: none;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .settings {
    padding-block-start: 80px;
    flex-direction: column;
    gap: 10px;
    &-profile {
      width: 100%;
    }
    &-info {
      width: 100%;
    }
  }
}

@media screen and (min-width: 769px) {
  .settings {
    margin-inline-start: 200px;
  }
}
