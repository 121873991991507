@import "/src/theme/colors";
.plan_card {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 2px 3px 2px rgb(205, 207, 219);
  background: white;
  border-radius: 5px;
  cursor: pointer;
  color: black;
  position: relative;
  &-selected {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    box-shadow: 1px 1px 3px 1px rgb(205, 207, 219);
    position: absolute;
    right: -10px;
    top: -15px;
    font-size: 18px;
    & svg {
      color: $tertiaryColor;
    }
  }
  &-top {
    background: #ffff0000;
    width: 100%;
    height: 55px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    &-semi_oval {
      display: flex;
      justify-content: center;
      border-radius: 100% / 100%;
      align-items: end;
      padding-block-end: 16px;
      background: $tertiaryColor;
      width: 100%;
      position: absolute;
      top: -45px;
      width: 320px;
      height: 100px;
      &-text {
        font-size: 20px;
        color: white;
        font-weight: 500;
      }
    }
  }

  &-body {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding-block-start: 25px;
    padding-block-end: 35px;
    &-left {
      // Styles for the left part of the body
      &-key {
        font-size: 14px;
      }
      &-value {
        // Styles for the value text in the left part
      }
    }

    &-right {
      // Styles for the right part of the body
      &-key {
        font-size: 14px;
      }
      &-value {
        display: flex;
        justify-content: end;
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-block-end: 20px;
    padding-inline: 20px;
    .primary-button {
      width: max-content;
      font-weight: 400;
    }
    .secondary-button {
      width: max-content;
      font-weight: 400;
    }
    &-link {
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      background: none;
      text-decoration: underline;
    }
  }
}
