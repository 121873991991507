@import "/src/theme/colors";
.slide-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-cost {
    color: $primaryColor;
    font-weight: 500;
    font-size: 40px;
  }
  &-credits {
    font-size: 24px;
    font-weight: 500;
    position: relative;

    &::after {
      content: "}";
      height: 100%;
      width: 5px;
      position: absolute;
      right: -10px;
      top: 0;
    }

    &::before {
      content: "{";
      height: 100%;
      width: 5px;
      position: absolute;
      left: -10px;
      top: 0;
    }
  }

  &-help_text {
    color: $primaryColor;
    font-weight: 500;
    font-size: 40px;
  }
  &-slider {
    margin-block: 35px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    appearance: none;
    width: 75%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3a8;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    padding: 0;
    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $primaryColor;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $primaryColor;
      cursor: pointer;
    }

    &::-ms-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $primaryColor;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1050px) {
  .slide-container {
    &-cost {
      font-size: 30px;
    }
    &-credits {
      font-size: 20px;
    }
    &-slider {
      margin-block: 20px;
      height: 8px;
    }
    &-help_text {
      text-align: center;
      font-size: 30px;
    }
  }
}
@media screen and (max-width: 820px) {
  .slide-container {
    &-cost {
      font-size: 18px;
    }
    &-credits {
      font-size: 16px;
    }
    &-slider {
      margin-block: 20px;
      height: 8px;
    }
    &-help_text {
      text-align: center;
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 320px) {
  .slide-container {
    &-cost {
      font-size: 18px;
    }
    &-credits {
      font-size: 16px;
    }
    &-slider {
      margin-block: 20px;
      height: 8px;
    }
    &-help_text {
      text-align: center;
      font-size: 18px;
    }
  }
}
