@import "/src/theme/colors";
.account-settings {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  &-top{
    display: none;
  }
  &-body {
    width: 100%;
    display: flex;
    // padding-block-start: 80px;
    &-sidebar {
      position: relative;
      box-shadow: 1px 7px 10px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      // max-width: 200px;
      color: $fontColor;
      z-index: 2;
    }
    &-content {
      margin-inline-start: 215px;
      background-color: $primaryBackgroundColor;
      width: 100%;
      height: 100%;
      color: $fontColor;
      overflow-y: auto;
      &-header {
        top: 0;
        position: sticky;
        background-color: #fafbff;
        z-index: 1;
      }
      &-elements {
        padding-block: 10%;
        padding-inline: 60px;
        position: relative;
        z-index: 0;
        &-top {
          display: flex;
          gap: 10px;
          margin-block-end: 15px;
          &-icon {
            color: black;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: transform 0.3s, color 0.3s, cursor 0.3s;
            &:hover {
              transform: scale(1.1);
              color: $tertiaryColor;
            }
          }
          &-title {
            display: flex;
            gap: 5px;
            font-size: 22px;
            font-weight: 500;
            align-items: center;

            &-text {
              color: black;
            }
            &-template_name {
              color: $tertiaryColor;
            }
          }
        }
        &-body {
          &-personal_info {
            &-title {
              color: $tertiaryColor;
              display: flex;
              align-items: center;
              gap: 10px;
              margin-block: 10px;
            }
            &-content {
              display: flex;
              justify-content: space-between;
              &-profile {
                width: 30%;
                &-image {
                  display: flex;
                  justify-content: center;
                  position: relative;
                  border-radius: 50%;
                  width: 100%;
                  & img {
                    width: 100%;
                    max-width: 200px;
                    border-radius: 50%;
                  }
                  &-icon {
                    position: absolute;
                    bottom: 25px;
                    right: 10px;
                    background: white;
                    display: flex;
                    width: 30px;
                    height: 30px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    box-shadow: 1px 2px 5px 0px rgb(143 143 143);
                    & svg {
                      color: $tertiaryColor;
                    }
                  }
                }
              }
              &-info {
                width: 65%;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: space-between;
                &-item {
                  width: 47%;
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  &-label {
                    font-weight: 500;
                    color: gray;
                    font-size: 14px;
                  }
                  &-input {
                    background: white;
                    box-shadow: 1px 1px 2px 1px rgb(205, 207, 219);
                    padding-block: 5px;
                    padding-inline: 10px;
                    border-radius: 5px;
                    color: black;
                  }
                }
              }
            }
            &-bottom {
              display: flex;
              justify-content: end;
              margin-block-start: 20px;
              gap: 20px;
              & button {
                width: max-content;
              }
            }
          }
          &-change_password {
            & label{
              font-weight: 500;
              color: gray;
              font-size: 14px;
            }
            & input {
              margin: 0;
              padding: 0;
              background: white;
              box-shadow: 1px 1px 2px 1px rgb(205, 207, 219);
              padding-block: 5px;
              padding-inline: 10px;
              border-radius: 5px;
              color: black;
            }
            &-title {
              color: $tertiaryColor;
              display: flex;
              align-items: center;
              gap: 10px;
              margin-block: 10px;
            }
            &-content {
              display: flex;
              justify-content: flex-end;
              &-items {
                width: 70%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                &-row {
                  display: flex;
                  justify-content: space-between;
                  
                  &-item {
                    width: 47%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    &-input-container{
                      position: relative;
                      &-eye-icon{

                      }
                    }
                    &-label {
                      font-weight: 500;
                      color: gray;
                      font-size: 14px;
                    }
                    &-input {
                      background: white;
                      box-shadow: 1px 1px 2px 1px rgb(205, 207, 219);
                      padding-block: 5px;
                      padding-inline: 10px;
                      border-radius: 5px;
                      color: black;
                    }
                  }
                }
                &-bottom {
                  display: flex;
                  justify-content: end;
                  margin-block-start: 20px;
                  gap: 20px;
                  & button {
                    width: max-content;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .account-settings {
    &-body {
      &-content {
        padding-inline: 3%;
        &-elements {
          padding-inline: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .account-settings {
    &-body {
      // padding-block-start: 60px;
      &-content {
        margin-inline-start: 8%;
        padding: 0 20px;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .account-settings {
    &-body {
      &-content {
        &-elements {
          &-body {
            &-change_password{
              &-content-items{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .account-settings {
    &-top {
      position: fixed;
      display: block;
      background: #fafbff;
      padding-inline: 5%;
      padding-block-start: 5%;
      & svg{
        cursor: pointer;
      }
    }
    &-body {
      &-content {
        margin-inline-start: 0%;
        padding: 20px;
        padding-block-start: 60px;
        &-elements {
          padding: 0;
          &-body {
            &-change_password{
              &-content-items{
                width: 100%;
                &-row{
                  flex-wrap: wrap;
                  &-item{
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.input-container{
  position: relative;
}
.eye-icon{
  position: absolute;
  color: gray;
  right: 5px;
}
