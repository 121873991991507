@import "/src/theme/colors";

.admin-staff-management {
  &-modal {
    &-title {
      font-weight: 500;
      font-size: 18px;
      margin-block-end: 10px;
      color: $titleColor;
    }
    &-content {
      &-message {
        color: $primaryButtonColor;
        margin-block: 5px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
      }
      &-form {
        &-label {
          font-weight: 500;
          font-size: 14px;
          margin-block-end: 5px;
        }
        &-help_text {
          font-size: 12px;
          margin-inline-start: 5px;
          color: #5a5a5a;
        }
        &-input {
          background: #fafbff;
          margin: 0;
          margin-block-start: 5px;
          margin-block-end: 15px;
        }
      }
      &-body {
        background: $primaryBackgroundColor;
        border-radius: 5px;
        border: 1px dashed #9f9f9f;
        padding-block: 15px;
        margin-block-start: 5px;
        &-bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          & button {
            font-weight: 400;
            width: 120px;
          }
          &-icon {
            color: $primaryButtonColor;
            font-size: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &-drag_text {
            font-size: 14px;
            font-weight: 500;
          }
          &-subtext {
            font-size: 14px;
          }
          &-button {
            text-decoration: underline;
            font-size: 14px;
            color: $primaryButtonColor;
            cursor: pointer;
            &:hover {
              background-color: $primaryBackgroundColor;
              color: $primaryButtonColor;
            }
          }
        }
        &-uploaded {
          display: flex;
          gap: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &-file {
            color: $primaryButtonColor;
            display: flex;
            gap: 5px;
            cursor: pointer;
            padding-block: 15px;
            padding-inline: 30px;
            border-radius: 10px;
            background: #3aadb81a;
            &-icon {
              font-size: 35px;
              display: flex;
              align-items: center;
            }
            &-info {
              display: flex;
              flex-direction: column;
              align-items: start;
              &-name {
                color: black;
                font-weight: 500;
              }
              &-size {
                color: gray;
                font-size: 14px;
              }
            }
          }
          &-action {
            display: flex;
            gap: 10px;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            padding-inline: 5%;
            &-error-message {
              font-size: 14px;
              text-align: center;
              color: #990000;
            }
            &-upload_button {
              display: flex;
              gap: 5px;
              border-radius: 5px;
              padding-block: 5px;
              padding-inline: 10px;
              cursor: pointer;
              border: 1px solid $primaryButtonColor;
              color: $primaryButtonColor;
              &:hover {
                border: none;
                background-color: $primaryButtonColor;
                color: $primaryBackgroundColor;
              }
            }
            &-cancel_button {
              width: 100px;
              display: flex;
              gap: 5px;
              border-radius: 5px;
              padding-block: 5px;
              padding-inline: 10px;
              cursor: pointer;
              border: 1px solid rgb(153, 0, 0);
              color: rgb(153, 0, 0);
              &:hover {
                border: none;
                background-color: rgb(153, 0, 0);
                color: $primaryBackgroundColor;
              }
            }
          }
        }
      }
    }
    &-bottom {
      display: flex;
      gap: 20px;
      margin-block-start: 20px;
      & button {
        font-size: 12px;
        width: 130px;
      }
    }
  }
  &-title {
    margin-bottom: 30px;
    font-size: 24px;
    color: $titleColor;
    font-weight: 600;
  }
  &-tabs {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid $buttonHoverColor;
    margin-block-end: 25px;

    &-tab {
      padding: 1px 50px;
      border-right: 1px solid #3aadb8;
      cursor: pointer;

      &.active {
        color: #05167c;
        background-color: transparent;
        border-bottom: 3px solid $primaryButtonColor; // Example of additional styling for the active tab
      }

      // &:hover {
      //     color: $buttonHoverColor;
      // }
    }
  }
  &-body {
    background: white;
    padding: 2%;
    border-radius: 10px;

    &-content {
      // overflow-x: scroll;
      &-buttons {
        display: flex;
        gap: 20px;
        justify-content: end;
        & button {
          width: min-content;
          font-size: 14px;
        }
      }
      &-search_filter {
        display: flex;
        justify-content: end;
        margin-block-start: 15px;
        gap: 20px;
        &-search_box {
          display: flex;
          gap: 15px;
        }
      }
      &-dropdowns {
        position: relative;
        z-index: 1;
        display: flex;
        gap: 15px;
        margin-block-start: 15px;
      }
      &-table {
        margin-block-start: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 7px 1px #d9d9d9;
        min-width: 550px;
        width: 100%;
        &-top {
          padding-inline: 25px;
          padding-block: 22px;
          display: flex;
          justify-content: space-between;
          // border-bottom: 2px solid #f3f3f3;
          &-selected {
            font-weight: 500;
            font-size: 16px;
          }
          &-actions {
            display: flex;
            gap: 45px;
            &-button {
              display: flex;
              gap: 5px;
              align-items: center;
              cursor: pointer;
            }
            &-button:hover {
              transform: scale(1.05);
              transition: transform 0.2s ease-in-out;
            }
            &-button:nth-child(1) {
              color: rgb(55 209 174);
            }
            &-button:nth-child(2) {
              color: rgb(253 143 38);
            }
          }
        }
        &-head {
          // position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: gray;
          padding: 25px 20px;
          border-top: solid 1px #d9d9d9;
          &-item {
            display: flex;
            width: 20%; // Adjust width for standard columns
            text-align: center;
            justify-content: center;

            &:nth-child(1) {
              justify-content: flex-start;
              width: 30%; // Reduced width for the first column
            }

            & svg {
              border: 1px solid #8080803d;
              border-radius: 5px;
              width: 40px;
              height: 35px;
              padding: 5px;
              transition: transform 0.3s, color 0.3s, cursor 0.3s;

              &.active {
                color: red;
                background-color: transparent;
                &:hover {
                  border: 1px solid #ff000061;
                  cursor: pointer;
                  transform: scale(1.1);
                }
              }

              &.inactive {
                color: grey;
              }
            }
          }
        }

        &-body {
          &-row {
            display: flex;
            justify-content: space-between;
            border-top: solid 1px #d9d9d9;

            &-item {
              display: flex;
              width: 20%; // Adjust width for standard columns
              padding: 15px 20px;
              align-items: center;
              justify-content: center;
              // position: relative;
              color: #5a5a5a;

              &:nth-child(1) {
                justify-content: flex-start;
                width: 30%; // Reduced width for the first column
              }

              &-text {
                display: flex;
                flex-direction: column;

                &-main {
                  font-weight: 500;
                  color: black;
                }
                &-sub {
                  font-size: 14px;
                  color: #6eaadc;
                }
                & input {
                  margin: 0;
                  background: white;
                  box-shadow: 0px 0px 1px 1px rgb(205, 207, 219);
                }
              }

              &-button {
                gap: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: color 0.3s ease;
                font-weight: 400;
                svg {
                  transition: transform 0.3s ease;
                }

                &.open {
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }

              &-dropdown-menu {
                position: absolute;
                bottom: -40px;
                left: 5px;
                display: flex;
                flex-direction: column;
                box-shadow: 0px 0px 7px 1px #d9d9d9;
                background: white;
                z-index: 1;
                border-radius: 5px;
                padding: 5px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-10px);
                transition: opacity 0.3s ease, transform 0.3s ease,
                  visibility 0.3s ease;

                &.open {
                  opacity: 1;
                  visibility: visible;
                  transform: translateY(0);
                }

                &-item {
                  padding: 5px;
                  cursor: pointer;
                  border-radius: 5px;

                  &:hover {
                    background: $secondaryColor;
                    color: black;
                  }
                }
                &-item:nth-child(1) {
                  border-bottom: solid 1px #d9d9d98a;
                }
              }
            }
          }
        }
      }
    }

    & button {
      width: 100px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
}

.link {
  color: $tertiaryColor;
  text-decoration: underline;
  transition: transform 0.3s, color 0.3s, cursor 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

@media screen and (max-width: 820px) {
  .admin-staff-management {
    &-body {
      &-content {
        &-search_filter{
          flex-wrap: wrap;
          justify-content: end;
          &-search_box{
            width: 100%;
          }
        }
        &-table {
          &-head {
            padding: 25px 10px;
            &-item {
              width: 18%;
              padding: 15px 8px;
            }
          }
          &-body {
            &-row {
              &-item {
                width: 18%;
                padding: 15px 8px;
                &-text {
                  &-main {
                    font-size: 14px;
                  }
                  &-sub {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .admin-staff-management {
    padding: 20px;
    &-title{
      font-size: 20px;
    }
    &-body {
      &-content {
        &-table {}}}}
}

.responsive-container {
  width: 100%;
  overflow-x: scroll;
  overflow: auto;
  z-index: 0;
}
