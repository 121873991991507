@import "/src/theme/colors";

.course_completed {
  background-color: $finishButtonColor !important;
}

.course_titlebar {
  display: flex;
  position: relative;
  top: 16px;
  width: max-content;
  &-list {
    display: flex;
    list-style: none;
    margin-inline-start: 0;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    // padding-left: 40px;
    &-item {
      list-style: none;
      padding: 10px 30px;
      border-radius: 10px 10px 0 0;
      position: relative;
      font-weight: 500;
      color: black;
      cursor: pointer;
      &-center {
        display: flex;
        gap: 10px;
        &-count {
          background: $secondaryColor;
          color: #000000;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-left {
        position: absolute;
        bottom: 0px;
        left: -20px;
        height: 75%;
        width: 20px;
        background: #fff;
        display: none;
      }
      &-left::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom-right-radius: 10px;
        background: #fafbff;
        box-shadow: inset -3px -5px 6px 1px #e7e8f0;
      }
      &-right {
        position: absolute;
        right: -20px;
        bottom: 0px;
        height: 75%;
        width: 20px;
        background: #fff;
        display: none;
      }
      &-right::before {
        content: "";
        position: absolute;
        right: 0;
        width: 100%;
        top: 0;
        height: 100%;
        border-bottom-left-radius: 10px;
        background: #fafbff;
        box-shadow: inset 4px -5px 5px -1px #e7e8f0;
      }
      a {
      }
    }
    &-item.active {
      background: #fff;
      box-shadow: -1px -5px 5px 1px #e6e7ef;
      color: black;
    }
    &-item.active .course_titlebar-list-item-left,
    &-item.active .course_titlebar-list-item-right {
      display: block;
    }
    &-item:nth-child(1) .course_titlebar-list-item-left {
      position: absolute;
      bottom: -10px;
      left: 0px;
      height: 75%;
      width: 20px;
      background: #ffffff;

      &::before {
        display: none;
      }
    }
  }
}
.course_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.course {
  font-family: Arial, Helvetica, sans-serif;
  color: $fontColor;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px 1px rgb(205 207 219);
  padding: 25px;

  &_header {
    height: 10%;
    display: flex;
    padding-block: 12px;

    &_title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 3px;
      text-align: start;

      &_name {
        font-size: 18px;
        font-weight: 600;
        color: #041570;
      }

      &_module {
        font-size: 12px;
        color: rgb(153, 153, 153);
      }
    }
  }
  &_body {
    display: flex;
    gap: 30px;
    align-items: stretch;
    &_image {
      border-radius: 5px;
      & img {
        max-height: 250px;
        width: 350px;
        border-radius: 5px;
      }
    }
    &_details {
      display: flex;
      flex-direction: column;
      text-align: start;
      justify-content: space-between;
      padding-block-end: 4px;
      &_description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: auto;
        &_title {
          color: #565656;
          font-weight: 600;
          font-size: 14px;
          padding: 0;
          margin: 0 0 5px 0;
        }

        &_text {
          line-height: 1.5;
          font-size: 14px;
          color: #939393;
        }
      }

      &_data {
        width: 100%;
        gap: 10px;
        display: flex;
        margin-top: auto;
        &_info {
          width: 70%;
          &_col {
            display: flex;
            justify-content: space-between;
            padding-inline: 3%;
            font-size: 13px;
            align-items: center;
            height: 40px;
            border: none;
            border-radius: 4px;
            background-color: rgb(248, 248, 248);
            color: black;

            &_key {
              color: #777777;
            }

            &_value {
              color: #000000;
              font-weight: 600;
            }
          }
        }

        &_button {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          width: 30%;
        }
        &_button a {
          margin: 0;
        }
      }
      // &_data button {
      //   font-weight: 600;
      //   width: 80px;
      //   height: 30px;
      //   border: none;
      //   border-radius: 4px;
      //   cursor: pointer;
      //   background-color: $primaryColor;
      //   color: white;
      // }
    }
  }
}
@media screen and (max-width: 450px) {
  .course {
    &_header {
      padding-block: 5px;
    }
    &_body {
      &_details {
        flex-direction: column;
        &_description {
          width: 100%;
          &_text {
            font-size: 12px;
            letter-spacing: 0.4px;
          }
        }
        &_data {
          width: 100%;
          &_button {
            justify-content: center;
          }
        }
        &_data button {
          width: 125px;
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .course_titlebar {
    top: 0px;
    width: 100%;
    &-list {
      width: 100%;
      justify-content: space-around;
      padding-block-end: 10px;
      border-block-end: 1px solid #ebebeb;
      margin-block-end: 0;
      &-item {
        padding: 0;
        font-size: 12px;
        &-left {
          display: none;
        }
        &-right {
          display: none;
        }
      }
      &-item.active {
        background: #fafbff;
        border-bottom: 2px solid;
        padding-block-end: 5px;
        border-image: linear-gradient(to right, #0089ff, #00ebff) 1;
        box-shadow: none;
      }
      &-item.active .course_titlebar-list-item-left,
      &-item.active .course_titlebar-list-item-right {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .course {
    &_body {
      display: block;
      gap: 10px;
      &_image {
        & img {
          max-width: 100%;
        }
      }
      &_details {
        &_data {
          flex-direction: column;
          &_info {
            width: 100%;
          }
          &_button {
            width: 100%;
          }
        }
      }
    }
  }
}

.empty_container {
  width: 100%;
  background: white;
  box-shadow: 0px 0px 10px 1px rgb(205 207 219);
  border-radius: 10px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-text {
    color: black;
    font-size: 18px;
    font-weight: 500;
  }
  &-image {
    & img {
      width: 150px;
    }
  }
}
