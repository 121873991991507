@import "/src/theme/colors";
.user-header {
  width: 100%;
  padding-inline: 20px;
  height: 80px;
  padding: 18px 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  &-logo {
    display: none;
    &-img {
      width: 10%;
    }
    &-img img {
      width: 100%;
      padding-block-start: 5px;
    }
    &-tab {
      display: none;
    }
  }
  &-user {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    &-credits {
      color: #05177d;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    &-avatar {
      float: right;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 40px;
      &-name {
        color: #05177d;
        font-weight: 600;
      }
      &-image {
        width: 35px;
      }
      &-image img {
        width: 100%;
      }
    }
    &-superapp {
      &-button {
        padding: 12px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 20px;
        }
      }
      &-container {
        display: none;
        position: absolute;
        top: 80px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 0.2px #00000047;
        right: 10px;
        width: 350px;
        background: #eaf7f8;
        padding: 10px;
        z-index: 3;
        &-row {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          background: white;
          border-radius: 10px;
          padding: 10px;
          &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100px;
            &-icon {
              width: 50px;
              padding: 12px;
              border-radius: 40px;
              display: flex;

              img {
                width: 100%;
              }
            }

            &-title {
              padding-top: 8px;
              text-align: center; // Ensure the title is centered
            }
          }

          &-item:hover {
            background-color: #71bdff36;
            cursor: pointer;
            border-radius: 10px;
          }
        }
      }
    }
    &-superapp-button:hover {
      background-color: #e2e4ecb5;
    }
    &-superapp-button:active {
      background-color: rgb(255 255 255);
      box-shadow: 0px 0px 5px 3px #e2e4ec;
    }
    &-superapp-button.active {
      background-color: rgb(255 255 255);
      box-shadow: 0px 0px 5px 3px #e2e4ec;
    }
  }
}
@media screen and (max-width: 1024px) {
  // .user-header{
 
  // }
}

@media screen and (max-width: 820px) {
  .user-header {
    height: 60px;
    padding: 0;
    font-size: 14px;
    &-logo {
      &-img {
        display: none;
      }
      &-tab {
        display: block;
        width: 10%;
      }
      &-tab img {
        width: 100%;
        padding-block-start: 5px;
      }
      &-title {
        &-text {
          font-size: 20px;
        }
        &-subtext {
          font-size: 18px;
        }
      }
    }
    &-user {
      margin-right: 15px;
      &-avatar {
        margin: 0;
        & img {
          width: 80%;
        }
      }
    }
  }
}



@media screen and (min-width: 451px) {
  .user-header {
    &-hambuger {
      display: none;
    }
  }
}

@media screen and (max-width: 450px) {
  .user-header {
    flex-direction: row;
    justify-content: space-around;
    font-size: 12px;
    & svg {
      margin-inline-end: 15px;
      min-width: 25px;
    }
    &-hambuger {
      color: #0f2083;
      font-size: 28px;
    }
    &-logo {
      display: flex;
      justify-content: center;
      &-img {
        display: block;
        width: 100px;
        img {
          width: 100%;
          padding-block-start: 5px;
        }
      }
      &-tab {
        display: none;
      }
      &-title {
        &-text {
          font-size: 18px;
        }
        &-subtext {
          font-size: 16px;
        }
      }
    }
  }
}

.selected-app {
  background: #e1f1ff;
  border-radius: 10px;
}
