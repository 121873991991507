.dynamic-container {
  height: 80px;
  padding-block: 15px;
  padding-inline: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  margin-block-end: 20px;
  color: black;
  position: relative;
  background-color: white;
  &-body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    & svg {
      font-size: 24px;
    }
    &-file_status {
      flex: 1;
      border-inline-end: 1px solid #00000080;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &-text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-title {
          font-size: 14px;
          font-weight: 500;
        }
        &-status {
          font-size: 16px;
          font-weight: 500;
        }
      }
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
      }
    }
    &-file_info {
      flex: 1;
      border-inline-end: 1px solid #00000080;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &-text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-width: 100px;
        &-title {
          font-size: 14px;
          font-weight: 500;
        }
        &-file_name {
          font-size: 16px;
          font-weight: 500;
          color: #5a5a5a;
        }
      }
      &-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-svg {
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
          margin-bottom: 5px;
        }
        &-error {
          color: black;
          text-decoration: underline;
          cursor: pointer;
          font-size: 12px;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    &-file_date {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &-text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-title {
          font-size: 14px;
          font-weight: 500;
        }
        &-date {
          font-size: 16px;
          font-weight: 500;
          color: #5a5a5a;
        }
      }
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        &-error {
        }
      }
    }
  }
}

.processing {
  background-color: #f2f4ff;
}

.failed {
  background-color: #ffe7e6;
}

.completed {
  background-color: #f0fcf9;
}

.dynamic-container-body-file_status-text-status {
  font-weight: bold;
}

.dynamic-container-body-file_info-icon-error {
  color: #ff0000;
}

.close_button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.container-data {
  display: flex;
  justify-content: space-around;
  align-items: center;
  &-trainee_count {
    flex: 1;
    display: flex;
    gap: 5px;
    &-key {
    }
    &-value {
    }
  }
  &-course_count {
    flex: 1;
    display: flex;
    gap: 5px;
    &-key {
    }
    &-value {
    }
  }
  &-ratio {
    flex: 1;
    display: flex;
    gap: 5px;
    &-key {
    }
    &-value {
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  display: inline-block;
  vertical-align: bottom;
}

@media screen and (max-width: 1150px) {
  .dynamic-container {
    height: min-content;
    &-body {
      &-file_status {
        border: none;

        &-text {
        }
      }
      &-file_info {
        border: none;
        &-text {
        }
      }
      &-file_date {
        border: none;
        &-text {
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .dynamic-container {
    padding-inline: 10px;
  }
}

@media screen and (max-width: 600px) {
  .ellipsis {
    max-width: 180px;
  }
  .dynamic-container {
    height: min-content;
    &-body {
      flex-direction: column;

      &-file_status {
        width: 100%;
        border: none;
        justify-content: space-between;
        padding-block-end: 10px;
        &-text {
          gap: 0;
        }
      }
      &-file_info {
        width: 100%;
        border: none;
        justify-content: space-between;
        padding-block-end: 10px;
        &-text {
          gap: 0;
        }
      }
      &-file_date {
        width: 100%;
        justify-content: space-between;
        &-text {
          gap: 0;
        }
      }
    }
  }
}
.roles-list{
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
}