.tag-select-menu-container {
  box-shadow: 0px 1px 2px 2px #d9d9d9;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  width: 45%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.tag-select-menu-label {
  font-weight: 500;
}

.tag-selected-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 10px;
}
.tag-items {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-height: 70px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
}
.input-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
}
.tag-item {
  background-color: #e0e0e0;
  border-radius: 20px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.tag-remove-icon {
  cursor: pointer;
  font-size: 12px;
}

.tag-input {
  border: none;
  outline: none;
  flex-grow: 1;
  margin: 0;
  background: white;
  padding: 3px 10px;
  box-shadow: 0px 0px 0px 1px #d9d9d9;
}

.tag-caret-icon {
  margin-left: auto;
}

.tag-select-menu-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0px 1px 2px 2px #d9d9d9;
  border-radius: 10px;
  z-index: 1;
  max-height: 120px;
  overflow-y: auto;
  z-index: 1;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
}

.tag-option-item {
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

.tag-option-item:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width:450px) {
  .tag-select-menu-container{
    width: 100%;
  }
}
  