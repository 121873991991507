@import "/src/theme/colors";
.admin_survey_template {
  &-top {
    display: flex;
    gap: 10px;
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
    &-title {
      display: flex;
      gap: 5px;
      font-size: 22px;
      font-weight: 500;
      align-items: center;

      &-text {
        color: black;
      }
      &-template_name {
        color: $tertiaryColor;
      }
    }
  }
  &-body {
    background: white;
    margin-block: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 1px #d9d9d9;
  }
}
