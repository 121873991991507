@import '/src/theme/colors';
.mock_test{
    color: $fontColor;
    height: 100vh;
    overflow-y: auto;
    width: inherit;
    padding: 5%;
    &-title{
      font-weight: 600;
      font-size: 40px;
      margin-block-end: 20px;
    }
    &-body{
      display: flex;
      flex-direction: column;
      &-content{
        margin-block-end: 20px;
        display: flex;
        justify-content: space-between;
        background-color: $secondaryColor;
        padding: 20px;
        border-radius: 10px;
        width: 100%;
        box-shadow: 1px 7px 11px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        &:hover{
          background-color: #a873ff;
          box-shadow: 1px 7px 11px 4px rgba(82, 0, 250, 0.14);
          color: #fff;
        }
        &-details{
          width: 70%;
          &-title{
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 10px;
          }
          &-info{
            font-size: 14px;
            font-weight: 400;
            text-align: justify;
            display: flex;
            gap:100px;
            &-left{
              display: flex;
              gap:5px;
              flex-direction: column;
            }
            &-right{
              display: flex;
              gap:5px;
              flex-direction: column;
            }
          }
        }
        &-progress{
          width: 20%;
          border-left: 2px solid #09ca9f; 
          border-bottom: 2px solid #09ca9f; 
          max-width: 50px;
          border-radius: 100px;
          
        }
  
        
      }
    }
  }

  @media screen and (min-width: 769px){
    .mock_test{
      margin-inline-start: 200px;
    }
  }