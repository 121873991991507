@import "/src/theme/colors";
.app-course-selector {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;
  &-navbar {
    box-shadow: 1px 7px 10px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-width: 200px;
    // position: fixed;
    // height: 100%;
    color: rgb(5 23 125);
    &-logo {
      padding: 20px;
      width: 100%;
      display: flex;
      // &-img {
      //   width: 35px;
      // }
      &-img img {
        width: 100%;
        padding-block-start: 5px;
      }
      &-title {
        display: flex;
        flex-direction: column;
        line-height: 1;
        justify-content: center;
        &-text {
          font-size: 20px;
          color: $fontColor;
          font-weight: 500;
        }
        &-subtext {
          font-size: 18px;
          color: $fontColor;
          font-weight: 300;
          letter-spacing: 2px;
          padding-inline-start: 1px;
        }
      }
    }
    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      &-tab {
        gap: 15px;
        border-radius: 5px;
        padding: 1rem;
        display: flex;
        cursor: pointer;

        &:hover {
          background-color: rgb(228 231 245);
        }
        &.selected {
          background-color: rgb(228 231 245);
        }
        &-icon {
          width: 20%;
        }
        &-icon img {
          width: 100%;
        }
        &-text {
          font-weight: 600;
          width: 80%;
        }
      }
    }
  }
  &-body {
    margin-inline-start: 200px;
    background-color: $primaryBackgroundColor;
    width: 100%;
    height: 100%;
    text-align: center;
    color: $fontColor;
    overflow-y: auto;
    padding: 0% 3%;
    &-user {
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      gap: 15px;
      display: flex;
      &-header-user {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        &-credits {
          color: #05177d;
          font-weight: 600;
          display: flex;
          align-items: center;
        }
        &-avatar {
          float: right;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-right: 40px;
          &-name {
            color: #05177d;
            font-weight: 600;
          }
          &-image {
            width: 35px;
          }
          &-image img {
            width: 100%;
          }
        }
        &-superapp {
          width: 8%;
          &-button{
            padding: 12px;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img{
              width: 100%;
            }
          }
          &-container{
            display: none;
            position: absolute;
            top: 80px;
            background-color: #fff;
            border-radius: 20px;
            box-shadow: 0px 0px 5px 0.2px #00000047;
            right: 10px;
            width: 350px;
            background: #eaf7f8;
            padding: 10px;
            
            &-row{
              display: grid;
              grid-template-columns: repeat(3, 1fr); 
              gap: 20px; 
              background: white;
              border-radius: 10px;
              padding: 10px;
              &-item{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100px;
                &-icon{
                  width: 50px; 
                  padding: 12px;
                  border-radius: 40px;
                  display: flex;
                  
                  img{
                    width: 100%;
                  }
                }
          
                &-title{
                  padding-top: 8px;
                  text-align: center;  // Ensure the title is centered
                }
              }
          
              &-item:hover{
                background-color: #71bdff36;
                cursor: pointer;
                border-radius: 10px;
              }
            }
          }
          
        }
        &-superapp-button:hover {
          background-color: #e2e4ecb5;
        }
        &-superapp-button:active {
          background-color: rgb(255 255 255);
          box-shadow: 0px 0px 5px 3px #e2e4ec;
        }
        &-superapp-button.active {
          background-color: rgb(255 255 255);
          box-shadow: 0px 0px 5px 3px #e2e4ec;
        }
        
      }
    }
    &-user button {
      font-weight: 600;
      width: 80px;
      height: 30px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      background-color: $primaryColor;
      color: white;
    }
    &-user img {
      width: 35px;
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $titleColor;
      &-text {
        font-size: 22px;
        font-weight: 600;
        padding-block: 2%;
        padding-inline-start: 5px;
        text-align: start;
      }
      &-search-box { 
        height: 40px;
        width: 40%;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .app {
    &-course {
      &-selector {
        &-body {
          margin-inline-start: 8%;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .app {
    &-course {
      &-selector {
        &-body {
          padding: 3%;
          padding-block-start: 60px;
          margin-inline-start: 0;
          &-user {
            padding-block: 3%;
          }
          &-user img {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .app-course-selector {
    &-body {
      margin-inline-start: 65px;
      &-title {
        flex-direction: column;
        &-text{
          width: 100%;
          font-size: 18px;
        }
        &-search-box{
          width: 100%;
          height: 35px;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .app-course-selector {
    &-body {
      margin-inline-start: 0px;
    }
  }
}

