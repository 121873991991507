@import "/src/theme/colors";

.searchable-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  &__toggle {
    margin-block-start: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
  }

  .chevron-icon {
    margin-left: 10px;
    transition: transform 0.3s ease;

    &.rotate {
      transform: rotate(180deg);
    }
  }
  & input{
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
  &__input {
    margin: 0;
    // padding: 10px;
    width: 100%;
    border: none;
    outline: none;
    transition: all 0.3s ease;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 130px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
    z-index: 10;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
    
    &.open {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      width: 100%;
      margin-block-start: 5px;
    }
  }

  &__list-item {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    color: #808080;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
