@import "/src/theme/colors";
.disabled {
  pointer-events: none;
  color: #5a5a5a;
  // opacity: 0.5;
}

.admin-trainees {
  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    &-text {
      font-size: 24px;
      color: $titleColor;
      font-weight: 600;
    }
    &-buttons {
      padding-block: 15px;
      display: flex;
      gap: 20px;
      &-search_box {
      }
      &-button {
        font-size: 15px;
        width: 125px;
        height: 30px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: #95a0af;
        color: white;
      }
    }
  }
  .enabled {
    background-color: $primaryButtonColor;
  }

  &-body {
    color: #5a5a5a;
    background-color: white;
    border-radius: 10px;
    // padding: 3%;
    display: flex;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
    &-table {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      // gap: 15px;
      min-width: 550px;
      width: 100%;
      &-top {
        padding-inline: 25px;
        padding-block: 22px;
        display: flex;
        justify-content: space-between;
        // border-bottom: 2px solid #f3f3f3;
        &-selected {
          font-weight: 500;
          font-size: 16px;
        }
        &-actions {
          display: flex;
          gap: 45px;
          &-button {
            display: flex;
            gap: 5px;
            align-items: center;
            cursor: pointer;
          }
          &-button:hover {
            transform: scale(1.05);
            transition: transform 0.2s ease-in-out;
          }
          &-button:nth-child(1) {
            color: rgb(55 209 174);
          }
          &-button:nth-child(2) {
            color: rgb(253 143 38);
          }
        }
      }

      &-head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding-inline: 20px;
        padding-block: 20px;
        border-block: 2px solid #f3f3f3;
        &-value {
          width: 99%;
          display: flex;
          padding-inline-start: 10px;
          &-item {
            font-weight: bolder;
            flex: 1;
            &-checkbox {
            }
            &-label {
              padding-left: 22px;
            }
          }
          &-item:nth-child(2),
          &-item:nth-child(3) {
            text-align: center;
          }
        }
      }

      &-body {
        display: flex;
        flex-direction: column;

        &-row {
          width: 100%;
          padding-block: 10px;
          padding-inline: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          border-bottom: 2px solid #f3f3f3;
          &-checkbox {
            width: 2%;
            height: 100%;
          }
          &-value {
            width: 99%;
            display: flex;
            &-item {
              flex: 1;
              text-align: center;

              &-icon {
                width: 40px;
                text-align: center;
                background-color: #e9e8e4;
                padding: 10px;
                border-radius: 20px;
                font-size: 16px;
                font-weight: 500;
                color: #505050;
              }
              &-text {
                &-name {
                  font-weight: 500;
                }
                &-name span:hover {
                  color: $primaryColor;
                  cursor: pointer;
                }

                &-email {
                  color: #9c9fb4;
                }
              }
            }
            &-item:nth-child(1) {
              display: flex;
              text-align: left;
              align-items: center;
              gap: 15px;
              padding-inline-start: 10px;
              width: 30%;
            }
          }
        }
        &-row:hover {
          background-color: #f0fcf9;
        }
       
      }

    }
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;

    &-pagination {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    &-dropdown {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding-inline-end: 10px;
    }
  }
}
.container {
  width: 0;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  top: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #948f8f;
  border-radius: 3px;
}

// .checkmark:nth-child(n + 2) {
//   top: 14px;
// }
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #05177d;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 820px) {
  .admin-trainees {
    &-title {
      margin-bottom: 25px;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      &-buttons {
        gap: 10px;
        &-button {
          width: 150px;
          height: 30px;
        }
      }
    }
    &-body {
      &-table {
        &-top {
          padding-block: 14px;
        }
        &-head {
          padding-block: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .admin-trainees {
    &-title {
      margin-bottom: 18px;
      &-text {
        font-size: 20px;
      }
      &-buttons {
        gap: 10px;
      }
    }
    &-body {
      &-table {
        &-top {
          flex-direction: column-reverse;
          gap: 10px;
          &-selected {
            font-size: 14px;
          }
          &-actions {
            gap: 25px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .admin-trainees {
    padding: 20px;
    &-title {
      flex-direction: column;
      &-text {
        width: 100%;
      }
      &-buttons {
        justify-content: start;
        width: 100%;
        flex-direction: column;
        &-button {
          width: 100%;
        }
      }
    }
  }
}
