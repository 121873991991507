@import "/src/theme/colors";
.certificate {
  color: $fontColor;
  height: max-content;
  overflow-y: auto;
  width: inherit;
  padding: 5%;
  width: 100%;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
  &-title {
    font-weight: 600;
    font-size: 40px;
    margin-block-end: 20px;
  }
  &-body {
    margin: auto;
    width: 80%;
    &-image {
      width: 100%;
    }
    &-image img {
      border-radius: 10px;
      width: 100%;
    }
    &-buttons {
      display: flex;
      gap: 15px;
    }
    &-buttons button {
      width: 120px;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      border-radius: 5px;
      outline: none;
      letter-spacing: 1px;
      cursor: pointer;
      padding-inline: 10px;
      padding-block: 5px;
    }
  }
  &-fail {
    &-body {
      border-radius: 10px;
      padding: 5%;
      justify-content: center;
      display: flex;
      box-shadow: 1px 1px 15px 5px #edeffc;
      align-items: center;
      flex-direction: column;
      &-image {
        & img {
          width: 200px;
          height: 200px;
        }
      }
      &-text {
        margin: 5px;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .certificate{
      margin-inline-start: 200px;
  }
}
@media screen and (max-width: 820px) and (min-width:451px) {
  .certificate{
      margin-inline-start: 70px;
      &-body{
        width: 100%;
      }
  }
}


@media screen and (max-width: 450px) {
  .certificate{
      margin-block-start: 70px;
      &-title{
        font-size: 28px;
      }
      &-body{
        width: 100%;
        &-buttons{
          justify-content: center;
        }
      }
  }
}