@import "/src/theme/colors";

.admin-trainee_details {
  &-head {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-block-end: 30px;
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
    &-title {
      font-size: 24px;
      color: $titleColor;
      font-weight: 600;
    }
  }
  &-body {
    &-content {
      background-color: white;
      border-radius: 10px;
      margin-top: 25px;
      &-titlebar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px;
        border-bottom: solid 1px #d9d9d9;
        &-title {
          color: $fontColor;
          font-weight: 600;
          font-size: 20px;
        }
        &-user {
          display: flex;
          &-icon {
            width: 40px;
            text-align: center;
            background-color: #e9e8e4;
            padding: 10px;
            border-radius: 20px;
            font-size: 16px;
            font-weight: 500;
            color: #505050;
          }
          &-text {
            padding-inline-start: 15px;
            &-name {
              font-weight: 500;
            }
            &-email {
              color: #9c9fb4;
            }
          }
        }
      }
      &-courses {
        padding: 25px;
        &-header {
          &-enrolled {
            display: flex;
            justify-content: space-between;
            &-title {
              font-size: 16px;
              font-weight: 600;
            }
            &-search {
            }
            &-button {
              &-enroll {
                width: 140px;
              }
            }
          }
          &-subhead {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block-start: 20px;
            &-courses {
              font-weight: 900;
            }
            &-allcertificate {
            }
            &-allcertificate button {
              width: 140px;
              display: flex;
              align-items: center;
              font-size: 15px;
            }
            &-allcertificate img {
              width: 18%;
            }
          }
        }
        &-table {
          margin-block-start: 30px;
          // padding: 20px;
          border-radius: 10px;
          box-shadow: 0px 0px 7px 1px #d9d9d9;
          &-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 22px;
            font-weight: 500;
            padding: 25px 20px;
            &-item {
              display: flex;
              width: 25%;
              text-align: center;
              & svg {
                border: 1px solid #8080803d;
                border-radius: 5px;
                width: 40px;
                height: 35px;
                padding: 5px;
                transition: transform 0.3s, color 0.3s, cursor 0.3s;

                &.active {
                  color: red;
                  background-color: transparent;
                  &:hover {
                    border: 1px solid #ff000061;
                    cursor: pointer;
                    transform: scale(1.1);
                  }
                }

                &.inactive {
                  color: grey;
                }
              }
            }
            &-item:nth-child(2),
            &-item:nth-child(3) {
              justify-content: center;
            }
            &-item:nth-child(4) {
              justify-content: end;
            }
          }
          &-body {
            &-row {
              display: flex;
              justify-content: space-between;
              &-item {
                display: flex;
                width: 25%;
                border-top: solid 1px #d9d9d9;
                padding: 15px 20px;
                align-items: center;
                &-progress {
                  width: 50%;
                  &-label {
                    margin-bottom: 5px;
                  }
                }
                &-dropdown{
                  display: none;
                  position: absolute;
                  overflow: auto;
                  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                  z-index: 1;
                  border-radius: 0 0 10px 10px;
                  button{
                    padding: 10px;
                    border: none;
                    border-bottom: solid 1px #6a6a6a6b;
                    background: #edfdff;
                    cursor: pointer;
                  }
                  button:nth-child(2){
                    border: none;
                  }
                }
              }
              &-item:nth-child(2),
              &-item:nth-child(3) {
                justify-content: center;
              }
              &-item:nth-child(4) {
                display: inline-block;
              }
              &-item button {
                width: 140px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &-item button img {
                width: 18%;
              }
            }
          }
        }
      }
      &-details {
        padding: 20px;
        &-title {
          font-size: 20px;
          font-weight: 600;
          padding-bottom: 20px;
        }
        &-title span {
          margin-inline-start: 10px;
        }
        &-title span img {
          width: 20px;
        }
        &-tab {
          padding: 20px;
          display: flex;
          box-shadow: 0px 0px 7px 1px #d9d9d9;
          border-radius: 10px;
          &-user {
            width: 25%;
            margin: 20px;
            border-right: solid 1px #d9d9d9;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &-icon {
              width: 100px;
              height: 100px;
              text-align: center;
              background-color: #ccf4f7;
              padding: 22px;
              border-radius: 50px;
              font-size: 48px;
              font-weight: 500;
              color: #505050;
            }
            &-text {
              &-name {
                font-weight: 500;
                text-align: center;
              }
              &-email {
                color: #9c9fb4;
              }
            }
          }
          &-form {
            width: 75%;
            padding: 50px;
            &-name,
            &-contact {
              display: flex;
              &-details {
                border-radius: 10px;
                padding: 10px;
                width: 50%;
                margin: 10px;
                &-label {
                  font-size: 14px;
                  color: #7d8090;
                }
                &-data input {
                  margin: 0;
                  background-color: #ffffff;
                  padding: 0;
                  height: 25px;
                }
              }
            }
            &-error {
              margin: 10px;
              color: #ff4500;
            }
            &-action {
              display: flex;
              justify-content: flex-end;
            }
            &-action button {
              margin-inline-end: 20px;
            }
          }
        }
      }
    }
  }
}

.edit-mode {
  box-shadow: 0px 0px 7px 1px #d9d9d9;
}
.read-only-mode {
  caret-color: transparent;
  cursor: pointer;
}

.unenroll_confirm_modal-body {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &-title {
    font-size: 18px;
    font-weight: 500;
  }
  &-text {
  }
  &-buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
.show-dropdown{
  display: block;
}