@import "/src/theme/colors";
.admin_survey_recipient_list {
  &-top {
    display: flex;
    gap: 10px;
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
    &-title {
      display: flex;
      gap: 5px;
      font-size: 22px;
      font-weight: 500;
      align-items: center;

      &-text {
        color: black;
      }
      &-template_name {
        color: $tertiaryColor;
      }
    }
  }
  &-body {
    background: white;
    &-table {
      margin-block-start: 30px;
      border-radius: 10px;
      box-shadow: 0px 0px 7px 1px #d9d9d9;
      &-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 20px;
        font-size: 16px;
        font-weight: 500;
        color: gray;
        &-item {
          display: flex;
          width: 25%;
          text-align: center;
          justify-content: center;
          & svg {
            border: 1px solid #8080803d;
            border-radius: 5px;
            width: 40px;
            height: 35px;
            padding: 5px;
            transition: transform 0.3s, color 0.3s, cursor 0.3s;

            &.active {
              color: red;
              background-color: transparent;
              &:hover {
                border: 1px solid #ff000061;
                cursor: pointer;
                transform: scale(1.1);
              }
            }

            &.inactive {
              color: grey;
            }
          }
        }
        &-item:nth-child(1) {
          justify-content: flex-start;
        }
      }
      &-body {
        &-row {
          display: flex;
          justify-content: space-between;
          border-top: solid 1px #d9d9d9;
          &-item {
            display: flex;
            width: 25%;
            padding: 15px 20px;
            align-items: center;
            justify-content: center;
            color: #5a5a5a;
            &-text {
              display: flex;
              flex-direction: column;
              &-main {
                font-weight: 500;
                color: black;
              }
              &-sub {
                font-size: 14px;
              }
            }
          }
          &-item:nth-child(1) {
            display: flex;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
