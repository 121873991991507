@import "/src/theme/colors";
.compliance-admin {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #fafbff;
  &-body {
    width: 100%;
    display: flex;
    &-content {
      padding-inline: 2%;
      margin-inline-start: 215px;
      background-color: $primaryBackgroundColor;
      width: 100%;
      height: 100%;
      color: $fontColor;
      overflow-y: auto;
      &-header {
        top: 0;
        position: sticky;
        background-color: #fafbff;
        z-index: 1;
      }
      &-elements {
        padding-block-end: 30px;
        position: relative;
        z-index: 0;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .compliance-admin {
    &-body {
      &-content {
        padding-inline: 3%;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .compliance-admin {
    &-body {
      // padding-block-start: 60px;
      &-content {
        margin-inline-start: 10%;
        padding: 0 20px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .compliance-admin {
    // &-header {
    //   background-color: #ffffff;
    // }
    &-body {
      padding: 20px;
      &-content {
        margin-inline-start: 0%;
        padding: 0;
        &-elements {
          padding: 0;
        }
      }
    }
  }
}
