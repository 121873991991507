.app-loader{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    &-circle{
        width: 75%;
        display: flex;
        justify-content: center;
        position: absolute;
        animation: rotate 2s infinite;
    }
    &-circle img{
        width: 12%;
    }
    &-oldman{
        width: 75%;
        display: flex;
        justify-content: center;
        padding-left: 5px;
            padding-bottom: 10px;
    }
    &-oldman img{
        width: 5%;
    }
}


@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }
    // 50%{
    //     transform: rotate(-90deg);
    // }
    100% {
        transform: rotate(-360deg);
    }
}