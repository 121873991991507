@import "/src/theme/colors";
.admin_survey_detailed {
  &-tabs {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid $buttonHoverColor;
    margin-block-end: 25px;
    &-tab {
      padding: 1px 50px;
      border-right: 1px solid #3aadb8;
      cursor: pointer;

      &.active {
        color: #05167c;
        background-color: transparent;
        border-bottom: 3px solid $primaryButtonColor;
      }
    }
  }
  &-top {
    display: flex;
    gap: 10px;
    margin-block-end: 15px;
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
    &-title {
      display: flex;
      gap: 5px;
      font-size: 22px;
      font-weight: 500;
      align-items: center;

      &-text {
        color: black;
      }
      &-template_name {
        color: $tertiaryColor;
      }
    }
  }
  &-body {
    background: white;

    // overflow: hidden;
    &-overview {
      overflow-y: auto;
      height: calc(100vh - 200px);
      flex-grow: 1;
      margin-block-start: 30px;
      border-radius: 10px;
      box-shadow: 0px 0px 7px 1px #d9d9d9;
      padding: 2%;
      border-block-end: 1px solid #b2b2b2;
      display: flex;
      flex-direction: column;
      gap: 30px;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c0bbbb;
        border-radius: 4px;
      }
      &-graph_info {
        display: flex;
        padding-inline-start: 20px;
        padding-block-end: 40px;
        gap: 20px;
        border-block-end: 1px solid #e2e2e2;
        color: black;
        &-item {
          gap: 5px;
          display: flex;
          &-value {
            font-weight: 500;
          }
        }
      }
      &-overall {
        padding-block-end: 120px;
        &-title {
          color: black;
          font-size: 18px;
          font-weight: 500;
          margin-block-end: 30px;
        }
        &-body {
          display: flex;
          justify-content: space-between;
          padding-inline: 5%;
          &-graph {
            width: 45%;
            border-radius: 10px;
          }
          &-info {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 25px;
            &-title {
              display: flex;
              gap: 5px;
              font-size: 22px;
              font-weight: 500;
              &-text {
                color: black;
              }
              &-rating {
                color: green;
              }
            }
            &-legend {
              display: flex;
              flex-direction: column;
              gap: 20px;
              &-item {
                display: flex;
                gap: 5px;
                &-key {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  &-color {
                    display: inline-block;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                  }
                  &-text {
                  }
                }
                &-value {
                }
              }
              &-item:nth-child(1),
              &-item:nth-child(2) {
                color: orange;
              }
              &-item:nth-child(3),
              &-item:nth-child(4),
              &-item:nth-child(5) {
                color: green;
              }
            }
          }
        }
      }
      &-divided {
        margin-block-start: 50px;
        &-title {
          color: black;
          font-size: 18px;
          font-weight: 500;
          margin-block-end: 10px;
        }
        &-body {
          display: flex;
          justify-content: space-between;
          padding-inline: 5%;
          &-graph {
            width: 45%;
            border-radius: 10px;
          }
          &-info {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 50px;
            &-filter {
              &-title {
                color: black;
                font-weight: 500;
                font-size: 18px;
                margin-block-end: 15px;
              }
              &-dropdown {
                gap: 10px;
                display: flex;
                width: 100%;
                border: 1px solid #cccccc;
                padding-block: 10px;
                padding-inline: 10px;
                font-size: 14px;
                transition: color 0.3s ease;
                font-weight: 400;
                position: relative;
                align-items: center;
                color: black;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                  border: 1px solid rgb(112, 112, 112);
                }
                &-chevron {
                  margin-left: auto;
                  font-size: 12px;
                  color: gray;
                  transition: transform 0.3s ease;
                }
                &-search {
                  font-size: 14px;
                  color: gray;
                }

                &-menu {
                  position: absolute;
                  display: flex;
                  flex-direction: column;
                  box-shadow: 0px 1px 1px 1px #d9d9d9;
                  width: 100%;
                  top: 40px;
                  left: 0;
                  border-radius: 5px;
                  background: white;
                  z-index: 1;
                  padding: 5px;
                  opacity: 0;
                  visibility: hidden;
                  transform: translateY(-10px);
                  transition: opacity 0.3s ease, transform 0.3s ease,
                    visibility 0.3s ease;

                  &.open {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                  }

                  &-item {
                    padding: 10px;
                    cursor: pointer;
                    border-block-end: 1px solid #e2e2e2;
                    &:hover {
                      background: $secondaryColor;
                      color: black;
                    }
                  }
                }
              }
              &.open {
                &-chevron {
                  transform: rotate(180deg);
                }
              }
            }
            &-legend {
              display: flex;
              flex-direction: column;
              gap: 20px;
              &-title {
                display: flex;
                gap: 5px;
                font-size: 22px;
                font-weight: 500;
                &-text {
                  color: black;
                }
                &-rating {
                  color: orange;
                }
              }
              &-item {
                display: flex;
                gap: 5px;
                font-weight: 500;
                &-key {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  &-color {
                    display: inline-block;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &-table {
      margin-block-start: 30px;
      border-radius: 10px;
      box-shadow: 0px 0px 7px 1px #d9d9d9;

      &-head,
      &-body {
        display: flex;
      }

      &-head {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        font-size: 16px;
        font-weight: 500;
        color: gray;

        &-item {
          flex: 1;
          text-align: center;

          &:first-child {
            flex: 2;
            text-align: left;
          }
        }
      }

      &-body {
        flex-direction: column;
        &-row {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #d9d9d9;
          padding: 15px 20px;

          &-item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            color: #5a5a5a;
            text-align: center;
            &-rating-icon {
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              position: relative;
              z-index: 0;
              & svg{
                position: absolute;
                width: 30px;
                height: 30px;
                z-index: 1;
              }
              & span{
                position: absolute;
                z-index: 2;
                color: white;
                font-weight: 500;
                font-size: 13px;
              }
            }
            &:first-child {
              flex: 2;
              justify-content: flex-start;
              text-align: left;
            }

            &-text {
              display: flex;
              flex-direction: column;

              &-main {
                font-weight: 500;
                color: black;
              }

              &-sub {
                font-size: 14px;
                color: #569cd6;
              }
            }
          }
        }
      }
    }
  }
}
