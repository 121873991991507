@import "/src/theme/colors";

.course-completed {
  background-color: $finishButtonColor !important;
}

.user-policy-item-titlebar {
  display: flex;
  position: relative;
  top: 16px;
  width: max-content;
  &-list {
    display: flex;
    list-style: none;
    margin-inline-start: 0;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    margin-block-start: 0;
    &-item {
      list-style: none;
      padding: 10px 30px;
      border-radius: 10px 10px 0 0;
      position: relative;
      font-weight: 500;
      color: black;
      cursor: pointer;
      &-center {
        display: flex;
        gap: 10px;
        &-count {
          background: $secondaryColor;
          color: #000000;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-left {
        position: absolute;
        bottom: 0px;
        left: -20px;
        height: 75%;
        width: 20px;
        background: #fff;
        display: none;
      }
      &-left::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom-right-radius: 10px;
        background: #fafbff;
        box-shadow: inset -3px -5px 6px 1px #e7e8f0;
      }
      &-right {
        position: absolute;
        right: -20px;
        bottom: 0px;
        height: 75%;
        width: 20px;
        background: #fff;
        display: none;
      }
      &-right::before {
        content: "";
        position: absolute;
        right: 0;
        width: 100%;
        top: 0;
        height: 100%;
        border-bottom-left-radius: 10px;
        background: #fafbff;
        box-shadow: inset 4px -5px 5px -1px #e7e8f0;
      }
      a {
      }
    }
    &-item.active {
      background: #fff;
      box-shadow: -1px -5px 5px 1px #e6e7ef;
      color: black;
    }
    &-item.active .user-policy-item-titlebar-list-item-left,
    &-item.active .user-policy-item-titlebar-list-item-right {
      display: block;
    }
    &-item:nth-child(1) .user-policy-item-titlebar-list-item-left {
      position: absolute;
      bottom: -10px;
      left: 0px;
      height: 75%;
      width: 20px;
      background: #ffffff;

      &::before {
        display: none;
      }
    }
  }
}
.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    gap: 2%; /* Controls the space between items */
    background: white;
    box-shadow: 0px 0px 10px 1px rgb(205, 207, 219);
    border-radius: 10px;
    padding: 2%;
}


.user-policy-item {
  color: black;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px 1px rgb(205 207 219);
  padding: 20px;
  margin-block:1% ;
  transition: transform 0.8s;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-version {
      &-key {
        color: gray;
      }
      &-value {
      }
    }
    &-status {
      
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 40px;
    &-top {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-block-start: 15px;
      align-items: flex-start;
      &-name {
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &-date {
        text-align: left;
        font-size: 14px;
        color: gray;
      }
    }
    &-bottom {
      &-info {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        flex-wrap: wrap;
        &-item {
          display: flex;
          flex-direction: column;
          width: 40%;
          gap: 2px;
          &-key {
            font-size: 14px;
            color: gray;
          }
          &-value {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.user-policy-item:hover{
    transform: scale(1.08);
    cursor: pointer;
}


@media screen and (max-width: 1000px) {
  .wrapper{
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    gap: 2%;
  }
  .course {
    &-body {
      display: block;
      gap: 10px;
      &-image {
        & img {
          max-width: 100%;
        }
      }
      &-details {
        &-data {
          flex-direction: column;
          &-info {
            width: 100%;
          }
          &-button {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .wrapper{
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    gap: 2%;
    background-color: transparent;
    box-shadow: none;
  }
  .user-policy-item-titlebar {
    top: 0px;
    width: 100%;
    &-list {
      margin-block-start: 15px;
      width: 100%;
      justify-content: space-around;
      padding-block-end: 10px;
      border-block-end: 1px solid #ebebeb;
      margin-block-end: 0;
      &-item {
        padding: 0;
        font-size: 12px;
        &-left {
          display: none;
        }
        &-right {
          display: none;
        }
      }
      &-item.active {
        background: #fafbff;
        border-bottom: 2px solid;
        padding-block-end: 5px;
        border-image: linear-gradient(to right, #0089ff, #00ebff) 1;
        box-shadow: none;
      }
      &-item.active .user-policy-item-titlebar-list-item-left,
      &-item.active .user-policy-item-titlebar-list-item-right {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .wrapper{
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 2%;
  }
  .course {
    &-header {
      padding-block: 5px;
    }
    &-body {
      &-details {
        flex-direction: column;
        &-description {
          width: 100%;
          &-text {
            font-size: 12px;
            letter-spacing: 0.4px;
          }
        }
        &-data {
          width: 100%;
          &-button {
            justify-content: center;
          }
        }
        &-data button {
          width: 125px;
        }
      }
    }
  }
}

.empty-container {
  width: 100%;
  background: white;
  box-shadow: 0px 0px 10px 1px rgb(205 207 219);
  border-radius: 10px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-text {
    color: black;
    font-size: 18px;
    font-weight: 500;
  }
  &-image {
    & img {
      width: 150px;
    }
  }
}
