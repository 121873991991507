.accordion-container {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  p{
    color: #05167c;
    letter-spacing: normal;
    font-weight: 400;
  }

  .accordion {
    background-color: #008b9654;
    border-top: 1px solid white;
    color: #05167c;
    font-weight: 500;
    cursor: pointer;
    padding-block: 30px;
    padding-inline: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
    // &.active,
    // &:hover {
    //     -webkit-transform: scale(1);
    // }
  }

  .panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;

    &.open {
      display: block;
      background: #a8d6dc17;
    }
  }
}
