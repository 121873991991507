@import '/src/theme/colors';
.incident_admin {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;
  &-body {
    width: 100%;
    display: flex;
    // padding-block-start: 80px;
    &-sidebar {
      position: relative;
      box-shadow: 1px 7px 10px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      // max-width: 200px;
      color: $fontColor;
      z-index: 2;
    }
    &-content {
      background-color: $primaryBackgroundColor;
      width: 100%;
      height: 100%;
      color: $fontColor;
      overflow-y: auto;
      &-header{
        top: 0;
        position: sticky;
        background-color: #fafbff;
        z-index: 1;
      }
      &-elements{
        padding-inline: 60px;
        padding-block-end: 30px;
        position: relative;
        z-index: 0;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .policy_admin {
    &-body {
      &-content{
        padding-inline: 3%;
      }
    }
  }
  
}
@media screen and (max-width: 820px) {
  .policy_admin {
    &-body {
      // padding-block-start: 60px;
      &-content{
        padding: 0 20px;
      }
    }
  }
  
}
@media screen and (max-width: 500px) {
  .policy_admin {
    &-header{
      background-color: #ffffff;
    }
    &-body {
      // margin-block-start: 60px;
      &-content{
        padding: 0;
        &-elements{
          padding: 0;
        }
      }
    }
  }
  
}
