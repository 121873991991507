.toggle-switch {
    width: 50px;            // Width of the toggle switch
    height: 25px;           // Height of the toggle switch
    border-radius: 25px;    // Rounded corners for the switch
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    padding: 2px;           // Padding for the slider
    position: relative;     // Make the parent element relative to position the slider
  }
  
  .toggle-switch__slider {
    width: 21px;           // Width of the slider
    height: 21px;          // Height of the slider
    border-radius: 50%;    // Make the slider round
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    position: absolute;    // Use absolute positioning for sliding
    top: 2px;              // Center the slider vertically
    left: 2px;             // Initial position on the left
    transform: translateX(0); // Default position (left)
  }
  
  .toggle-switch.checked .toggle-switch__slider {
    transform: translateX(25px); // Move the slider to the right when checked
  }
  