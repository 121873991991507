.payment_success_page {
  background-color: #fcfafa;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-body {
    padding-block: 40px;
    display: flex;
    flex-direction: column;
    min-width: 450px;
    &-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-text {
        font-weight: 600;
        font-size: 26px;
      }
    }
    &-middle {
      border-radius: 10px;
      background-color: #eceefc;
      padding-inline: 30px;
      padding-block: 20px;
      color: black;
      font-weight: 300;
      box-shadow: 0px 4px 5px #cbcbcb;
      margin-block: 30px;
      &-info {
        display: flex;
        flex-direction: column;
        &-row {
          display: flex;
          justify-content: space-between;
          font-weight: 400;
          margin-block-end: 10px;
          &-key {
          }
          &-value {
          }
        }
      }
      &-amount {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 20px;
        margin-block-end: 10px;
        &-key {
        }
        &-value {
        }
      }
      &-transaction {
        text-align: center;
        font-size: 14px;
        color: #837b8c;
      }
    }
    &-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      &-button {
        width: 140px;
        height: 30px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: rgb(5, 23, 125);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
    &-error-bottom {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-block-start: 20px;
      &-try {
        width: 140px;
        height: 30px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: rgb(5, 23, 125);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
      &-back {
        width: 140px;
        height: 30px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: rgb(221 221 221);
        color: #080808;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 600px) 
{
  .payment_success_page {
    &-body{
      min-width: 80%;
      &-top {
        gap: 10px;
        &-text {
          font-size: 24px;
        }
      }
      &-middle {
        margin-block: 15px;
        &-info {
          &-row {
            &-key {
              font-size: 14px;
            }
            &-value {
              font-size: 14px;
            }
          }
        }
        &-amount {
          &-key {
            font-size: 16px;
          }
          &-value {
            font-size: 16px;
          }
        }
      }
      &-error-bottom {
        
        &-try {
          width: 100px;
        }
        &-back {
          width: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) 
{.payment_success_page {
  &-body{
    min-width: 90%;
    &-middle{
      padding: 15px;
    }
  }}

}