@import "/src/theme/colors";
.incident-report-form {
  &-top {
    display: flex;
    gap: 10px;
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
    &-title {
      display: flex;
      gap: 5px;
      font-size: 22px;
      font-weight: 500;
      align-items: center;

      &-text {
        color: black;
      }
      &-template_name {
        color: $tertiaryColor;
      }
    }
  }
  &-body {
    max-width: 100%;
    margin: 20px auto;
    padding: 5%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px rgb(205, 207, 219);
    input {
      margin-inline: 0;
    }

    h2 {
      text-align: center;
      color: $fontColor;
      margin-bottom: 20px;
    }

    .form-section {
      margin-bottom: 30px;

      &-heading {
        background-color: $tertiaryColor;
        color: white;
        padding: 10px 15px;
        border-radius: 5px;
        font-size: 1.2rem;
        margin-bottom: 20px;
      }

      .form-fields {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        label {
          width: 100%;
          font-size: 1rem;
          color: $fontColor;
          margin-bottom: 5px;
        }

        input,
        select,
        textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 1rem;
          background-color: #fff;
          transition: border 0.3s ease;
          box-sizing: border-box;

          &:focus {
            border-color: #37afb9;
          }
        }

        select {
          position: relative;
          appearance: none;
          background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5H7zM7 5l5-5 5 5H7z"/></svg>');
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-size: 10px;

          &:hover {
            background-color: #e1f1ff;
          }
        }

        textarea {
          min-height: 100px;
        }

        .half-width {
          width: 48%; // Two items side by side in each row
        }

        .full-width {
          width: 100%; // Full row for certain items
        }
      }
    }

    // Dropdown styling and smooth opening effect
    select {
      &:focus {
        outline: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      }

      option {
        background-color: white;
        &:hover {
          background-color: #e1f1ff;
        }
      }

      &::-ms-expand {
        display: none; // Hide default dropdown arrow for IE
      }

      transition: all 0.3s ease;
    }

    .form-footer {
      text-align: center;
      margin-top: 20px;

      button {
        background-color: #37afb9;
        color: white;
        padding: 10px 20px;
        font-size: 1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #2a909b;
        }
      }
    }

    .consent-checkbox {
      display: flex;
      align-items: center;

      input[type="checkbox"] {
        margin-right: 10px;
      }

      label {
        margin: 0;
      }
    }

    .file-upload {
      input[type="file"] {
        padding: 5px;
        border: none;
        background-color: transparent;
      }
    }
  }
}
