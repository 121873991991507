@import "/src/theme/colors";
.create-policy {
  &-top {
    display: flex;
    gap: 10px;
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
    &-title {
      display: flex;
      gap: 5px;
      font-size: 22px;
      font-weight: 500;
      align-items: center;

      &-text {
        color: black;
      }
      &-template_name {
        color: $tertiaryColor;
      }
    }
  }
  &-body {
    background: white;
    margin-block-start: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 1px #d9d9d9;
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-title {
      font-size: 20px;
      font-weight: 500;
      color: black;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &-form {
      color: black;
      display: flex;
      &-org {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2%;
        border-right: 1px solid #80808069;
        &-profile {
          background: #ccf4f7;
          font-size: 32px;
          border-radius: 50%;
          height: 80px;
          width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          margin-block-end: 10px;
          & img {
            width: 100%;
          }
        }
        &-name {
          font-weight: 500;
          font-size: 20px;
        }
        &-email {
          color: gray;
          font-size: 14px;
        }
      }
      &-inputs {
        width: 70%;
        padding: 2%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        &-button {
          width: 100%;
        }
        &-input {
          box-shadow: 0px 1px 2px 2px #d9d9d9;
          height: 65px;
          padding: 10px;
          font-size: 14px;
          border-radius: 10px;
          width: 45%;
          display: flex;
          flex-direction: column;
          gap: 5px;
          position: relative;
          & span {
            display: flex;
            justify-content: space-between;
            max-height: 22px;
            overflow: auto;
          }
          & label {
            font-weight: 500;
          }
          & input {
            background-color: white;
            margin: 0;
            padding: 0;
            padding: 3px 10px;
            box-shadow: 0px 0px 0px 1px #d9d9d9;
          }
          svg {
            transition: transform 0.3s ease;
          }
        }
      }
    }
    &-file {
      display: flex;
      justify-content: end;
      &-file_upload {
        background: white;
        width: 70%;
        border-radius: 5px;
        border: 3px dashed #9f9f9f73;
        padding: 10px;
        &-bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-icon {
            color: $primaryButtonColor;
            font-size: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &-drag_text {
            color: gray;
          }
          &-subtext {
            color: gray;
            margin-block-start: 5px;
            margin-block-end: 10px;
          }
          &-button {
            font-size: 16px;
            color: #3aadb8;
            cursor: pointer;
            text-decoration: underline;
            cursor: pointer;
            // &:hover {
            //   background-color: $primaryBackgroundColor;
            //   color: $primaryButtonColor;
            //   border: 1px solid $primaryButtonColor;
            // }
          }
        }
        &-uploaded {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &-file {
            color: $primaryButtonColor;
            display: flex;
            gap: 5px;
            cursor: pointer;
            padding-block: 15px;
            padding-inline: 30px;
            border-radius: 10px;
            background: #3aadb81a;
            &-icon {
              font-size: 35px;
              display: flex;
              align-items: center;
            }
            &-info {
              display: flex;
              flex-direction: column;
              align-items: start;
              &-name {
                color: black;
                font-weight: 500;
              }
              &-size {
                color: gray;
                font-size: 14px;
              }
            }
          }
          &-action {
            display: flex;
            margin-block-start: 20px;
            gap: 20px;
            &-upload_button {
              display: flex;
              gap: 5px;
              border-radius: 5px;
              padding-block: 5px;
              padding-inline: 10px;
              cursor: pointer;
              border: 1px solid $primaryButtonColor;
              color: $primaryButtonColor;
              &:hover {
                border: none;
                background-color: $primaryButtonColor;
                color: $primaryBackgroundColor;
              }
            }
            &-cancel_button {
              display: flex;
              gap: 5px;
              border-radius: 5px;
              padding-block: 5px;
              padding-inline: 10px;
              cursor: pointer;
              border: 1px solid rgb(153, 0, 0);
              color: rgb(153, 0, 0);
              &:hover {
                border: none;
                background-color: rgb(153, 0, 0);
                color: $primaryBackgroundColor;
              }
            }
          }
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: end;
      gap: 20px;
      & button {
        width: 120px;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .create-policy {
    &-top {
    }
    &-body {
      &-form {
        flex-direction: column;
        &-org {
          width: 100%;
        }
        &-inputs {
          width: 100%;
        }
      }
      &-file{
        &-file_upload{
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .create-policy {
    padding: 20px;
    &-top {
      &-title{
        font-size: 18px;
      }
    }
    &-body {
      padding: 3%;
      &-title{
        font-size: 18px;
      }
      &-form {
        &-inputs {
          &-button{
            text-align: center;
          }
          &-input{
            width: 100%;
          }
        }
      }
    }
  }
}

.dropdown-list {
  position: absolute;
  box-shadow: 0px 1px 2px 2px #d9d9d9;
  border-radius: 10px;
  width: 100%;
  left: 0;
  top: 70px;
  background: white;
  z-index: 1;
  max-height: 120px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
}
.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #80808036;
  &:hover {
    background: $secondaryColor;
    cursor: pointer;
  }
}

.error {
  box-shadow: 0px 1px 2px 2px #ffb0b0;
  color: black;
}
