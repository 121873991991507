@import "/src/theme/colors";
.user-avatar-dropdown {
  float: right;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 40px;
  &-name {
    color: #05177d;
    font-weight: 600;
  }
  &-image {
    cursor: pointer;
    transition: transform 0.3s ease;
    border-radius: 50%;
    &:hover {
      transform: scale(1.1);
    }
  }
  &-image img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid #38afb9;
  }

  &-dropdown_container {
    z-index: 3;
    min-width: 250px;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: white;
    gap: 10px;
    right: 45px;
    top: 80px;
    padding: 1%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px rgb(205, 207, 219);
    color: black;
    &-close {
      text-align: end;
      cursor: pointer;
    }
    &-body {
      display: flex;
      flex-direction: column;
      gap: 15px;
      &-top {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-user_avatar {
          & img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
        }
        &-name {
          font-weight: 500;
        }
        &-email {
          color: gray;
          font-size: 14px;
        }
      }
      &-tabs {
        display: flex;
        flex-direction: column;
        &-item {
          display: flex;
          gap: 30px;
          padding-inline: 25px;
          padding-block: 10px;
          border-block-end: 1px solid rgba(226, 226, 226, 0.667);
          transition: background-color 0.3s ease;
          &:hover {
            background-color: #e1f1ff;
            cursor: pointer;
          }
          &:nth-child(1) {
            border-block-start: 1px solid rgba(226, 226, 226, 0.667);
          }
          &-icon {
            color: $tertiaryColor;
          }
          &-text {
            font-weight: 400;
          }
        }
      }
    }
  }
}
