@import '/src/theme/colors';

.custom-checkbox {
  display: inline-flex; // Better alignment for flex-based layouts
  align-items: center; // Vertically centers the checkbox
  position: relative;
  cursor: pointer;
  margin-inline-end: 10px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    z-index: 0; // Prevent high priority interference
  }

  .checkmark {
    position: relative;
    height: 20px; // Adjusted for better alignment
    width: 20px; // Square size for the checkbox
    border: 1px solid #ccc; // Lighter default border
    border-radius: 3px; // Subtle rounding for modern design
    background-color: #fff;
    transition: all 0.2s ease-in-out; // Smooth transitions
  }

  &:hover .checkmark {
    background-color: #f0f0f0; // Light hover effect
  }

  input:checked ~ .checkmark {
    background-color: #05177d; // Active state color
    border-color: #05177d; // Match border with background
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
    left: 7px;
    top: 2px;
    width: 3px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
