@import "/src/theme/colors";
.pricing_plan_selector {
  &_header {
    position: fixed;
    background: #ffffffc9;
    width: 100%;
    height: 100px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 10%;
    padding-block-start: 2%;
    color: #605757;
    &_links {
      display: flex;
      gap: 30px;
      & img {
        height: 75px;
      }
      &_help {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
      }
      &_contact {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
      }
    }
    &_certificates {
      display: flex;
      gap: 30px;
      & img {
        height: 45px;
      }
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 10%;
    padding-block-start: 110px;
    gap: 10px;
    &_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      &_text {
        font-weight: 600;
        font-size: 24px;
      }
      &_subtext {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #605757;
      }
    }
    &_body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_container {
        zoom: 90%;
        width: 90%;
        margin-inline: auto;
        display: flex;
        justify-content: space-around;
        margin-block: 30px;

        &_card {
          width: 280px;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-block-end: 4%;
          transition: transform 0.8s;
          &:nth-child(1) {
            background-image: linear-gradient(
              to top,
              rgba(87, 155, 213, 0.25),
              white
            );
          }
          &:nth-child(2) {
            background-image: linear-gradient(
              to top,
              rgba(58, 173, 184, 0.25),
              white
            );
          }
          &:nth-child(3) {
            background-image: linear-gradient(
              to top,
              rgba(255, 175, 82, 0.25),
              white
            );
          }

          &:nth-child(1) &_top {
            background: $primaryColor;
            &_plan_name {
              color: $primaryColor;
            }
          }

          &:nth-child(2) &_top {
            background: $tertiaryColor;
            &_plan_name {
              color: $tertiaryColor;
            }
          }

          &:nth-child(3) &_top {
            background: $finishButtonColor;
            &_plan_name {
              color: $finishButtonColor;
            }
          }

          &:nth-child(1) &_bottom_button {
            background: $primaryColor;
            &:hover {
              box-shadow: 0px 0px 50px white;
            }
          }
          &:nth-child(2) &_bottom_button {
            background: $tertiaryColor;
            &:hover {
              box-shadow: 0px 0px 50px white;
            }
          }
          &:nth-child(3) &_bottom_button {
            background: $finishButtonColor;
            &:hover {
              box-shadow: 0px 0px 50px white;
            }
          }

          &_top {
            height: 150px;
            border: 1px solid #80808033;
            border-radius: 10px 10px 140px 140px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            background: $primaryColor;
            z-index: 0;
            &_plan_name {
              position: relative;
              margin: auto;
              top: -18px;
              width: 170px;
              height: 35px;
              box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
              display: flex;
              justify-content: center;
              align-items: center;
              background: white;
              border-radius: 5px;
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 0.5px;
              &_ribbon {
                z-index: -1;
                width: 30px;
                height: 30px;
                background: linear-gradient(#ffe500, #ffc369);
                position: absolute;
                left: 132px;
                top: 35px;
              }
              &_ribbon::after {
                content: "";
                position: absolute;
                border: 18px solid transparent;
                border-bottom-color: $tertiaryColor;
                right: -3px;
                bottom: -3px;
              }
            }
            &_text {
              color: white;
              display: flex;
              justify-content: center;
              position: relative;
              &_essential {
                position: absolute;
                left: 60px;
                top: -10px;
              }
              &_icon {
                font-size: 30px;
                display: flex;
                align-items: center;
                font-size: 45px;
                font-weight: 500;
              }
              &_price {
                font-size: 48px;
                font-weight: 500;
              }
              &_month {
                display: flex;
                align-items: flex-end;
                padding-block-end: 10px;
              }
            }
          }
          &_middle {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-block: 5%;
            &_title {
              padding-inline: 20%;
              font-weight: 500;
              text-align: center;
            }
            &_body {
              padding-inline: 20%;
              padding-block-end: 5%;
              &_item {
                display: flex;
                gap: 10px;
                padding-block-start: 5%;
                &_icon {
                  color: black;
                }
                &_text {
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
          &_bottom {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            &_button {
              width: 190px;
              height: 50px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 22px;
              border-radius: 10px;
              background: $primaryColor;
              color: white;
              font-weight: 400;
              box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
              cursor: pointer;
            }
            &_detailed_view {
              display: flex;
              align-items: center;
              gap: 5px;
              color: #605757;
              cursor: pointer;
              &:hover {
                color: black;
                text-decoration: underline;
              }
            }
          }
        }
        &_card:hover {
          -ms-transform: scale(1.08); /* IE 9 */
          -webkit-transform: scale(1.08); /* Safari 3-8 */
          transform: scale(1.08);
        }
      }
      &_bottom {
        & img {
          width: 230px;
        }
      }
    }
  }
  &_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10%;
    padding-block-end: 3%;
    color: #605757;
    font-size: 14px;
    margin-block-start: 70px;
    &_certifications {
      display: flex;
      align-items: center;
      &_images {
        display: flex;
        align-items: center;
        &_pentafold_logo {
          margin-inline-end: 15px;
          display: flex;
          flex-direction: column;
          & span {
            padding-inline-start: 27px;
            font-weight: 500;
          }
          & img {
            width: 180px;
            height: 30px;
          }
        }
        &_iso_certification {
          height: 50px;
        }
        &_cyber_essentials {
          height: 40px;
        }
      }
      &_info {
        &_item {
          display: flex;
          gap: 5px;
          &_key {
            font-weight: 500;
          }
          &_value {
          }
        }
      }
    }
    &_links {
      display: flex;
      gap: 35px;
      & div {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        &:hover {
          font-weight: 500;
          color: black;
        }
      }
    }
  }
}

.effect {
  &:hover {
    color: black;
    font-weight: 500;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    border-radius: 5px;
    height: 0.05em;
    bottom: 22px;
    background: linear-gradient(90deg, #00e9ff, #008aff);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
.Essential {
  background-color: $primaryColor;
}
.Advanced {
  background-color: $tertiaryColor;
}
.Enterprise {
  background-color: $finishButtonColor;
}
.pricing_plan_detailed_view {
  &-title {
    position: absolute;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.3);
    padding-block: 5px;
  }
  &-body {
    padding-block-start: 65px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-data {
      &-key {
        font-weight: 500;
        margin-inline-end: 10px;
      }
      &-value {
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .pricing_plan_selector {
    &_header {
      padding-inline: 5%;
      &_certificates {
        gap: 25px;
        & img {
          height: 35px;
        }
      }
    }
    &_content {
      padding-inline: 0;
      &_title {
        padding-inline: 5%;
      }
      &_body {
        &_container {
          zoom: 80%;
        }
      }
    }
    &_footer {
      padding-inline: 5%;
    }
  }
}
@media screen and (max-width: 900px) {
  .pricing_plan_selector {
    &_footer {
      padding-inline: 5%;
      flex-direction: column-reverse;
      gap: 20px;
      &_links {
        border-block: 1px solid #3737371c;
        padding-block: 10px;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .pricing_plan_selector {
    &_header {
      height: min-content;
      flex-direction: column;
      &_links {
        width: 100%;
        justify-content: space-between;
        &_help {
          margin-left: auto;
        }
      }
      &_certificates {
        width: 100%;
        justify-content: space-around;
        border-block: 1px solid #3737371c;
        padding-block: 1%;
      }
    }
    &_content {
      padding-block-start: 150px;
      &_title {
        &_text {
          font-size: 18px;
        }
      }
      &_body {
        &_container {
          zoom: 70%;
          // flex-direction: column;
          align-items: center;
          gap: 20px;
          width: 95%;
        }
      }
    }
    &_footer {
      &_links {
        width: 100%;
        justify-content: space-around;
      }
      &_certifications {
        width: 100%;
        justify-content: space-around;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .pricing_plan_selector {
    &_header {
      flex-direction: column;
      & img {
        height: 60px;
      }
      &_links {
        width: 100%;
        justify-content: space-between;
        &_help {
          margin-left: auto;
        }
      }
      &_certificates {
        width: 100%;
        & img {
          height: 30px;
        }
      }
    }
    &_content {
      padding-block-start: 135px;
      &_title {
        &_text {
          font-size: 18px;
        }
      }
      &_body {
        &_container {
          zoom: 85%;
          flex-direction: column;
          align-items: center;
          gap: 60px;
          width: 95%;
        }
      }
    }
    &_footer {
      padding-inline: 2%;
      &_certifications {
        &_images {
          &_pentafold_logo {
            & span {
              padding-inline-start: 17px;
              font-size: 10px;
            }
            & img {
              width: 120px;
              height: 20px;
            }
          }
          &_iso_certification {
            height: 35px;
          }
          &_cyber_essentials {
            height: 30px;
          }
        }
        &_info {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .pricing_plan_selector {
    &_header {
      flex-direction: column;
      padding: 0;
      font-size: 14px;
      & img {
        height: 55px;
      }
      &_links {
        width: 100%;
        gap: 25px;
        justify-content: space-between;
        &_help {
          margin-left: auto;
        }
        &_contact {
          margin-inline-end: 10px;
        }
      }
      &_certificates {
        width: 100%;
        justify-content: center;
        gap: 40px;
        & img {
          height: 25px;
        }
      }
    }
    &_content {
      padding-block-start: 135px;
      &_title {
        &_text {
          font-size: 18px;
        }
      }
      &_body {
        &_container {
          zoom: 85%;
          flex-direction: column;
          align-items: center;
          gap: 60px;
          width: 95%;
        }
      }
    }
    &_footer {
      &_certifications {
        &_images {
          flex-wrap: wrap;
          gap: 5px;
          justify-content: center;
          width: 50%;
          border-inline-end: 1px solid rgba(55, 55, 55, 0.1098039216);
          &_pentafold_logo {
            width: 100%;
            align-items: center;
          }
        }
      }
    }
  }
}
