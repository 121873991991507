@import "/src/theme/colors";
.admin-enroll-courses-body {
  &_head {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-block-end: 30px;
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &_container {
    padding: 0px 20px 20px 20px;
    background-color: #ffffff;
    border-radius: 10px;
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #96a0af47;
      padding-block: 20px;
      position: sticky;
      background: white;
      top: 80px;
      z-index: 5;
      p {
        font-size: 17px;
        font-weight: 500;
        margin: 0;
      }
      &-button {
        background-color: #95a0af;
        color: #ffffff;
        padding: 10px 30px;
        border: none;
        border-radius: 5px;
      }
    }
    &-body {
      padding: 20px;
      &-filter {
        &-list {
          padding: 0;
          &-item {
            list-style: none;
          }
        }
      }
      &-content {
        &-head {
          display: flex;
          justify-content: space-between;
          margin-block-end: 20px;
          &-title {
            border-block-end: 3px solid $primaryButtonColor;
            padding-block-end: 10px;
            border-radius: 5px;
          }
          &-search_box {
            min-height: 35px;
            min-width: 35%;
          }
        }
        &-item {
          &-details {
            &-courses {
              margin: 20px;
              box-shadow: 0px 1px 10px 2px #aeaeaea8;
              border-radius: 10px;
              overflow: hidden;
              transition: transform 0.8s;
              height: 380px;
              &-img {
                height: 55%;
                & img {
                  width: 100%;
                  height: 100%;
                }
              }
              &-text {
                height: 45%;
                padding: 10px;
                & h3 {
                  margin: 0;
                }
                &-module {
                  font-weight: 500;
                }
              }
            }
            &-courses:hover {
              -ms-transform: scale(1.08); /* IE 9 */
              -webkit-transform: scale(1.08); /* Safari 3-8 */
              transform: scale(1.08);
            }
          }
        }
        &-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgba(150, 160, 175, 0.2784313725);
          &-pagination {
            flex: 1;
            display: flex;
            justify-content: center;
          }
          &-dropdown {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            padding-inline-end: 10px;
          }
        }
      }
    }
  }
}
.selected {
  background-color: #04147c87;
  color: #ffffff;
}
.active {
  background-color: #04147c;
  color: #ffffff;
}

// Modal Styling
.admin-enroll-courses_modal {
  &-title {
    margin-top: 0;
  }
  &-cards {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    &-trainees {
      padding: 20px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 2px 4px 1px #868686ad;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        margin: 0;
      }
    }
    &-courses {
      padding: 20px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 2px 4px 1px #868686ad;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        margin: 0;
      }
    }
  }
  &-buttons {
    margin: 25px 0 15px;
    display: flex;
    justify-content: space-between;
    &-button {
      padding: 8px 48px;
      border: 1px solid #04147c;
      color: #04147c;
      border-radius: 5px;
    }
  }
}
#topup-button {
  background-color: #04147c;
  color: #ffffff;
}

.bundle-courses-section {
  margin: 20px 0;

  .bundle-course-head {
    display: flex;
    justify-content: space-between;
    &-title {
      border-block-end: 3px solid $primaryButtonColor;
      padding-block-end: 10px;
      border-radius: 5px;
    }
    &-search_box {
      min-height: 35px;
      min-width: 35%;
    }
  }
  .bundle-course-items {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding-inline: 20px;
    padding-block: 40px;
    .bundle-course-item-container {
      .bundle-name {
        background-color: white;
        color: black;
        text-align: center;
        padding: 5px;
        font-size: 14px;
        border-radius: 0 0 5px 5px; /* Match the rounded corners of the icons */
        box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;
        cursor: pointer;
        & svg {
          color: $primaryColor;
        }
      }
      .bundle-name:hover {
        box-shadow: 0px 0px 5px rgb(86 156 214);
      }
    }
  }
  .bundle-course-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(150, 160, 175, 0.2784313725);
    &-pagination {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    &-dropdown {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding-inline-end: 10px;
    }
  }
  .bundle-course-item {
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 2px rgba(174, 174, 174, 0.658823529);
    min-height: 300px;
    transition: transform 0.8s;
    cursor: pointer;
    &:hover {
      -ms-transform: scale(1.08); /* IE 9 */
      -webkit-transform: scale(1.08); /* Safari 3-8 */
      transform: scale(1.08);
    }
    &.selected {
      background-color: #7a83ba; // Selected background color
      color: white; // Ensures all text turns white
    }

    &-name {
      color: black;
      font-weight: 500;
      text-align: left;
      margin-block: 5px;
    }
    &-credit_info {
      color: grey;
      font-size: 15px;
      margin-block: 10px;
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      margin-block-start: 30px;
      flex-wrap: wrap;
      gap: 5px;
      &-course_info {
        display: flex;
        gap: 5px;
        color: grey;
        &-key {
        }
        &-value {
          font-weight: 500;
        }
      }
      &-view {
        text-decoration: underline;
        font-size: 14px;
        color: $primaryButtonColor;
        transition: transform 0.3s, color 0.3s, cursor 0.3s;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    &-name,
    &-credit_info,
    &-bottom,
    &-bottom-course_info,
    &-bottom-view {
      color: inherit; // Ensures all children inherit the parent's color
    }

    .bundle-course-image {
      position: relative;
      cursor: pointer;
      width: 150px;
      height: 150px;
      margin: 0 auto;
      padding-block-start: 15px;
      .bundle-icon-stack {
        position: relative;
        width: 100px;
        height: 100px;

        .stacked-icon {
          position: absolute;
          top: 0;
          left: 0;
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 5px;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
          transition: transform 0.4s ease, rotate 0.4s ease, scale 0.4s ease;
        }

        .stacked-icon-0 {
          z-index: 4;
          transform: translate(0, 0);
        }
        .stacked-icon-1 {
          z-index: 3;
          transform: translate(8px, 8px);
        }
        .stacked-icon-2 {
          z-index: 2;
          transform: translate(16px, 16px);
        }
        .stacked-icon-3 {
          z-index: 1;
          transform: translate(24px, 24px);
        }
      }

      &:hover .stacked-icon {
        transform-origin: center center;
      }

      &:hover .stacked-icon-0 {
        transform: translate(-20px, -20px) rotate(-5deg) scale(1.1);
      }
      &:hover .stacked-icon-1 {
        transform: translate(20px, -20px) rotate(5deg) scale(1.1);
      }
      &:hover .stacked-icon-2 {
        transform: translate(-20px, 20px) rotate(5deg) scale(1.1);
      }
      &:hover .stacked-icon-3 {
        transform: translate(20px, 20px) rotate(-5deg) scale(1.1);
      }
    }

    .bundle-enroll-button {
      margin-top: 10px;
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.bundle-popup {
  padding: 20px;

  h2 {
    margin-bottom: 15px;
    text-align: center;
  }

  .bundle-course-list {
    max-height: 300px; /* Set max height for scrolling */
    overflow-y: auto;
  }

  .bundle-course-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    .course-thumbnail {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 5px;
      margin-right: 15px;
    }

    .bundle-course-info {
      h4 {
        font-size: 16px;
        margin: 0;
        font-weight: 500;
      }

      p {
        font-size: 14px;
        color: #888;
        margin: 5px 0 0;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .admin-enroll-courses-body {
    &_container-body {
      padding: 3%;
      &-filter {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .admin-enroll-courses-body {
    &_container-head {
      flex-direction: column;
      gap: 10px;
      padding: 5%;
      text-align: center;
    }
    &_container-body-content-item-details-courses {
      margin: 0;
      margin-bottom: 10px;
      &-img {
        height: 60%;
      }
      &-text {
        height: 40%;
        & h3 {
          margin-block: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .admin-enroll-courses-body {
    &_container-head {
      top: 60px;
    }
  }
  .bundle-course-item-bottom{
    flex-direction: column;
    gap: 5px;
  }
}

@media screen and (max-width: 450px) {
  .admin-enroll-courses_modal {
    &-buttons {
      flex-direction: column;
      gap: 10px;
    }
  }
}


