@import '/src/theme/colors';
.change-password-popup {
    &-link {
        color: orangered;
      text-decoration: none;
      cursor: pointer;
    }
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }
    &-body {
      max-width: 500px;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 40px;
      border-radius: 8px;
      z-index: 1001;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  
      transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      &-title {
        color: black;
        font-size: 22px;
        font-weight: 600;
        margin-block-end: 10px;
      }
      
      &-submit {
        color: #fff;
        background-color: $primaryColor;
        width: 100%;
        font-size: 14px;
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }
      &-close {
        color: #7e808d;
        background-color: #fff;
        width: 100%;
        font-size: 14px;
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }
      &-close:hover {
        color: $fontColor;
      }
    }
    &-body input {
      margin: 0;
      margin-block-end: 10px;
      background: #edeffc;
      padding: 10px 15px;
      width: 100%;
      border-radius: 5px;
      border: none;
      outline: none;
      
    }
    &-overlay.popup-open {
      transform: translateY(0) scale(1);
    }
  
    &-body.popup-open {
      transform: scale(1);
    }
    &-error{
      color: red;
      margin-block-end: 10px;
    }
    
  }