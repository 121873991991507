@import "/src/theme/colors";
.user-policy-viewer {
  &-top {
    display: flex;
    gap: 10px;
    margin-block-end: 15px;
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
    &-title {
      display: flex;
      gap: 5px;
      font-size: 22px;
      font-weight: 500;
      align-items: center;

      &-text {
        color: black;
      }
      &-template-name {
        color: $tertiaryColor;
      }
    }
  }
  &-body {
    margin-block-start: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 1px #d9d9d9;
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: white;
    &-pdfcontainer {
      width: 80%;
      margin: auto;
      &-head {
        height: 150px;
        border-radius: 10px 10px 0 0;
        background-color: $tertiaryColor;
        color: white;
        padding: 2%;
        &-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-name {
            font-size: 24px;
            font-weight: 500;
          }
          &-date {
            font-size: 12px;
          }
        }
        &-info {
          display: flex;
          gap: 10px;
          justify-content: space-around;
          &-item {
            &-key {
              font-size: 14px;
            }
            &-value {
              font-weight: 500;
            }
          }
        }
      }
      &-body {
      }
      &-bottom {
        margin-block: 2%;
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: end;
        & button {
          width: 120px;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .user-policy-viewer{
    &-body{
      &-pdfcontainer{
        width: 90%;
      }
    }
  }
  
}
@media screen and (max-width: 700px) {
  .user-policy-viewer{
    &-top{
      &-title{
        font-size: 18px;
      }
    }
    &-body{
      &-pdfcontainer{
        width: 95%;
      }
    }
  }
  
}
@media screen and (max-width: 450px) {
  .user-policy-viewer{
    padding: 2%;
    &-body{
      &-pdfcontainer{
        width: 100%;
        &-head{
          height: 120px;
          &-info{
            &-item{
              &-value{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  
}
