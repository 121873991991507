@import "/src/theme/colors";

.app-pagination {
  display: flex;
  justify-content: center;
  &_nav {
    &-list {
      list-style: none;
      display: flex;
      padding: 0;
      gap: 10px;
      &-item {
        box-shadow: 0px 1px 3px 1px #d9d9d9;
        font-size: 14px;
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
        color: black;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 4px 0px rgb(58 173 184);
          background: $secondaryColor;
        }
        &-link {
          width: 35px;
          display: flex;
          height: 35px;
          justify-content: center;
          align-items: center;
          margin: 0;
          border: none;
          background: none;
          font-weight: 500;
        }
      }
    }
  }
}
.app-pagination_nav-list-item.active {
  background-color: $secondaryColor;
  color: black;
  &:hover {
    background-color: $secondaryColor;
    color: black;
  }
}

.nav {
  border-radius: 50%;
}
.dots {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  font-weight: 500;
}
