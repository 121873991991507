.arrow {
    display: flex;
    justify-content: center;
    font-family: 'slick';
    font-size: 50px;
    color: #2f386a;
    opacity: 1;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .prev {
    /* transform: rotate(360deg); */
  }
  
  .next {
    /* No rotation needed */
  }
  @media screen and (max-width:600px) {
    .arrow {
      font-size: 35px;
    }
}

  @media screen and (max-width:450px) {
    .arrow {
      font-size: 25px;
    }
}

@media screen and (max-width:375px) {
  .arrow {
    display: none;
  }
}