@import "/src/theme/colors";
.admin-header {
  width: 100%;
  padding-inline: 20px;
  height: 80px;
  padding: 18px 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  &-logo {
    display: none;
    &-img {
      width: 10%;
    }
    &-img img {
      width: 100%;
      padding-block-start: 5px;
    }
    &-tab {
      display: none;
    }
  }
  &-user {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    &-credits {
      color: #05177d;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    &-avatar {
      float: right;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 40px;
      &-name {
        color: #05177d;
        font-weight: 600;
      }
      &-image {
        cursor: pointer;
        transition: transform 0.3s ease;
        border-radius: 50%;
        &:hover{
          transform: scale(1.1);
        }
      }
      &-image img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 2px solid #38afb9;
      }
    
      &-dropdown_container {
        min-width: 250px;
        position: absolute;
        display: flex;
        flex-direction: column;
        background: white;
        gap: 10px;
        right: 45px;
        top: 80px;
        padding: 1%;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 1px rgb(205, 207, 219);
        color: black;
        &-close {
          text-align: end;
          cursor: pointer;
        }
        &-body {
          display: flex;
          flex-direction: column;
          gap: 15px;
          &-top {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            &-user_avatar {
              & img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
              }
            }
            &-name {
              font-weight: 500;
            }
            &-email {
              color: gray;
              font-size: 14px;
            }
          }
          &-tabs {
            display: flex;
            flex-direction: column;
            &-item {
              display: flex;
              gap: 30px;
              padding-inline: 25px;
              padding-block: 10px;
              border-block-end: 1px solid rgba(226, 226, 226, 0.667);
              transition: background-color 0.3s ease;
              &:hover {
                background-color: #e1f1ff;
                cursor: pointer;
              }
              &:nth-child(1) {
                border-block-start: 1px solid rgba(226, 226, 226, 0.667);
              }
              &-icon {
                color: $tertiaryColor;
              }
              &-text {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    &-superapp {
      &-button {
        padding: 12px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 20px;
        }
      }
      &-container {
        display: none;
        position: absolute;
        top: 80px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 0.2px #00000047;
        right: 10px;
        width: 350px;
        background: #eaf7f8;
        padding: 10px;

        &-row {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          background: white;
          border-radius: 10px;
          padding: 10px;
          &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100px;
            &-icon {
              width: 50px;
              padding: 12px;
              border-radius: 40px;
              display: flex;

              img {
                width: 100%;
              }
            }

            &-title {
              padding-top: 8px;
              text-align: center; // Ensure the title is centered
            }
          }

          &-item:hover {
            background-color: #71bdff36;
            cursor: pointer;
            border-radius: 10px;
          }
        }
      }
    }
    &-superapp-button:hover {
      background-color: #e2e4ecb5;
    }
    &-superapp-button:active {
      background-color: rgb(255 255 255);
      box-shadow: 0px 0px 5px 3px #e2e4ec;
    }
    &-superapp-button.active {
      background-color: rgb(255 255 255);
      box-shadow: 0px 0px 5px 3px #e2e4ec;
    }
  }
}
@media screen and (max-width: 1024px) {
  // .admin-header{
 
  // }
}

@media screen and (max-width: 820px) {
  .admin-header {
    height: 60px;
    padding: 0;
    font-size: 14px;
    &-logo {
      &-img {
        display: none;
      }
      &-tab {
        display: block;
        width: 10%;
      }
      &-tab img {
        width: 100%;
        padding-block-start: 5px;
      }
      &-title {
        &-text {
          font-size: 20px;
        }
        &-subtext {
          font-size: 18px;
        }
      }
    }
    &-user {
      margin-right: 15px;
      &-avatar {
        margin: 0;
        & img {
          width: 80%;
        }
      }
    }
  }
}



@media screen and (min-width: 451px) {
  .admin-header {
    &-hambuger {
      display: none;
    }
  }
}

@media screen and (max-width: 450px) {
  .admin-header {
    flex-direction: row;
    justify-content: space-around;
    font-size: 12px;
    & svg {
      margin-inline-end: 15px;
      min-width: 25px;
    }
    &-hambuger {
      color: #0f2083;
      font-size: 28px;
    }
    &-logo {
      display: flex;
      justify-content: center;
      &-img {
        display: block;
        width: 100px;
        img {
          width: 100%;
          padding-block-start: 5px;
        }
      }
      &-tab {
        display: none;
      }
      &-title {
        &-text {
          font-size: 18px;
        }
        &-subtext {
          font-size: 16px;
        }
      }
    }
  }
}

.selected-app {
  background: #e1f1ff;
  border-radius: 10px;
}
