@import "/src/theme/colors";
.app-sidebar {
  width: 350px;
  background-color: #ffffff;
  padding: 40px 20px;
  width: 20%;
  position: fixed;
  right: 0;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
  &_user {
    text-align: center;
    border-block-end: solid 1px rgba(0, 0, 0, 0.1098039216);
    padding-block-end: 10px;
    &-avatar img {
      border-radius: 100px;
      width: 40%;
    }
    &-details h3 {
      color: $fontColor;
      margin: 10px 0 0;
    }
    &-details p {
      font-weight: 500;
      margin: 0;
      padding: 0;
      color: #fd8b1d;
    }
  }
  &_dynamic-selector {
    color: $fontColor;
  }
  &_dynamic-selector ul {
    list-style: none;
    padding: 0;
  }

  // &_dynamic-selector li{
  //     margin: 15px;
  // }
  &_dynamic-selector {
    & h3 {
      text-align: center;
    }
    &-course {
      font-size: 15px;
      color: $fontColor;
      font-weight: 600;
      &-link {
        padding: 15px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-evenly; 
        &-icon {
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
      
          & img {
            width: 30px;
          }
        }
      
        &-text {
          display: flex;
          align-items: center;
          font-weight: 500;
          padding-left: 10px;
          font-size: 16px;
          flex-grow: 1; 
          max-width: 150px; 
        }
      
        & svg {
          margin-left: auto; 
          color: gray;
        }
      }
      
      &-link h3 {
        display: inline-block;
        padding-left: 10px;
        font-weight: 500;
      }
      &-link:hover {
        background-color: $buttonHoverColor;
      }
      &-link.selected {
        background-color: $secondaryColor;
        color: $fontColor;
      }
      &-module {
        &-chapters {
          background: #fafbff;
          border-radius: 5px;
          padding: 10px;
          &-chapter {
            cursor: pointer;
            border-radius: 5px;
            display: flex;
            align-items: center;
            gap: 10px;
            padding-block: 5px;
            padding-inline-start: 20px;
            font-weight: 400;
            font-size: 14px;
            &-icon {
              display: flex;
              justify-content: center;
              align-items: flex-end;
            }
            &-text {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          &-chapter:hover {
            background-color: $buttonHoverColor;
            font-weight: 500;
          }
          &-chapter.selected {
            background-color: $buttonHoverColor;
            color: $fontColor;
          }
        }
      }
    }
    &-controls {
      padding: 10px;
      color: grey;
      display: flex;
      gap: 8px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: $fontColor;
      }
      & span:hover {
        font-weight: 500;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .app-sidebar {
    padding: 40px 10px;
    &_user {
      &-details h3 {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .app-sidebar {
    width: 10%;
    background-color: #ffffff;
    overflow-y: auto;
    transition: 0.5s;
    position: fixed;
    right: 0;
    height: 100%;
    z-index: 3;
    padding: 40px 10px;
    &_collapsed {
      width: 30%;
      left: 0;
      position: relative;
      padding-bottom: 15px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &-hamburger {
        cursor: pointer;
        // animation: open-btn-motion 500ms ease forwards;
        transition: all 0.3s ease-in-out;
        &-bar {
          display: block;
          width: 25px;
          height: 2px;
          margin: 5px auto;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          background-color: $primaryColor;
        }
      }
      &-avatar {
        padding-top: 30px;
      }
      &-avatar img {
        width: 100%;
      }
    }

    &_collapsed.expand {
      right: 80px;
      animation: close-btn-motion 500ms ease forwards;
    }

    &_collapsed.expand
      .app-sidebar_collapsed-hamburger
      .app-sidebar_collapsed-hamburger-bar {
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }
    &_collapsed.expand
      .app-sidebar_collapsed-hamburger
      .app-sidebar_collapsed-hamburger-bar:nth-child(2) {
      opacity: 0;
    }
    &_collapsed.expand
      .app-sidebar_collapsed-hamburger
      .app-sidebar_collapsed-hamburger-bar:nth-child(1) {
      transform: translateY(7px) rotate(45deg);
    }
    &_collapsed.expand
      .app-sidebar_collapsed-hamburger
      .app-sidebar_collapsed-hamburger-bar:nth-child(3) {
      transform: translateY(-7px) rotate(-45deg);
    }
    &_user-avatar img {
      width: 100%;
      transition: 0.5s;
    }
    &_user-avatar.expand img {
      width: 40%;
    }
  }
  .app-sidebar.shadow {
    box-shadow: 2px 10px 10px 10px rgb(0 0 0 / 10%);
  }
  @keyframes close-btn-motion {
    from {
      right: 0;
    }
    to {
      right: 80px;
    }
  }
  @keyframes open-btn-motion {
    from {
      right: 80px;
    }
    to {
      right: 0;
    }
  }
  @keyframes font_size_animate {
    from {
      font-size: 2px;
    }
    to {
      font-size: 18px;
    }
  }

  .collapse_selector {
    transition: 0.25s all ease-in-out;
    animation: font-animate;
  }
}

.locked {
  &:hover {
    background-color: #c0bbbb;
  }
}
