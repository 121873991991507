body{
  margin: 0;
  padding: 0;
  /* background-color: #FAFBFF; */
}
.app{
  height: 100vh;
}
.app-parent-container{
  padding: 50px;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
}


@media screen and (max-width: 820px) {
  .app-parent-container {
    padding-inline-start: 100px;
  }
}
@media screen and (min-width: 821px) {
  .app-parent-container {
    margin-inline-start: 200px;
  }
}

@media screen and (max-width: 620px) {
  .app-parent-container{
        padding: 25px;
        padding-inline-start: 85px;
  }
}

@media screen and (max-width: 450px) {
  .app-parent-container {
      margin-top: 40px;
      padding-inline-start: 25px;
      /* padding-block-start: 65px; */
      height: auto;
  }
}