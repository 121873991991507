@import '/src/theme/colors';
.chapter_quiz_result{
    box-shadow: 1px 7px 11px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-block-start: 50px;
    height: max-content;
    overflow-y: auto;
    width: inherit;
    color: $fontColor;
    &-title {
      width: 100%;
      padding: 2%;
      background-color: #edeffc;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;      ;
      &-text{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80%;
        font-weight: 600;
        font-size: 30px;
      }
      &-details{
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      
  
    }
    &-body {
      background-color: white;
      border-radius: 10px;
      padding: 5%;
      display: flex;
      &-table{
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        &-head{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          gap:10px;
          &-item{
            font-weight: bolder;
            width: 25%;
            text-align: center;
          }
        }
        &-body{
          display: flex;
          flex-direction: column;
          gap: 5px;
          &-row{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap:10px;
            &-item{
              width: 25%;
              text-align: center;
            }
            // &-item button{
            //   color: #fff;
            //   background-color: $primaryColor;
            //   font-size: 12px;
            //   border-radius: 5px;
            //   border: 1px solid $primaryColor;
            //   outline: none;
            //   letter-spacing: 1px;
            //   cursor: pointer;
            //   padding-inline: 5px;
            //   padding-block: 5px;
            // }
  
          }
        }
        &-bottom{
          display: flex;
          justify-content: space-between;
          gap: 10px;
          &-back button{
            width: 130px;
          }
          &-buttons{
            display: inline-flex;
          }
          &-buttons button{
            margin-left: 10px;
            width: 130px;
          }
        }
        // &-bottom button{
        //   margin-top: 10px;
        //   color: white;
        //   background-color: $primaryColor;
        //   font-size: 14px;
        //   padding: 10px 20px;
        //   border-radius: 5px;
        //   border: none;
        //   cursor: pointer;
        //   letter-spacing: 1px;
        // }
  
      }
    }
    
  }

  @media screen and (max-width:600px) {
    .chapter_quiz_result{
      &-title{
        gap: 10px;
        &-text{
          font-size: 24px;
        }
        &-details{
          flex-direction: column;
        }
      }
      &-body{
        &-table{
          &-bottom{
            flex-direction: column;
            gap: 0px;
            & button{
              margin-top: 5px;
              padding: 6px 20px;
            }
          }
        }
      }
    }
}