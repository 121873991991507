@import '/src/theme/colors';

.delete_class_popup {
  &-button {
    font-weight: 600;
    width: 80px;
    height: 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(248, 248, 248);
    color: black;
  }
  &-button:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  &-content {
    color: black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: rgb(232 235 240);
    padding: 30px;
    width: 50%;
    max-width: 400px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    &-close_button {
      color: rgb(184 187 202);
      text-align: end;
      cursor: pointer;
      &:hover {
        color: grey;
      }
    }
    &-title {
      font-weight: bolder;
      font-size: 20px;
      margin-block-end: 10px;
    }
    &-text {
      margin-block-end: 20px;
    }

    &-action {
      display: flex;
      justify-content: flex-end;
      gap: 2%;

      button {
        font-weight: 600;
        width: 80px;
        height: 30px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }

      &-confirm {
        background-color: rgb(248, 248, 248);
        color: black;
      }

      &-cancel {
        background-color: $primaryColor;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .delete_class_popup {
    &-content{
      width: 80%;
      &-title{
        font-size: 18px;
      }
      &-text{
        font-size: 16px;
      }
    }
  }
}

