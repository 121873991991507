.search-box {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    box-shadow: 0px 0px 3px 0px rgb(205, 207, 219);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding-inline: 15px;
  
    &-input-wrapper {
      width: 100%;
      height: 100%;
      &-input {
        background: white;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        padding-inline-start: 20px;
        font-size: 15px;
      }
    }
    &-suggestions {
      z-index: -1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: absolute;
      width: 100%;
      background: white;
      top: 20px;
      left: 0;
      padding-block-start: 20px;
      box-shadow: 0px 10px 10px 1px rgb(205, 207, 219);
      border-radius: 0 0 10px 10px;
  
      &-container {
        max-height: 150px;
        overflow: auto;
        width: 100%;
        &-suggestion {
            font-size: 16px;
            color: black;
            padding-block: 15px;
            padding-inline-start: 40px;
            width: 100%;
            text-align: start;
            cursor: pointer;
      
            &:hover {
              background-color: rgba(113, 189, 255, 0.2117647059);
              font-weight: 500;
              position: relative;
              color: black;
      
              &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 5px;
                background: #a0d8dd;
                left: 0;
                bottom: 0;
                border-radius: 0 10px 10px 0;
              }
            }
            &.active {
              background-color: rgba(113, 189, 255, 0.2117647059);
              font-weight: 500;
              position: relative;
              color: black;
      
              &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 5px;
                background: #a0d8dd;
                left: 0;
                bottom: 0;
                border-radius: 0 10px 10px 0;
              }
            }
          }
        &::-webkit-scrollbar {
          width: 2px;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #c0bbbb;
          border-radius: 4px;
        }
      }
  
      
    }
  
    & svg {
      font-size: 14px;
      color: black;
    }
  }
  