@import "/src/theme/colors";
.user-settings {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  &-top{
    display: none;
  }
  &-body {
    width: 100%;
    display: flex;
    // padding-block-start: 80px;
    &-sidebar {
      position: relative;
      box-shadow: 1px 7px 10px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      // max-width: 200px;
      color: $fontColor;
      z-index: 2;
    }
    &-content {
      margin-inline-start: 215px;
      background-color: $primaryBackgroundColor;
      width: 100%;
      height: 100%;
      color: $fontColor;
      overflow-y: auto;
      &-header {
        top: 0;
        position: sticky;
        background-color: #fafbff;
        z-index: 1;
      }
      &-elements {
        padding-inline: 60px;
        padding-block: 10%;
        position: relative;
        z-index: 0;
        &-top {
          display: flex;
          gap: 10px;
          margin-block-end: 15px;
          &-icon {
            color: black;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: transform 0.3s, color 0.3s, cursor 0.3s;
            &:hover {
              transform: scale(1.1);
              color: $tertiaryColor;
            }
          }
          &-title {
            display: flex;
            gap: 5px;
            font-size: 22px;
            font-weight: 500;
            align-items: center;

            &-text {
              color: black;
            }
            &-template_name {
              color: $tertiaryColor;
            }
          }
        }
        &-body {
          &-org_info {
            &-title {
              color: $tertiaryColor;
              display: flex;
              align-items: center;
              gap: 10px;
              margin-block: 10px;
              font-size: 18px;
            }
            &-content {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              &-profile {
                width: 30%;
                &-image {
                  display: flex;
                  justify-content: center;
                  position: relative;
                  border-radius: 50%;
                  width: 100%;
                  & img {
                    width: 100%;
                    max-width: 200px;
                    border-radius: 50%;
                  }
                  &-icon {
                    position: absolute;
                    bottom: 25px;
                    right: 10px;
                    background: white;
                    display: flex;
                    width: 30px;
                    height: 30px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    box-shadow: 1px 2px 5px 0px rgb(143 143 143);
                    & svg {
                      color: $tertiaryColor;
                    }
                  }
                }
              }
              &-info {
                width: 65%;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: space-between;
                &-item {
                  width: 47%;
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  &-label {
                    font-weight: 500;
                    color: gray;
                    font-size: 14px;
                  }
                  &-input {
                    background: white;
                    box-shadow: 1px 1px 2px 1px rgb(205, 207, 219);
                    padding-block: 5px;
                    padding-inline: 10px;
                    border-radius: 5px;
                    color: black;
                  }
                }
              }
            }
            &-bottom {
              display: flex;
              justify-content: end;
              margin-block-start: 20px;
              gap: 20px;
              & button {
                width: max-content;
              }
            }
          }
          &-plan_info {
            &-title {
              color: $tertiaryColor;
              display: flex;
              align-items: center;
              gap: 10px;
              margin-block: 10px;
              font-size: 18px;
            }
            &-sub_title {
              font-weight: 500;
              color: black;
              padding-block: 20px;
            }
            &-content {
              display: flex;
              flex-wrap: wrap;
              gap: 50px;
              align-items: center;
              &-card {
                padding: 2%;
                width: 320px;
                display: flex;
                flex-direction: column;
                box-shadow: 1px 2px 3px 2px rgb(205, 207, 219);
                background: white;
                border-radius: 5px;
                cursor: pointer;
                color: black;
                position: relative;
                gap: 20px;
                &-top {
                  display: flex;
                  justify-content: space-between;
                  & svg {
                    color: $tertiaryColor;
                  }
                }
                &-data {
                  font-weight: 500;
                  font-size: 18px;
                }
                &-bottom {
                  display: flex;
                  justify-content: end;
                  & svg {
                    color: rgb(190, 190, 190);
                    font-size: 24px;
                  }
                }
              }
              &-add_card {
                padding: 2%;
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 4px 0px rgb(205, 207, 219);
                background: white;
                border-radius: 5px;
                cursor: pointer;
                color: black;
                position: relative;
                gap: 20px;
                font-size: 30px;
                & svg {
                  color: $tertiaryColor;
                }
              }
            }
          }
          &-jobrole {
            &-title {
              color: $tertiaryColor;
              display: flex;
              align-items: center;
              gap: 10px;
              margin-block: 10px;
              font-size: 18px;
            }
            &-content {
              &-add-input {
                width: 200px;
                display: flex;
                align-items: center;
                box-shadow: 0px 0px 3px 0px rgb(205, 207, 219);
                background: white;
                padding-inline-end: 10px;
                margin-block: 15px;
                border-radius: 5px;
                cursor: pointer;
                color: black;
                & input {
                  background: white;
                  border: 1px solid #80808026;
                  padding: 5px 10px;
                  margin: 10px 10px;
                }
                &-icons {
                  display: flex;
                  gap: 5px;
                  font-size: 20px;
                }
                & svg {
                  opacity: 40%;
                  transition: transform 0.3s, color 0.3s, cursor 0.3s,
                    opacity 0.3s;
                  &:hover {
                    transform: scale(1.1);
                    opacity: 100%;
                    // color: $tertiaryColor;
                  }
                }
              }
              &-add_button {
                width: 200px;
                display: flex;
                gap: 20px;
                align-items: center;
                box-shadow: 0px 0px 3px 0px rgb(205, 207, 219);
                background: white;
                padding-block: 10px;
                padding-inline: 25px;
                margin-block: 15px;
                border-radius: 5px;
                cursor: pointer;
                color: black;
                & svg {
                  color: $tertiaryColor;
                }
              }
              &-jobroles_container {
                &-item {
                  width: max-content;
                  display: flex;
                  gap: 20px;
                  align-items: center;
                  box-shadow: 0px 0px 3px 0px rgb(205, 207, 219);
                  background: white;
                  padding-block: 10px;
                  padding-inline: 25px;
                  border-radius: 5px;
                  cursor: pointer;
                  color: black;
                  &-text {
                  }
                  &-icons {
                    display: flex;
                    gap: 20px;
                  }
                  &-edit-input {
                    width: 200px;
                    display: flex;
                    align-items: center;
                    background: white;
                    gap: 10px;
                    border-radius: 5px;
                    cursor: pointer;
                    color: black;
                    & input {
                      margin: 0;
                      background: white;
                      border: 1px solid #80808026;
                      padding: 5px 10px;
                    }
                    &-icons {
                      display: flex;
                      gap: 5px;
                      font-size: 20px;
                    }
                    & svg {
                      opacity: 40%;
                      transition: transform 0.3s, color 0.3s, cursor 0.3s,
                        opacity 0.3s;
                      &:hover {
                        transform: scale(1.1);
                        opacity: 100%;
                        // color: $tertiaryColor;
                      }
                    }
                  }
                }
              }
              &-bottom {
                display: flex;
                justify-content: end;
                margin-block: 20px;
                gap: 20px;
                & button {
                  width: max-content;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .user-settings {
    &-body {
      &-content {
        padding-inline: 3%;
        &-elements {
          padding-inline: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .user-settings {
    &-body {
      // padding-block-start: 60px;
      &-content {
        margin-inline-start: 8%;
        padding: 0 20px;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .user-settings {
    &-body {
      &-content {
        &-elements {
          &-body {
            &-org_info {
              &-content {
                flex-direction: column;
                align-content: center;
                &-profile {
                  width: 100%;
                }
                &-info {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .user-settings {
   &-top {
      position: fixed;
      display: block;
      background: #fafbff;
      padding-inline: 5%;
      padding-block-start: 5%;
      & svg{
        cursor: pointer;
      }
    }
    &-body {
      &-content {
        margin-inline-start: 0%;
        padding: 20px;
        padding-block-start: 60px;
        &-elements {
          padding: 0;
          &-body {
            &-org_info {
              &-content {
                &-info {
                 &-item{
                  width: 100%;
                 }
                }
              }
            }
          }
        }
      }
    }
  }
}
