@import "/src/theme/colors";
.survey_form {
  &_header {
    position: fixed;
    background: #ffffffc9;
    width: 100%;
    height: 100px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 10%;
    padding-block-start: 2%;
    color: #605757;
    &_links {
      display: flex;
      gap: 30px;
      & img {
        height: 75px;
      }
      &_help {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
      }
      &_contact {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
      }
    }
    &_certificates {
      display: flex;
      gap: 30px;
      & img {
        height: 45px;
      }
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 20%;
    padding-block-start: 170px;
    gap: 30px;
    &_head {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background: $tertiaryColor;
      color: white;
      border-radius: 10px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
      padding-block: 5%;
      padding-inline: 10%;
      &_title {
        font-size: 24px;
        font-weight: 500;
      }
      &_content {
        font-weight: 500;
      }
    }

    &_body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      &_question {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        height: min-content;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding-inline: 10%;
        padding-block: 3%;
        border-block-start: 3px solid $tertiaryColor;

        &_text {
          font-weight: 500;
        }
        &_options {
          display: flex;
        }
      }
      &_bottom {
        & img {
          width: 230px;
        }
      }
    }
    &_bottom{
      display: flex;
      justify-content: end;
      width: 100%;
    }
  }
  &_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10%;
    padding-block-end: 3%;
    color: #605757;
    font-size: 14px;
    margin-block-start: 70px;
    &_certifications {
      display: flex;
      align-items: center;
      &_images {
        display: flex;
        align-items: center;
        &_pentafold_logo {
          margin-inline-end: 15px;
          display: flex;
          flex-direction: column;
          & span {
            padding-inline-start: 27px;
            font-weight: 500;
          }
          & img {
            width: 180px;
            height: 30px;
          }
        }
        &_iso_certification {
          height: 50px;
        }
        &_cyber_essentials {
          height: 40px;
        }
      }
      &_info {
        &_item {
          display: flex;
          gap: 5px;
          &_key {
            font-weight: 500;
          }
          &_value {
          }
        }
      }
    }
    &_links {
      display: flex;
      gap: 35px;
      & div {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        &:hover {
          font-weight: 500;
          color: black;
        }
      }
    }
  }
}

.effect {
  &:hover {
    color: black;
    font-weight: 500;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    border-radius: 5px;
    height: 0.05em;
    bottom: 22px;
    background: linear-gradient(90deg, #00e9ff, #008aff);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
.Essential {
  background-color: $primaryColor;
}
.Advanced {
  background-color: $tertiaryColor;
}
.Enterprise {
  background-color: $finishButtonColor;
}
.pricing_plan_detailed_view {
  &-title {
    position: absolute;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.3);
    padding-block: 5px;
  }
  &-body {
    padding-block-start: 65px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-data {
      &-key {
        font-weight: 500;
        margin-inline-end: 10px;
      }
      &-value {
      }
    }
  }
}
.thank_you_message_container{
  display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        height: 150px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding-inline: 10%;
        padding-block: 3%;
        border-block-start: 3px solid $tertiaryColor;
}

@media screen and (max-width: 1050px) {
  .survey_form {
    &_header {
      padding-inline: 5%;
      &_certificates {
        gap: 25px;
        & img {
          height: 35px;
        }
      }
    }
    &_content {
      padding-inline: 0;
      &_title {
        padding-inline: 5%;
      }
      &_body {
        &_container {
          zoom: 80%;
        }
      }
    }
    &_footer {
      padding-inline: 5%;
    }
  }
}
@media screen and (max-width: 900px) {
  .survey_form {
    &_footer {
      padding-inline: 5%;
      flex-direction: column-reverse;
      gap: 20px;
      &_links {
        border-block: 1px solid #3737371c;
        padding-block: 10px;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .survey_form {
    &_header {
      height: min-content;
      flex-direction: column;
      &_links {
        width: 100%;
        justify-content: space-between;
        &_help {
          margin-left: auto;
        }
      }
      &_certificates {
        width: 100%;
        justify-content: space-around;
        border-block: 1px solid #3737371c;
        padding-block: 1%;
      }
    }
    &_content {
      padding-block-start: 150px;
      &_title {
        &_text {
          font-size: 18px;
        }
      }
      &_body {
        &_container {
          zoom: 70%;
          // flex-direction: column;
          align-items: center;
          gap: 20px;
          width: 95%;
        }
      }
    }
    &_footer {
      &_links {
        width: 100%;
        justify-content: space-around;
      }
      &_certifications {
        width: 100%;
        justify-content: space-around;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .survey_form {
    &_header {
      flex-direction: column;
      & img {
        height: 60px;
      }
      &_links {
        width: 100%;
        justify-content: space-between;
        &_help {
          margin-left: auto;
        }
      }
      &_certificates {
        width: 100%;
        & img {
          height: 30px;
        }
      }
    }
    &_content {
      padding-block-start: 135px;
      &_title {
        &_text {
          font-size: 18px;
        }
      }
      &_body {
        &_container {
          zoom: 85%;
          flex-direction: column;
          align-items: center;
          gap: 60px;
          width: 95%;
        }
      }
    }
    &_footer {
      padding-inline: 2%;
      &_certifications {
        &_images {
          &_pentafold_logo {
            & span {
              padding-inline-start: 17px;
              font-size: 10px;
            }
            & img {
              width: 120px;
              height: 20px;
            }
          }
          &_iso_certification {
            height: 35px;
          }
          &_cyber_essentials {
            height: 30px;
          }
        }
        &_info {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .survey_form {
    &_header {
      flex-direction: column;
      padding: 0;
      font-size: 14px;
      & img {
        height: 55px;
      }
      &_links {
        width: 100%;
        gap: 25px;
        justify-content: space-between;
        &_help {
          margin-left: auto;
        }
        &_contact {
          margin-inline-end: 10px;
        }
      }
      &_certificates {
        width: 100%;
        justify-content: center;
        gap: 40px;
        & img {
          height: 25px;
        }
      }
    }
    &_content {
      padding-block-start: 135px;
      &_title {
        &_text {
          font-size: 18px;
        }
      }
      &_body {
        &_container {
          zoom: 85%;
          flex-direction: column;
          align-items: center;
          gap: 60px;
          width: 95%;
        }
      }
    }
    &_footer {
      &_certifications {
        &_images {
          flex-wrap: wrap;
          gap: 5px;
          justify-content: center;
          width: 50%;
          border-inline-end: 1px solid rgba(55, 55, 55, 0.1098039216);
          &_pentafold_logo {
            width: 100%;
            align-items: center;
          }
        }
      }
    }
  }
}

.form__radio-input {
  display: none;
}

.form__radio-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.form__radio-button {
  height: 20px;
  width: 20px;
  border: 2px solid $tertiaryColor;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  // transform: translateY(-2px);

  &::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transition: opacity 0.1s;
    transform: translate(-50%, -50%);
    background-color: $tertiaryColor;
    opacity: 0;
  }
}

.form__radio-input:checked + .form__radio-label .form__radio-button::after {
  opacity: 1;
}

.contact_info {
  margin: 0;
  background: white;
  border: 1px solid #cccccc;
}

.scaling{
  flex-direction: column;
  gap: 10px;
}
.multiple_choice{
gap: 10px;
flex-direction: column;
}
