@import '/src/theme/colors';
*{
    box-sizing: border-box;
}

.login-outer_body-container{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // background-color: #edeffc;
    min-height: 100%;
    margin: 10%;
}

.login-register{
    position: relative;
    width: 850px;
    max-width: 100%;
    min-height: 560px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25),
                0 10px 10px rgba(0,0,0,0.22);
}
.register-container, .login-container{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.register-container{
    width: 50%;
    opacity: 0;
    z-index: 1;
    &_form-error{
        color: rgb(221, 34, 34);
    }
}

.login-container{
    width: 50%;
    z-index: 2;
    &_form-error{
        color: rgb(221, 34, 34);
    }
}

.login-register_form{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
    &-input-container{
        position: relative;
        width: 100%;
        & span{
            position: absolute;
            right: 10px;
            top: 15px;
            color: #c6bebe;
        }
        & input{
            margin-inline: 0;
        }
    }
}

h1{
    font-weight: bold;
    margin: 0;
}

p{
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 15px 0 20px;
}

input{
    background: #edeffc;
    padding: 10px 15px;
    margin:  8px 15px;
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
}

a{
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

.login-register_form-button {
    color: #fff;
    background-color: $primaryColor;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 55px;
    margin: 20px;
    border-radius: 20px;
    border: 1px solid $primaryColor;
    outline: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    cursor: pointer;
  
    @media screen and (max-width: 820px) {
      font-size: 10px;
      padding: 11px 45px;
      margin: 15px;
      border-radius: 18px;
    }  
  }
  

button:active{
    transform: scale(0.90);
}

.overlay-container_body-left #login, 
.overlay-container_body-right #register{
    background-color: transparent;
    border: 2px solid #fff;
}

.login-register.right-panel-active .register-container{
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

.login-register.right-panel-active .login-container{
    transform: translateX(100%);
    opacity: 0;
}
.overlay-container{
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.login-register.right-panel-active .overlay-container{
    transform: translateX(-100%);
}

.overlay-container_body{
    position: relative;
    color: #fff;
    background-color: $primaryColor;
    left: -100%;
    height: 100%;
    width: 200%;
    background: linear-gradient(to right, $primaryColor, #2662d6);
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}
.login-register.right-panel-active .overlay-container_body{
    transform: translateX(50%);
}
.overlay-container_body-left, .overlay-container_body-right{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-container_body-left{
    transform: translateX(-20%);
}

.overlay-container_body-right{
    right: 0;
    transform: translateX(0);
}

.login-register.right-panel-active .overlay-container_body-left{
    transform: translateX(0);
}

.login-register.right-panel-active .overlay-container_body-right{
    transform: translateX(20%);
}

.overlay-container_body-right-image {
    width: 75%;
}

.overlay-container_body-left-image {
    width: 60%;
}


// Responsive Screens starting from 520px

.app-authresp {
    background-color: $primaryColor;
    color: #fff;
    height: 100vh;
}

.app-authresp.sign_up .app-authresp-content .app-authresp-content_login ,
.app-authresp.sign_in .app-authresp-content .app-authresp-content_register{
    opacity: 0;
}
.app-authresp.sign_up .app-authresp-form .app-authresp-form_login,
.app-authresp.sign_in .app-authresp-form .app-authresp-form_register {
    opacity: 0;
}
.app-authresp.sign_up .app-authresp-content .app-authresp-content_register,
.app-authresp.sign_in .app-authresp-content .app-authresp-content_login{

    transform: translateX(0);
}
.app-authresp.sign_up .app-authresp-form{
    height: 70%;
}
.app-authresp.sign_in .app-authresp-form{
    height: 35%;
}

.app-authresp.sign_in .app-authresp-form .app-authresp-form_login,
.app-authresp.sign_up .app-authresp-form .app-authresp-form_register {
    transform: scale(1);
}

.app-authresp{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-content{
        text-align: center;
        height: 100%;
            h1{
                font-size: 24px;
            }
            p{

                font-size: 12px;
            }
        &_login{
            visibility: visible;
            opacity: 1;
            transition: 0.5s linear;      
            position: absolute;
            z-index: 1;
            padding: 40px;
            transform: translateX(-250%);      
        }
        &_register{
            visibility: visible;
            opacity: 1;
            transition: 0.5s linear;       
            position: absolute;
            z-index: 0;
            padding: 12px 40px;
            height: 30%;
            transform: translateX(-250%);
            img{
                width: 40%;
            }
        }
    }
    &-form{
        text-align: center;
        background-color: #fff;
        color: $fontColor;
        padding: 18px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        text-align: center;
        transition: height 0.5s linear;
        input{
            margin: 8px;
            width: 75%;
        }
        p{
            margin: 0;
        }
        &_login{
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            transform: scale(0);
            transition: 0.2s ease-in-out;
            opacity: 1;
            & p{
                margin-bottom: 30px;
            }
            &-input-container{
                position: relative;
                margin: auto;
                width: 75%;
                & span{
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    color: #c6bebe;
                }
                & input{
                    width: 100%;
                    margin-inline: 0;
                }
            }
            h1{
                padding-bottom: 15px;
                font-size: 24px;
            }
        }
        &_register{
            display: block;
            transform: scale(0);
            transition: 0.2s ease-in-out;
            opacity: 1;
            &-input-container{
                position: relative;
                margin: auto;
                width: 75%;
                & span{
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    color: #c6bebe;
                }
                & input{
                    width: 100%;
                    margin-inline: 0;
                }
            }
            h1{
                font-size: 24px;
            }
            

        }
    }
}
