// Login & Register

$primaryColor: #579bd6;
$fontColor: #05167c;
$secondaryColor: #71bdff36;
$tertiaryColor: #3aadb8;
$buttonHoverColor: #3aadb842;
$titleColor: black;

// Theme dashboard background colors
$primaryBackgroundColor: #fafbff;

// Button Colors
$primaryButtonColor: #3aadb8;
$secondaryButtonColor: #579bd6;
$startButtonColor: linear-gradient(to right, #3aadb8, #7ddde4);
$finishButtonColor: #fd8f26;
$backButtonColor: #05177d;
