@import "/src/theme/colors";
.personal-info {
  &-title {
    color: $tertiaryColor;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-block: 10px;
  }
  &-content {
    display: flex;
    justify-content: space-between;
    &-profile {
      width: 30%;
      display: flex;
      justify-content: center;
      &-image {
        display: flex;
        justify-content: center;
        height: 200px;
        width: 200px;
        position: relative;
        border-radius: 50%;
        background: white;
        &-text {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 65px;
          background: #3aadb842;
          color: black;
          border-radius: 50%;
        }
        & img {
          max-width: 200px;
          max-height: 200px;
          border-radius: 50%;
        }
        &-icon {
          position: absolute;
          bottom: 25px;
          right: 10px;
          background: white;
          display: flex;
          width: 30px;
          height: 30px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: 1px 2px 5px 0px rgb(143 143 143);
          & svg {
            color: $tertiaryColor;
          }
        }
      }
    }
    &-info {
      width: 65%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
      &-item {
        width: 47%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        &-label {
          font-weight: 500;
          color: gray;
          font-size: 14px;
        }
        &-input {
          background: white;
          box-shadow: 1px 1px 2px 1px rgb(205, 207, 219);
          padding-block: 5px;
          padding-inline: 10px;
          border-radius: 5px;
          color: black;
          & input {
            margin: 0;
            padding: 0;
            background-color: white;
            font-size: 16px;
          }
        }
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: end;
    margin-block-start: 20px;
    gap: 20px;
    & button {
      width: max-content;
    }
  }
}

@media screen and (max-width: 650px) {
  .personal-info {
    &-content {
      flex-direction: column;
      align-content: center;
      &-profile {
        width: 100%;
      }
      &-info {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .personal-info {
    &-content {
      &-info {
       &-item{
        width: 100%;
       }
      }
    }
  }
}
