@import '/src/theme/colors';
.app-navbar{
    height: 100%;
    width: 200px;
    background-color: #ffffff;
    position: fixed;
    overflow-y: auto;
    box-shadow: 1px 7px 10px 1px rgba(0, 0, 0, 0.1);
    z-index: 4;
    &::-webkit-scrollbar {
        width: 2px; 
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c0bbbb; 
        border-radius: 4px; 
      }
    &_logo {
        display: flex;
        flex-direction: row;
        padding: 20px;
        &-img img{
            width: 80%;
        }
        &-title{
            font-size: 28px;
            &-text{
                font-weight: 600;
                color: $fontColor;
            }
            &-subtext{
                font-weight: 200;
                letter-spacing: 2px;
                color: $fontColor;
            }
        }
    }
    &_body{
        // padding: 20px;
        &-lists{
            list-style: none;
            padding: 0%;

            // width increased in the list to exceed the sidebar width to get the interactive hover feel
            // width: 220px;
            &-list{
                padding: 15px 10px;
                border-radius: 10px;
            }
            &-list:hover{
                background-color: $buttonHoverColor;
            }
            &-list.selected{
                background-color: $secondaryColor;
            }
        }
        &-link{
            &-tabs {
                display: flex;
                &-img{
                    width: 20%;
                }
                &-text{
                    font-size: 18px;
                    padding-left: 15px;
                    font-weight: 500;
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    color: $fontColor;
                }
                &-text span{
                    font-size: 14px;
                }
            }
        }
    }
}
@media screen and (max-width: 1440px) {
    .app-navbar{
        &_body{
            &-lists{
                &-list{
                    padding: 15px 10px;
                }
            }
        }
    }
}
// @media screen and (max-width: 1440px){
//     .app-navbar{
//         width: 17%;
//         position: fixed;
//         overflow-y: scroll;

//         &_logo{
//             // padding: 0;
//             padding-inline-start: 15%;
//             padding-block: 8%;
//             width: 100%;
//             transition: transform 0.3s ease;
//             &-img{
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 padding: 2px;
//             }
//             &-img img{
//                 height: 100%;
//                 width: 50px;
//             }
//             &-title{
//                 line-height: 1.2rem;
//                 font-size: 1.25rem;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: center;
//                 padding-left: 4px;
//                 &-text{
//                     font-size: 100%;
//                 }
//                 &-subtext{
//                     letter-spacing: 0;
//                     font-size: 21px;
//                 }
//             }
//         }
//         &_logo:hover{
//             transform: scale(1.1);
//         }
//         &_body{
//             height: 80%;
//             &-lists{
//                 width: 100%;
//                 &-list{
//                     padding: 0.9rem;
//                 }
//                 &-list.selected{
//                     background-color: white;
//                     box-shadow: 1px 1px 15px 5px #edeffc;
//                 }
//                 &-list:hover{
//                     background-color: white;
//                     box-shadow: 1px 1px 15px 5px #edeffc;
//                 }

//             }
//             &-link{
//                 &-tabs{
//                     padding-inline-start: 1rem;
//                     gap: 2rem;
//                     &-img{
//                         width: 35px;
//                     }
//                     &-text{
//                         font-size: 1rem;
//                         padding: 0;
//                         display: flex;
//                         align-items: center;
//                     }
//                 }
//             }
//         }

//     }
// }


@media screen and (max-width: 1024px){
    .app-navbar{
        width: 18%;
        // position: fixed;
        // overflow-y: scroll;
        &_logo{
            // padding: 0;
            padding-inline-start: 10%;
            padding-block: 8%;
            width: 100%;
            transition: transform 0.3s ease;
            &-img{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px;
            }
            &-img img{
                height: 100%;
            }
            &-title{
                line-height: 1.2rem;
                font-size: 1.25rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 4px;
                &-text{
                    font-size: 100%;
                }
                &-subtext{
                    letter-spacing: 0;
                    font-size: 21px;
                }
            }
        }
        &_logo:hover{
            transform: scale(1.1);
        }
        &_body{
            height: 80%;
            &-lists{
                width: 100%;
                &-list{
                    padding: 0.9rem;
                }
                &-list.selected{
                    background-color: white;
                    box-shadow: 1px 1px 15px 5px #edeffc;
                }
                &-list:hover{
                    background-color: white;
                    box-shadow: 1px 1px 15px 5px #edeffc;
                }

            }
            &-link{
                &-tabs{
                    gap: 1rem;
                    &-img{
                        width: 35px;
                    }
                    &-text{
                        font-size: 1rem;
                        padding: 0;
                      
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 820px){
    .app-navbar{
        position: fixed;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        width: 65px;
        box-shadow: 1px 7px 10px 1px rgba(0, 0, 0, 0.1);
        transition: 0.5s;
        background-color: #ffffffde;
        z-index: 4;
        overflow-x: hidden;
        &_logo{
            // padding: 0;
            padding-inline-start: 10%;
            padding-block: 8%;
            width: 100%;
            transition: transform 0.3s ease;
            &-img{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px;
            }
            &-img img{
                height: 100%;
                width: 140px;
            }
            &-title{
                line-height: 1.2rem;
                font-size: 1.25rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 4px;
                &-text{
                    font-size: 100%;
                }
                &-subtext{
                    letter-spacing: 0;
                    font-size: 21px;
                }
            }
        }
        &_logo:hover{
            transform: scale(1.1);
        }

        &_body{
            height: 80%;
            &-lists{
                width: 100%;
                &-list{
                    padding: 0.9rem;
                }
                &-list.selected{
                    background-color: white;
                    box-shadow: 1px 1px 15px 5px #edeffc;
                }
                &-list:hover{
                    background-color: white;
                    box-shadow: 1px 1px 15px 5px #edeffc;
                }

            }
            &-link{
                &-tabs{
                    gap: 1rem;
                    &-img{
                        width: 35px;
                    }
                    &-text{
                        font-size: 1rem;
                        padding: 0;
                    }
                    &-text span{
                        font-size: 12px;
                    }
                }
            }
        }
        &_collapse-btn{
            background-color: transparent;
            border: none;
            font-size: 20px;
            margin-left: auto;
            cursor: pointer;
            color: #05167c;
        }
        &_collapse-btn:active{
            border-style: none;
            background: none;
        }

        
    }
    .responsive-hide{
        display: none;
    }
}
.animate{
    animation: spin-close 500ms ease forwards;
}
@keyframes spin-close {
    from {transform: rotate(0deg);}
    to {transform: rotate(2turn);}
}

@media screen and (max-width: 450px){
    .app-navbar{
        background-color: rgba(255, 255, 255);
        &_logo{
            &-img{
                display: none;
            }
        }
    }
}