@import "/src/theme/colors";
.email-subscription {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-body {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
    color: $primaryColor;
    border-radius: 10px;
    margin: 40px auto;
    max-width: 585px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-logo {
      padding: 10px;
      display: flex;
      &-img {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
      }
      &-img img {
        height: 100%;
        width: 120px;
      }
    }
    p {
      font-weight: 500;
      text-align: center;
    }
    &-title {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 600;
      text-align: center;
    }

    button {
      color: #fff;
      background-color: $primaryColor;
      font-size: 14px;
      font-weight: bold;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid $primaryColor;
      cursor: pointer;
      margin-bottom: 20px;
    }

    &-text {
      text-align: center;
      font-size: 15px;
      color: black;

      p {
        margin-bottom: 10px;
        font-weight: 500;
      }
    }

    .email-subscription-link {
      color: blue;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .email-subscription {
    &-body {
      width: 500px;
      padding: 50px;
      margin: 15px auto;
      &-title {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .email-subscription {
    &-body {
      width: 85%;
      padding: 30px;
      margin: 10px auto;
      &-title {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

.bottom {
  display: flex;
}
.top-text {
  color: black;
}
.success-text {
  text-align: center;
  color: $tertiaryColor;
  font-weight: 500;
}
.note-text {
  color: black;
}
