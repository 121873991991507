@import "/src/theme/colors";
.edit-profile-picture-popup {
  &-icon {
    position: absolute;
    bottom: 25px;
    right: 10px;
    background: white;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 1px 2px 5px 0px rgb(143 143 143);
    cursor: pointer;
    transition: transform 0.3s ease;
    & svg {
      color: $tertiaryColor;
    }
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
  // &-link {
  //   display: flex;
  //   justify-content: center;
  //   max-width: 200px;
  //   // max-height: 145px;
  //   cursor: pointer;
  //   transition: transform 0.3s ease;
  //   position: relative;
  //   &:hover &-overlay {
  //     opacity: 1;
  //     // transform: scale(1.1);
  //   }
  //   &-overlay {
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     opacity: 0;
  //     transition: 0.3s ease;
  //     border-radius: 100px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     background: #1e1e1e85;
  //     &-icon-fa-user {
  //       color: white;
  //       font-size: 40px;
  //       position: absolute;
  //       top: 50%;
  //       left: 50%;
  //       transform: translate(-50%, -50%);
  //       -ms-transform: translate(-50%, -50%);
  //       text-align: center;
  //     }
  //   }
  // }
  // &-link img {
  //   border-radius: 100px;
  //   width: 100%;
  //   height: 100%;
  //   max-width: 200px;
  //   max-height: 200px;
  // }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }
  &-body {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    z-index: 1001;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    &-picture {
      max-width: 100px;
      max-height: 120px;
    }
    &-picture img {
      border-radius: 100px;
      width: 100px;
      height: 100px;
    }
    &-title {
      font-size: 22px;
      font-weight: 600;
      margin-block-end: 10px;
      color: $tertiaryColor;
    }
    &-text {
      font-size: 14px;
      margin-block-end: 10px;
      color: black;
    }
    &-submit {
      color: #fff;
      background-color: $primaryColor;
      width: 100%;
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }
    &-close {
      color: #7e808d;
      background-color: #fff;
      width: 100%;
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }
    &-close:hover {
      color: $fontColor;
    }
  }
  &-body input {
    background: #edeffc;
    padding: 10px 15px;
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
    color: black;
  }
  &-overlay.popup-open {
    transform: translateY(0) scale(1);
  }

  &-body.popup-open {
    display: flex;
    align-items: center;
    transform: scale(1);
  }
  &-error {
    color: red;
    margin-block-end: 10px;
  }
}

.placeholder {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  background: #3aadb842;
  color: black;
  border-radius: 50%;
}
