.app-dashboard{
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: row;
    &_body{
        width: 80%;
        display: flex;
        background-color: #FAFBFF;
        height: 100vh;
    }
}
.app-mobile-header{
    z-index: 3;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-inline: 5%;
    align-items: center;
    position: fixed;
    background-color: #ffffff;
    height: 65px;
    &-hamburger{
        cursor: pointer;
                transition: all 0.3s ease-in-out;
                &-bar{
                    display: block;
                    width: 25px;
                    height: 2px;
                    margin: 5px auto;
                    -webkit-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    background-color: #04157C;
                }
    }
    &-logo{
        display: flex;
        &-img{
            width: 100px;
        }
        &-img img{
            width: 100%; 
            padding-block-start: 5px;

        }
        &-title{
            display: flex;
            flex-direction: column;
            line-height: 1;
            justify-content: center;
            &-text{
                font-size: 20px;
                color: #04157c;
                font-weight: 500;
            }
            &-subtext{
                font-size: 18px;
                color: #04157c;
                font-weight: 300;
                letter-spacing: 2px;
                padding-inline-start: 1px;
            }
        }

    }
    &-sidebar-button{
        align-items: center;
        gap: 3%;
        display: flex;
        &-triangle{
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #04157c;
        }
    }
    &-sidebar-button img{
        width: 25px;
    }
}


@media(max-width: 1440px){
    .app-dashboard_body{
        width: 80%;
    }
}

@media(max-width: 820px){
    .app-dashboard_body{
        width: 90%;
    }
}

@media(min-width: 451px){
    .app-dashboard{
        flex-direction: row;
        height: 100%;
    }
    .app-mobile-header{
        display: none;
    }
}
@media(max-width: 450px) {
    .app-dashboard_body{
        // margin-top: 40px;
        width: 100%;
        background-color: #ffffff;
    }
}
