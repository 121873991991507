.admin-classes {
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
      &-text {
        font-size: 24px;
        color: black;
        font-weight: 600;
      }
    }
  
    &-body {
      background-color: white;
      border-radius: 10px;
      padding: 2%;
      display: flex;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c0bbbb;
        border-radius: 4px;
      }
      &-table {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        min-width: 450px;
        &-head {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
  
          &-item {
            font-weight: bolder;
            flex: 1;
          }
        }
  
        &-body {
          display: flex;
          flex-direction: column;
  
          &-row {
            width: 100%;
            padding-block: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            border-top: 1px solid #d0d0d0;
  
            &-item {
              align-items: center;
              gap: 10px;
              display: flex;
              flex: 1;
              &-img {
                background-color: orange;
                height: 35px;
                width: 35px;
                border-radius: 10px;
              }
  
              &-text {
                display: flex;
                align-items: center;
                font-weight: bold;
              }
  
              &-icons {
                display: flex;
                width: 100%;
                gap: 35px;
  
                .icon-pencil,
                .icon-trash {
                  font-size: 14px;
                  cursor: pointer;
                  transition: transform 0.2s, color 0.2s;
                }
  
                .icon-pencil:hover {
                  transform: scale(1.2);
                  color: #007bff;
                }
  
                .icon-trash:hover {
                  transform: scale(1.2);
                  color: #dc3545;
                }
              }
            }
          }
        }
      }
    }
  }
  

  @media screen and (max-width: 500px) {
    .admin-classes{
      &-title{
        margin-block-end: 14px;
        &-text{
          font-size: 20px;
        }
      }
      &-body{
        padding:5%;
        &-table{
          gap: 10px;
          &-body{
            &-row-item{
              gap: 2px;
            }
          }
        }
      }
    }
  }
