@import '/src/theme/colors';
.app-subcontent {
  &_body {
    &-title {
      font-size: 30px;
      color: $fontColor;
      font-weight: 600;
    }
    &-content {
      &-chapter {
        padding-top: 20px;
        &-text,
        &-html {
          color: $fontColor;
          word-spacing: 2px;
          text-align: justify;
          line-height: 25px;
        }
        &-html p {
          font-weight: 400;
          font-size: 15px;
        }
        &-subheading{
          font-size: 22px;
          color: $fontColor;
          font-weight: 600;
          margin-block-end: 10px;
        }
        &-img{
          display: flex;
          justify-content: center;
          align-items: center;
          padding-block: 3%;
          img{
            max-width: 90%;
            height: auto;
            border-radius: 10px;
          }
        }
      }
    }
    &-next-chapter{
      margin-block-start: 20px;
      display: flex;
      justify-content: end;
      gap: 10px;
    }
    &-next-chapter button {
      width: 125px;
      height: 30px;
      color: #fff;
      background-color: #579bd6;
      font-size: 14px;
      font-weight: bold;
      border-radius: 5px;
      border: 1px solid #579bd6;
      outline: none;
      letter-spacing: 1px;
      cursor: pointer;
      padding-inline: 10px;
      padding-block: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    &-quiz {
      margin-block-start: 20px;
      display: flex;
      justify-content: end;
      gap: 5px;
    }
    // &-quiz button {
    //   width: 120px;
    //   height: 30px;
    //   color: #fff;
    //   background-color: $primaryColor;
    //   font-size: 14px;
    //   font-weight: bold;
    //   border-radius: 5px;
    //   border: 1px solid $primaryColor;
    //   outline: none;
    //   letter-spacing: 1px;
    //   cursor: pointer;
    //   padding-inline: 10px;
    //   padding-block: 5px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   gap: 5px;
    // }
    &-heading {
      font-size: 18px;
      color: $fontColor;
    }
  }
}

@media screen and (max-width:820px) {
  .app-subcontent{
    &_body{
      &-quiz button{
        font-size: 12px;
        letter-spacing: 0px;
        width: 110px;
        height: 30px;
      }
    }
  }
  
}