@import '/src/theme/colors';
.app-home{
    margin-inline-start: 200px;
    width: 100%;
    padding: 50px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 2px; 
    }
    &::-webkit-scrollbar-thumb {
        background-color: #c0bbbb; 
        border-radius: 4px; 
    }
    &_body{
        &-header{
            padding: 20px;
            background-color: $secondaryColor;
            border-radius: 10px;
            display: flex;
            box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
            &-text{
                color: $fontColor;
                width: 70%;
                &-icon{
                  width: 20px;
                }
            }
            &-text p{
                font-weight: 300;
            }
            &-img{
                width: 30%;
            }
            &-img img{
                width: 100%;
            }
            // & button{
            //   color: white;
            //   background-color: #579bd6;
            //   display: flex;
            //   font-size: 16px;
            //   gap: 5px;
            //   padding: 7px 20px;
            //   border-radius: 5px;
            //   border: none;
            //   cursor: pointer;
            // }
        }
        
        &-progress{
            display: flex;
            margin: 20px 0 0 0;
            justify-content: space-between;
            &-course{
                background-color: $secondaryColor;
                border-radius: 10px;
                box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
                padding: 15px;
                color: $fontColor;
                width: 35%;
                height: 200px;
                &-title{
                  display: flex;
                  height: 20%;
                  text-align: center;
                  font-size: 20px;
                  font-weight: 700;
                  justify-content: center;
                  align-items: center;
                }
                &-content{
                    height: 80%;
                    padding: 10px;
                    height: 130px;                
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 2px; 
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #c0bbbb; 
                        border-radius: 4px; 
                    }
                    &-details{
                        display: flex;
                        padding: 5px 10px;
                        justify-content: space-between;
                        &-name{
                            font-weight: 300;
                            margin-right: 20px;
                            font-size: 12px;
                            width: 50%;
                        }
                        &-bar{
                            width: 50%;
                            padding-top: 5px;
                        }
                    }
                    
                }
            }
            &-class{
                background-color: $secondaryColor;
                border-radius: 10px;
                box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
                padding: 10px;
                color: $fontColor;
                width: 25%;
                &-title{
                  text-align: center;
                  font-size: 20px;
                  font-weight: 700;
                  height: 20%;
                }
                &-content{
                    height: 80%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: start;
                    padding: 10px 10px;
                    &-data{
                        padding: 5px 0;
                        &-chapters{
                            font-weight: 700;
                            font-size: 30px;
                        }
                        &-total{
                            font-weight: 600;
                        }
                    }
                    &-completed{
                        color: #e67e00;
                    }

                }
            }
            &-quiz{
                background-color: $secondaryColor;
                border-radius: 10px;
                box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
                padding: 10px;
                color: $fontColor;
                width: 30%;
                &-title{
                  text-align: center;
                  font-size: 20px;
                  font-weight: 700;
                  height: 20%;
                }
                &-details {
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  height: 80%;
                  &-content{
                    &-data {
                      padding: 5px 0;
                      &-chapters {
                        font-weight: 700;
                        font-size: 30px;
                      }
                      &-total {
                        font-weight: 600;
                      }
                    }
                    &-completed {
                      color: #e67e00;
                      text-align: center;
                    }
                  }
                }
                &-details img{
                    width: 150px;
                }
            }
        }
        &-info{
            margin: 20px 0 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &-live{
                width: 48%;
                background-color: $secondaryColor;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
                height: 300px;
                &-header{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-block-end: 10px;
                    &-title{
                        font-size: 20px;
                        font-weight: 700;
                        color: $fontColor;
                    }
                    &-icon{
                        color: $fontColor;
                    }
                }
                &-classes{
                    padding-top: 15px;
                    height: 85%;
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                      width: 2px; 
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #c0bbbb; 
                        border-radius: 4px; 
                    }
                    &-details{
                        display: flex;
                        border-top: solid 1px #0000001c;
                        &-img{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-inline-start: 10px;
                            svg{
                                width: 35px;
                                color: $fontColor;
                            }
                        }
                        &-text{
                            padding: 8px;
                            margin-inline-start: 20px;
                            color: $fontColor;
                            font-size: 14px;
                            &-title{
                                font-weight: 700;
                            }
                        }
                        &-duration{
                            padding: 8px;
                            margin-inline-start: auto;
                            color: $fontColor;
                            font-size: 12px;
                        }
                        &-datetime{
                            padding: 8px;
                            margin-inline-start: 8px;
                            color: $fontColor;
                            font-size: 12px;
                        }
                    }
                    &-details:hover{
                        color: #ffffff;
                        background: linear-gradient(to right, #569cd6, rgb(89 161 215 / 26%));
                        border-radius: 10px;
                
                    }
                    &-details:hover &-details-img svg{
                            color: #ffffff;
                    }
                    &-details:hover &-details-text{
                            color: #ffffff;
                    }
                    &-details:hover &-details-duration{
                            color: #ffffff;
                    }
                    &-details:hover &-details-datetime{
                            color: #ffffff;
                    }
                }
                &-empty{
                    width: 100%;
                    width: 100%;
                    text-align: center;
                    padding-top: 80px;
                    color: $fontColor;
                }
            }
            &-announcements{
                width: 48%;
                background-color: $secondaryColor;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
                height: 300px;
                &-header{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-block-end: 10px;
                    &-title{
                        font-size: 20px;
                        font-weight: 700;
                        color: $fontColor;
                    }
                    &-icon{
                        color: $fontColor;
                    }
                }
                &-content{
                    padding-top: 15px;
                    height: 85%;
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                      width: 2px; 
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #c0bbbb; 
                        border-radius: 4px; 
                    }
                    &-details{
                        border-top: solid 1px rgba(0, 0, 0, 0.1098039216);
                        &-text{
                            padding: 8px;
                            color: $fontColor;
                            &-title{
                                font-size: 14px;
                                font-weight: 600;
                            }
                            &-desc{
                                font-size: 14px;
                            }
                        }
                    }
                }
                &-empty{
                    width: 100%;
                    text-align: center;
                    padding-top: 80px;
                    color: $fontColor;
                }
            }
        }
    }
}
@media screen and (max-width: 1024px) {
  .app-home {
    padding: 40px;
    &_body {
      &-progress {
        display: flex;
        margin: 40px 0;
        justify-content: space-between;
        &-course {
          border-radius: 10px;
          box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
          padding: 15px 10px;
          color: #04157c;
          width: 35%;
          &-title {
            font-size: 20px;
            font-weight: 700;
          }
          &-content {
            margin-top: 10px;
            padding: 4px;
            &-details {
              display: flex;
              padding: 5px 0;
              justify-content: space-between;
              &-name {
                // font-weight: 700;
                margin-right: 12px;
                font-size: 10px;
              }
              &-bar {
                align-items: center;
                padding-top: 5px;
              }
            }
          }
        }
      &-class {
        border-radius: 10px;
        box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
        padding: 15px;
        color: #04157c;
        width: 25%;
        &-title {
          font-size: 20px;
          font-weight: 700;
        }
        &-content {
          padding: 10px 10px;
          &-data {
            padding: 5px 0;
            &-chapters {
              font-weight: 700;
              font-size: 30px;
            }
            &-total {
              font-weight: 600;
            }
          }
          &-content {
            &-data {
              padding: 5px 0;
              &-chapters {
                font-weight: 700;
                font-size: 30px;
              }
              &-total {
                font-weight: 600;
              }
            }
            &-completed {
              color: #e67e00;
            }
          }
        }
      }
        &-quiz {
          border-radius: 10px;
          box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
          padding: 15px;
          color: #04157c;
          width: 35%;
          &-title {
            font-size: 20px;
            font-weight: 700;
          }
          &-details {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 10px;
            &-content {
              padding: 10px 10px;
              &-data {
                padding: 5px 0;
                &-chapters {
                  font-weight: 700;
                  font-size: 30px;
                }
                &-total {
                  font-weight: 600;
                }
              }
              &-completed {
                color: #e67e00;
              }
            }
          }
          &-details img {
            width: 150px;
          }
        }
      }
    }
    &-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-live {
        width: 40%;
        background-color: #edeffc;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
        height: 300px;
        overflow-y: auto;
        &-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &-title {
            font-size: 20px;
            font-weight: 700;
            color: #04157c;
          }
          &-icon {
            color: #04157c;
          }
        }
        &-classes {
          padding-top: 15px;
          &-details {
            display: flex;
            border-top: solid 1px #0000001c;
            &-img {
              margin-inline-start: 10px;
              img {
                width: 35px;
              }
            }
            &-text {
              padding: 8px;
              margin-inline-start: 20px;
              color: #04157c;
              font-size: 12px;
              &-title {
                font-weight: 700;
              }
            }
            &-duration {
              padding: 8px;
              margin-inline-start: auto;
              color: #04157c;
              font-size: 12px;
            }
            &-datetime {
              padding: 8px;
              margin-inline-start: 8px;
              color: #04157c;
              font-size: 12px;
            }
          }
          &-details:hover {
            background-color: #a873ff;
            border-radius: 10px;
            color: #ffffff;
            &-text {
              &-title {
              }
            }
          }
          &-details:hover &-details-text {
            color: #ffffff;
          }
          &-details:hover &-details-duration {
            color: #ffffff;
          }
          &-details:hover &-details-datetime {
            color: #ffffff;
          }
        }
        &-empty {
          width: 100%;
          width: 100%;
          text-align: center;
          padding-top: 80px;
          color: #04157c;
        }
      }
      &-announcements {
        width: 40%;
        background-color: #edeffc;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
        overflow-y: auto;
        &-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &-title {
            font-size: 20px;
            font-weight: 700;
            color: #04157c;
          }
          &-icon {
            color: #04157c;
          }
        }
        &-content {
          padding-top: 15px;
          &-details {
            border-top: solid 1px rgba(0, 0, 0, 0.1098039216);
            &-text {
              padding: 8px;
              color: #04157c;
              &-title {
                font-size: 16px;
                font-weight: 600;
              }
              &-desc {
                font-size: 14px;
              }
            }
          }
        }
        &-empty {
          width: 100%;
          text-align: center;
          padding-top: 80px;
          color: #04157c;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .app-home {
    margin-inline-start: 10%;
    width: 90%;
    padding: 40px 15px;
    display: flex;
    height: max-content;
    overflow-y: auto;
    &_body {
      width: 100%;
      display: flex;
      flex-direction: column;
      &-header {
        flex-wrap: wrap;
        display: flex;
        padding: 3.5%;
        &-text {
          width: 70%;
          flex-shrink: 1;
        }
        &-text h4 {
          font-weight: 500;
        }
        &-text p {
          font-weight: 400;
          font-size: 0.8rem;
          line-height: 1.2rem;
          margin-block-start: 3%;
        }
        &-img {
          width: 30%;
        }
      }
      &-progress {
        margin-block-start: 5%;
        &-course {
          display: flex;
          flex-direction: column;
          // padding: 3%;
          width: 35%;
          &-title {
            height: 20%;
            display: flex;
            font-size: 95%;
            font-weight: 650;
            text-align: center;
            line-height: 1;
            padding-inline: 5%;
          }
          &-content {
            height: 80%;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            padding-block-start: 5%;
            &-details {
              padding-block: 2%;
              &-name {
                font-size: 9px;
                font-weight: 300;
                width: 50%;
                margin-right: 5px;
              }
              &-bar {
                align-items: center;
                width: 50%;
                display: flex;
                padding: 3.5%;
              }
            }
          }
        }
        &-class {
          padding: 3%;
          display: flex;
          flex-direction: column;
          &-title {
            font-size: 95%;
            height: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &-content {
            padding: 0;
            display: flex;
            height: 80%;
            flex-direction: column;
            justify-content: space-around;
            // padding-block-start: 10%;
            &-data {
              align-items: center;
              padding: 0;
              display: flex;
              justify-content: space-evenly;
              &-chapters {
                font-size: 250%;
                font-weight: 500;
              }
              &-total {
                font-weight: 400;
              }
            }
            &-completed {
              text-align: center;
              font-size: 1em;
            }
          }
        }
        &-quiz {
          padding: 3%;
          &-title {
            font-size: 95%;
            height: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &-details {
            height: 80%;
          }
        }
        &-info {
          flex-direction: column;
          &-announcements {
            margin-top: 50px;
            width: 100%;
          }
          &-live {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .app-home {
    &_body {
      &-progress {
        &-course {
          width: 45%;
          padding: 2%;
          &-title {
            align-items: center;
          }
          &-content{
            &-details{
              &-name{
                font-size: 11px;
              }
            }
          }
        }
        &-class {
          width: 25%;
          padding: 2%;
          flex-direction: column;
          &-title {
            align-items: center;
          }
          &-content {
            &-data {
              flex-direction: column;
            }
          }
        }
        &-quiz {
          width: 25%;
          padding: 2%;
          &-title {
            align-items: center;
          }
          &-details{
            flex-direction: column;
            &-content{
              padding: 0px;
              &-data{
                padding: 0px;
                font-size: 14px;
              }
              &-completed{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 510px) {
  .app-home {
    margin-inline-start: 15%;
    &_body {
      &-header {
        &-text {
          width: 100%;
        }
        &-img {
          display: none;
        }
      }
      &-progress {
        display: inline-block;
        margin: 20px 0;
        &-course {
          width: 100%;
          margin-bottom: 20px;
          padding: 5%;
          &-title {
            padding-inline: 0;
          }
        }
        &-class {
          display: inline-block;
          width: 48%;
          margin-bottom: 0;
          height: 185px;
          padding: 5%;
          &-title {
            justify-content: flex-start;
          }
          &-content {
            &-data {
              gap: 5px;
              justify-content: center;
              flex-direction: row;
              align-items: center;
            }
          }
        }
        &-quiz {
          width: 48%;
          float: right;
          height: 185px;
          margin-bottom: 0;
          padding: 5%;
          &-title {
            justify-content: flex-start;
          }
        }
      }
      &-info {
        flex-direction: column;
        &-live {
          width: 100%;
          height: 280px;
        }
        &-announcements {
          margin-top: 25px;
          width: 100%;
          height: 280px;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .app-home {
      margin-inline-start: 0;
      width: 100%;
      margin-top: 40px;
      &_body{
          &-header{
              &-text h4{
                  font-size: 14px;
              }
              &-text p{
                letter-spacing: 0px;
                font-size: 13px;
              }
              &-img{
                  width: 45%;
              }
          }
          &-progress{
            &-course{
              &-content{
                padding-block-start: 0%;
                &-details{
                  &-bar{
                    padding: 5px 5px 0;
                  }
                }
              }
            }
            &-class {
              padding: 3%;
              display: inline-block;
              flex-direction: column;
              &-title {
                font-size: 95%;
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &-content {
                padding: 0;
                display: flex;
                height: 80%;
                justify-content: space-around;
                flex-direction: column;
                &-data {
                  align-items: center;
                  padding: 0;
                  display: flex;
                  justify-content: space-evenly;
                  &-chapters {
                    font-size: 250%;
                    font-weight: 500;
                  }
                  &-total {
                    font-weight: 400;
                  }
                }
                &-completed {
                  text-align: center;
                  font-size: 1em;
                }
              }
            }
            &-quiz {
              padding: 3%;
              &-title {
                font-size: 95%;
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &-details {
                height: 80%;
              }
            }
          }
          // &-info {
          //   flex-direction: column;
          //   &-announcements {
          //     margin-top: 50px;
          //     width: 100%;
          //   }
          // }
        }
      }
    }
    // @media screen and (min-width: 769px){
    //   .app-home{
    //     margin-inline-start: 200px;
    //   }
    // }


