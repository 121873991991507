.admin-toggle-switch {
    width: 75px;            // Adjusted width for space for text
    height: 30px;           // Adjusted height
    border-radius: 30px;    // Rounded corners for the switch
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    position: relative;     // To position the slider and text
    padding: 0 4px;         // Added padding for text spacing
  }
  
  .admin-toggle-switch__slider {
    width: 20px;            // Width of the slider
    height: 20px;           // Height of the slider
    border-radius: 50%;     // Rounded slider
    background-color: white; // Slider background color
    transition: transform 0.3s ease-in-out;
    position: absolute;
    top: 5px;               // Center vertically
    left: 5px;              // Initial position
    transform: translateX(0); // Default (left) position
  }
  
  .admin-toggle-switch.checked .admin-toggle-switch__slider {
    transform: translateX(45px); // Move the slider to the right when checked
  }
  
  .toggle-text {
    font-size: 12px;        // Adjust text size if needed
    color: white;           // Text color
    position: absolute;
    pointer-events: none;   // Ensure text doesn't interfere with clicks
  }
  
  .toggle-text.left {
    left: 8px;              // Position for "admin" text
  }
  
  .toggle-text.right {
    right: 8px;             // Position for "user" text
  }
  