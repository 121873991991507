@import "/src/theme/colors";

.file_upload_popup {
  &-button {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: $primaryButtonColor;
    color: white;
  }
  &-button:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(191 198 207 / 53%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  &-content {
    color: black;
    display: flex;
    flex-direction: column;
    background-color: #e8ebf0;
    border-radius: 10px;
    padding: 20px;
    width: 50%;
    max-width: 485px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    &-close_button {
      color: rgb(184 187 202);
      text-align: end;
      cursor: pointer;
      &:hover {
        color: grey;
      }
    }
    &-title {
      font-weight: 500;
      font-size: 18px;
      margin-block-end: 10px;
    }
    &-body {
      background: $primaryBackgroundColor;
      border-radius: 5px;
      border: 1px dashed #9f9f9f;
      padding: 10px;
      &-top {
        display: flex;
        justify-content: end;
        &-download_button {
          display: flex;
          border-radius: 5px;
          padding-inline: 10px;
          padding-block: 5px;
          gap: 5px;
          border: 1px solid $primaryButtonColor;
          cursor: pointer;
          color: $primaryButtonColor;
          &:hover {
            background-color: $primaryButtonColor;
            color: $primaryBackgroundColor;
          }
          &-text {
            font-size: 12px;
          }
          &-icon {
            font-size: 12px;
          }
        }
      }
      &-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block-end: 30px;
        margin-block-start: 10px;
        &-icon {
          color: $primaryButtonColor;
          font-size: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-drag_text {
          font-size: 18px;
          font-weight: 500;
        }
        &-subtext {
          margin-block-start: 5px;
          margin-block-end: 10px;
        }
        &-button {
          background: $primaryButtonColor;
          padding-block: 7px;
          padding-inline: 20px;
          border-radius: 5px;
          font-size: 14px;
          color: $primaryBackgroundColor;
          cursor: pointer;
          &:hover {
            background-color: $primaryBackgroundColor;
            color: $primaryButtonColor;
            border: 1px solid $primaryButtonColor;
          }
        }
      }
      &-uploaded {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-block: 10%;
        &-file {
          color: $primaryButtonColor;
          display: flex;
          gap: 5px;
          cursor: pointer;
          padding-block: 15px;
          padding-inline: 30px;
          border-radius: 10px;
          background: #3aadb81a;
          &-icon {
            font-size: 35px;
            display: flex;
            align-items: center;
          }
          &-info {
            display: flex;
            flex-direction: column;
            align-items: start;
            &-name {
              color: black;
              font-weight: 500;
            }
            &-size {
              color: gray;
              font-size: 14px;
            }
          }
        }
        &-action {
          display: flex;
          margin-block-start: 20px;
          gap: 20px;
          &-upload_button {
            display: flex;
            gap: 5px;
            border-radius: 5px;
            padding-block: 5px;
            padding-inline: 10px;
            cursor: pointer;
            border: 1px solid $primaryButtonColor;
            color: $primaryButtonColor;
            &:hover {
              border: none;
              background-color: $primaryButtonColor;
              color: $primaryBackgroundColor;
            }
          }
          &-cancel_button {
            display: flex;
            gap: 5px;
            border-radius: 5px;
            padding-block: 5px;
            padding-inline: 10px;
            cursor: pointer;
            border: 1px solid rgb(153, 0, 0);
            color: rgb(153, 0, 0);
            &:hover {
              border: none;
              background-color: rgb(153, 0, 0);
              color: $primaryBackgroundColor;
            }
          }
        }
      }
    }
  }
}

//   $primaryButtonColor

@media screen and (max-width: 820px) {
  .file_upload_popup {
    &-content {
      width: 80%;
      &-title {
        font-size: 18px;
      }
      &-text {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .file_upload_popup {
    &-button {
      // width: 100%;
    }
  }
}
