.course-progressbar{
    &-container-styles{
        height: 8px;
        width: 100%;
        border-radius: 10px;
        background-color: #e67e00;
    }
    &-filler-styles {
        height: 100%;
        width: 0;
        border-radius: 10px;
        border-radius: 'inherit';
        &-progress{
            display: block;
            height: 100%;
            border-radius: 10px;
            background-color: #2ac99d;
            border-radius: 'inherit';
            text-align: right;
            animation: progressBar 3s ease-in-out;
            animation-fill-mode:both; 
        }
    }
}

@keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
  }

@media(max-width:450px){
    .course-progressbar{
        &-container-styles{
            height: 8px;
        }
    }
}