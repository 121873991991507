@import "/src/theme/colors";

.add_trainee_modal {
  &-button {
    font-size: 16px;
    width: 140px;
    height: 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: $primaryColor;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &-icon {
      font-size: 24px;
    }
    &-text {
      font-size: 14px;
    }
  }
  &-button:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(191, 198, 207, 0.53);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  &-content {
    border-radius: 15px;
    color: black;
    display: flex;
    flex-direction: column;
    background-color: rgb(232 235 240);
    padding: 30px;
    width: 50%;
    max-width: 400px;
    max-height: 80%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
    &-close_button {
      color: rgb(184 187 202);
      text-align: end;
      cursor: pointer;
      &:hover {
        color: grey;
      }
    }
    &-title {
      font-weight: bolder;
      font-size: 20px;
      margin-block-end: 10px;
    }
    &-bottom {
      &-agreement {
        gap: 7px;
        display: flex;
        &-checkbox input {
          margin: 0;
          padding: 0;
        }
        &-text {
          font-size: 11px;
        }
      }
      &-buttons {
        margin-block-end: 5px;
        margin-block-start: 20px;
        display: flex;
        gap: 20px;
        &-submit {
          font-weight: 600;
          width: 130px;
          height: 30px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          background-color: $primaryColor;
          color: white;
        }
        &-cancel {
          font-weight: 600;
          width: 80px;
          height: 30px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          background-color: rgb(248, 248, 248);
          color: black;
        }
      }
    }
  }
  @media screen and (max-height: 600px) {
    &-content {
      max-height: 70vh; /* Set a maximum height for smaller screens */
      overflow-y: auto; /* Enable vertical scrolling if content overflows */
    }
  }

  @media screen and (max-height: 400px) {
    &-content {
      max-height: 50vh; /* Adjust max-height further for even smaller screens */
    }
  }
  &-content input {
    margin: 0;
    margin-block-start: 7px;
    margin-block-end: 15px;
    background: white;
    padding: 10px 15px;
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
  }
  &-content label {
    font-weight: 500;
    font-size: 14px;
    margin-block-end: 5px;
  }
  // &-content-bottom{
  //   display: flex;
  //   justify-content: space-between;
  //   &-left{
  //     width: 60%;
  //   }
  //   &-right{
  //     width: 30%;
  //   }
  // }
}
#description {
  min-height: 100px;
  overflow-y: scroll;
}

@media screen and (max-width: 450px) {
  .modal-content {
    width: 80%;
  }
}

.inputfile-box {
  position: relative;
}

.inputfile {
  display: none;
}

.container {
  display: inline-block;
  width: 100%;
}

.file-box {
  font-weight: lighter;
  font-size: 15px;
  display: inline-block;
  width: 100%;
  padding: 5px 0px 5px 5px;
  box-sizing: border-box;
  height: calc(2rem - 2px);
  background: white;
}

.file-button {
  background: rgb(137 147 205);
  padding-inline: 10px;
  padding-block: 8px;
  position: absolute;
  top: 0px;
  right: 0px;
}

#description {
  margin-block-end: 15px;
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  background: white;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
}

.locality {
  display: flex;
  gap: 15px;
}

@media screen and (max-width: 820px) {
  .add_trainee_modal {
    &-button {
      height: 30px;
      gap: 5px;
      &-icon {
        font-size: 20px;
      }
      &-text {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .add_trainee_modal {
    &-content {
      width: 85%;
      max-height: 80%;
      padding: 5%;
      &-close_button {
        font-size: 14px;
      }
      &-title {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .add_trainee_modal {
    &-button {
      width: 100%;
    }
  }
}
.error {
  color: red;
  margin-inline-start: 3px;
}
