.select-menu-container {
    box-shadow: 0px 1px 2px 2px #d9d9d9;
    height: 65px;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    cursor: pointer;
    & span {
      display: flex;
      justify-content: space-between;
    }
    & label {
      font-weight: 500;
    }
    svg {
      transition: transform 0.3s ease;
    }
  }
  
  .select-menu-list {
    position: absolute;
    box-shadow: 0px 1px 2px 2px #d9d9d9;
    border-radius: 10px;
    width: 100%;
    left: 0;
    top: 70px;
    background: white;
    transition: opacity 0.3s ease, transform 0.3s ease;
    max-height: 120px;
    overflow: auto;
    z-index: 1;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
    // opacity: 0;
    // transform: translateY(-10px);
    &.open {
    //   opacity: 1;
    //   transform: translateY(0);
    }
  }
  
  .select-menu-item {
    padding: 10px;
    border-bottom: 1px solid #80808036;
    &:hover {
      background: #f0f0f0; // Replace with your desired hover color
      cursor: pointer;
    }
  }

@media screen and (max-width:450px) {
  .select-menu-container{
    width: 100%;
  }
}
  