@import '/src/theme/colors';
.app{
    &-course{
        // padding: 50px;
        // overflow-y: scroll;
        &_body{
            &-title{
                font-size: 30px;
                color: $fontColor;
            }
            &-content{
                padding-top: 10px;
                color: $fontColor;
                word-spacing: 2px;
                text-align: justify;
         
                &-text{
                    padding-top: 20px;
                }
                // &-partC{
                //     padding-top: 20px;
                // }
                // &-partD{
                //     padding-top: 20px;
                // }
            }
        }
    }
}