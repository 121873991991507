@import '/src/theme/colors';
.app-parent-container {
    &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c0bbbb;
        border-radius: 4px;
      }
}

.app{
    &-subject{
        &_body{
            &-title{
                color: $fontColor;
                font-size: 30px;
            }
            &-title h2{
                font-weight: 600;
            }
            &-content{
                padding: 20px;
                margin: 30px 0;
                width: 100%;
                background-color: $secondaryColor;
                border-radius: 10px;
                display: flex;
                box-shadow: 1px 7px 11px 4px rgb(0 0 0 / 10%);
                cursor: pointer;
                color: $fontColor;
                &-number{
                    width: 15%;
                }
                &-info{
                    width: 65%;
                    &-title{
                        font-size: 20px;
                        font-weight: 600;
                        padding-bottom: 10px;
                    }
                    &-desc p{
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: justify;
                    }
                }
                &-progress{
                    width: 20%;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            &-content:hover{
                // background-color: #fff;
                background-color: #a873ff;
                box-shadow: 1px 7px 11px 4px rgb(82 0 250 / 14%);
                color: #fff;
            }
        }
    }
    &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c0bbbb;
        border-radius: 4px;
      }
}
@media screen and (max-width: 820px) {
    .app-subject{
        &_body{
            &-title {
                font-size: 25px;
            }
            &-content{
                &-number{
                    font-size: 14px;
                }
                &-info{
                    &-title{
                        font-size: 16px;
                    }
                    &-desc p{
                        font-size: 10px;
                        line-height: 15px;
                    }
                }
            }
        }
        
        
    }
}

@media screen and (max-width: 620px) {
    
    .app-subject{
        &_body{
            &-title {
                font-size: 20px;
            }
            &-content{
                padding: 8px;
                &-number{
                    width: 10%;
                    font-size: 12px;
                    writing-mode: vertical-rl;
                    transform: scale(-1);
                    display: flex;
                    justify-content: center;
                }
                &-progress {
                    width: 25%;
                    align-items: center;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .app-subject{
        &_body{
            &-content{
                &-info{
                    padding: 10px 0;
                    &-title{
                        padding-bottom: 10px;
                    }
                    p{
                        margin: 0;
                        font-size: 9px;
                    }
                }
            }
        }
    }
}

// @media screen and (min-width: 769px) {
//     .app-parent-container {
//         margin-inline-start: 200px;
//     }
// }
// @media screen and (max-width: 820px){
//     .app{
//         &-parent-container{
//             margin-inline-start: 10%;
//             padding: 5%;
//         }
//         &-subject{
//             &_body{
//                 display: flex;
//                 flex-direction: column;
//                 &-title{
//                     font-size: larger;
//                     font-weight: 700;
//                 }
//                 &-content{
//                     box-shadow: 1px 7px 11px 4px rgba(0, 0, 0, 0.1);
//                     padding: 3%;
//                     margin-block: 2%;
//                     justify-content: space-between;
//                     width: auto;
//                     background-color: edeffc;
//                     &-number{  
//                         width: 18%;
//                         font-size: 14px;
//                     }
//                     &-info{
//                         display: flex;
//                         width: 80%;
//                         flex-direction: column;
//                         &-title{
//                             font-weight: 700;
//                             font-size: small;
//                             padding: 0;
//                             margin-block-end: 1%;
//                         }
//                         &-desc{
//                             font-size: 80%;
//                             font-weight: 400;
//                             line-height: 1.2;
//                         }
//                     }
//                     &-progress{
//                         width: 20%;
//                     }
//             }
//             &-content:hover{
//                 background-color: #fff;
//                 box-shadow: 1px 1px 15px 5px #edeffc;
//                 color: $primaryColor;
//             }
//         }
//     }
// }
// }
