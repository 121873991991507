.stripe_setup_success_page {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  align-items: center;
  margin: auto;
  background: #e4e7f5;
  &-body {
    background: white;
    border: 1px solid #0000001f;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    height: 80%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    gap: 20px;
    &-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      text-align: center;
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-text {
        font-weight: 600;
        font-size: 26px;
      }
    }
    &-bottom {
      & button {
        font-size: 16px;
        width: 170px;
        height: 30px;
        border-radius: 5px;
        border: none;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        background: #1ccda6;
        color: white;
      }
    }
  }
}
