@import '/src/theme/colors';
.admin-course {
  &-title {
    display: flex;
    align-items: center;
    margin-block: 20px;
    justify-content: space-between;
    &-text {
      font-size: 24px;
      color: $titleColor;
      font-weight: 600;
    }
    &-search-box { 
      height: 40px;
      width: 40%;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-item {
      font-family: Arial, Helvetica, sans-serif;
      color: rgb(153, 153, 153);
      border-radius: 10px;
      background-color: white;

      &_header {
        height: 20%;
        display: flex;
        justify-content: space-around;
        padding-block: 2%;
        padding-inline: 2%;
        border-block-end: 1px solid rgb(228, 231, 245);

        &_icon {
          width: 10%;
          height: 35px;
          width: 35px;
          border-radius: 5px;
          background-color: orange;

          img {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &_title {
          display: flex;
          justify-content: center;
          flex-direction: column;
          color: black;
          gap: 3px;
          width: 80%;

          &_name {
            font-size: 14px;
            font-weight: 600;
          }

          &_module {
            font-size: 12px;
            color: rgb(153, 153, 153);
          }
        }

        &_status {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 5px;
          width: 10%;

          &_text {
            font-size: 14px;
            color: rgb(35, 186, 67);
          }
        }
      }

      &_details {
        display: flex;
        padding: 2%;
        height: 70%;

        &_description {
          width: 50%;

          &_title {
            font-weight: 600;
            font-size: 14px;
            padding: 0;
            margin: 0 0 5px 0;
          }

          &_text {
            line-height: 1.5;
            font-size: 14px;
          }
        }

        &_data {
          width: 50%;
          display: flex;
          flex-direction: column;
          padding-inline: 3%;
          gap: 10px;

          &_col {
            display: flex;
            justify-content: space-between;
            padding-inline: 3%;
            font-size: 13px;
            align-items: center;
            width: 100%;
            height: 40px;
            border: none;
            border-radius: 4px;
            background-color: rgb(248, 248, 248);
            color: black;

            &_key {
              color: rgb(138, 138, 138);
            }

            &_value {
              color: black;
              font-weight: 600;
            }
          }
        }
      }

      &_action {
        display: flex;
        justify-content: flex-end;
        padding-block-end: 3%;
        padding-inline: 3%;
        height: 10%;
        gap: 1%;

        button {
          font-weight: 600;
          width: 80px;
          height: 30px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        &_delete {
          background-color: rgb(248, 248, 248);
          color: black;
        }

        &_edit {
          background-color: $primaryColor;
          color: white;
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 14px;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgb(235, 235, 235);
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 34px;

          &:before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 3px;
            bottom: 1px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
          }
        }

        input:checked + .slider:before {
          background-color: rgb(35, 186, 67);
          -webkit-transform: translateX(12px);
          -ms-transform: translateX(12px);
          transform: translateX(12px);
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .admin-course {
    &-title {
      margin-block-end: 16px;
      &-text {
        font-size: 21px;
      }
    }
    &-list {
      &-item{
        &_header {
          padding-inline: 2%;
          padding-block: 1%;
          &_icon {
            height: 32px;
            width: 32px;
          }
        }
        &_details{
          flex-direction: column;
          padding-inline: 3%;
          padding-block: 2%;
          &_description{
            width: 100%;
            &_text{
              width: 100%;
            }
          }
          &_data{
            width: 100%;
            padding-inline: 0px;
            padding-block-start: 5px;
          }
        }
      }
      
    }
  }
}
@media screen and (max-width: 500px) {
  .admin-course {
    &-title {
      margin-block-end: 14px;
      &-text {
        font-size: 20px;
      }
    }
    &-list {
      &-item{
        &_header {
          padding-inline: 4%;
          padding-block: 2%;
          &_icon {
            height: 30px;
            width: 30px;
          }
        }
        &_details{
          padding-inline: 4%;
          &_description{
            width: 100%;
            &_text{
              width: 100%;
            }
          }
          &_data{
            width: 100%;
            padding-inline: 0px;
            padding-block-start: 5px;
          }
        }
      }
      
    }
  }}
