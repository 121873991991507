@import "/src/theme/colors";
.admin-dashboard-loader{
  height: 80vh;
}
.admin-dashboard-elements {
  &-title {
    font-size: 24px;
    color: $fontColor;
    font-weight: 600;
    position: sticky;
    top: 40px;
    color: $titleColor;
  }
  &_body {
    padding-top: 20px;
    &-appInfo {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      &-container {
        flex: 1;
        padding: 10px 20px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 1px rgb(205, 207, 219);
        display: flex;
        justify-content: space-between;
        &-data {
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          &-count {
            font-size: 30px;
            padding-top: 10px;
            font-weight: 600;
          }
        }
        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px;
          border-radius: 10px;
          width: 30%;
        }
        &-icon img {
          width: 40%;
        }
      }
    }
    &-cqcInfo {
      margin-top: 20px;
      box-shadow: 0px 0px 10px 1px rgb(205, 207, 219);
      // padding: 10px 20px;
      background-color: #ffffff;
      border-radius: 10px;
      &-title {
        font-size: 18px;
        font-weight: 600;
        padding: 20px;
      }
      &-details {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        &-ratings {
          width: 40%;
          &-title {
            padding-inline-start: 20px;
            margin-bottom: 10px;
            font-weight: 600;
          }
          &-body {
            &-list {
              padding: 0;
              list-style: none;
              margin: 0;
              &-item {
                display: flex;
                justify-content: space-between;
                padding: 20px;
                border-top: 1px solid #05167c2e;
                height: 75px;
                align-items: center;
                &-key {
                  width: 40%;
                }
                &-value {
                  width: 60%;
                  text-align: center;
                  padding: 6px 15px;
                  border-radius: 5px;
                  text-align: left;
                  display: flex;
                  align-items: center;
                  p {
                    margin: 0;
                    margin-inline-start: 15px;
                    font-weight: 400;
                  }
                }
              }
              &-item:nth-child(1) {
                border: none;
              }
            }
          }
        }
        &-ratingbar {
          width: 20%;
          display: flex;
          align-items: center;
          border-radius: 5px;
          font-size: 32px;
          font-weight: 600;
          justify-content: center;
          padding: 0;
          text-align: center;
        }
        &-desc {
          width: 40%;
          &-list {
            padding: 0;
            list-style: none;
            margin: 0;
            margin-block-start: 28px;
            &-item {
              display: flex;
              justify-content: space-between;
              padding: 20px;
              border-top: 1px solid #05167c2e;
              height: 75px;
              align-items: center;
              &-key {
                width: 40%;
              }
              &-value {
                width: 60%;
                text-align: center;
                padding: 8px 15px;
                background-color: #cbf7d8;
                border-radius: 5px;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            &-item:nth-child(1) {
              border: none;
            }
          }
        }
      }
    }
    &-topInfo {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      &-courses,
      &-trainees {
        flex: 1;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 1px rgb(205, 207, 219);
        border-radius: 10px;
        // padding: 20px;
        &-title {
          font-size: 18px;
          font-weight: 600;
          padding: 20px;
          display: flex;
          justify-content: space-between;
        }
        &-data {
          &-list {
            list-style: none;
            padding: 0;
            margin: 0;
            &-item {
              display: flex;
              justify-content: space-between;
              padding: 12px 20px;
              align-items: center;
              border-top: 1px solid #05167c2e;
              &-icon {
                border-radius: 5px;
                background-color: antiquewhite;
                width: 40px;
                height: 40px;
                text-align: center;
                align-content: center;
              }
              &-title {
                width: 80%;
              }
              &-info {
                font-weight: 600;
                display: flex;
                flex-direction: column;
                text-align: end;
                &-count {
                }
                &-desc {
                  font-weight: 100;
                  font-size: 12px;
                }
              }
            }
          }
          &-empty{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &-message{
              font-weight: 500;
            }
            & p{
              margin-block-start: 5px;
              color: black;
            }
            & img {
              max-width: 100px;
            }
          }
        }
        
      }
    }
    &-progressInfo {
      margin: 20px 0 40px;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px 1px rgb(205, 207, 219);
      border-radius: 10px;
      padding: 20px;
      &-title {
        font-size: 18px;
        font-weight: 600;
      }
      &-data {
        &-container {
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          &-title {
            align-content: center;
            width: 10%;
          }
          &-bar {
            width: 80%;
            align-content: center;
          }
          &-completed {
            width: 10%;
            text-align: end;
            display: flex;
            flex-direction: column;
            &-percent {
              font-size: 20px;
              font-weight: 700;
            }
            &-count {
              font-size: 12px;
              font-weight: 200;
            }
          }
        }
        &-empty{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &-message{
            font-weight: 500;
          }
          & p{
            margin-block-start: 5px;
            color: black;
          }
          & img {
            max-width: 100px;
          }
        }
      }
    }
  }
}

.outstanding {
  color: #2b9448;
  background-color: #ccf7d8;
}
.no-rating {
  color: #8c8c8c;
  background-color: #eeeeee;
}
.good {
  color: #2b9448;
  background-color: #ccf7d8;
}
.inadequate {
  color: #f71616;
  background-color: #ffdbdb;
}
.improvement {
  color: #fdb01b;
  background-color: #fff0d3;
}
.trainee-icon {
  background-color: #ccf4f7;
}
.course-icon {
  background-color: #ccf7d8;
}
.credit-icon {
  background-color: #fff0d3;
}
.plan-icon {
  background-color: #fbe7ff;
}
.local-authority {
  background-color: #ccf7d8;
  color: #067d27;
}
.last-inspection {
  background-color: #ccf4f7;
  color: #16b2bb;
}
.last-report {
  background-color: #fff0d3;
  color: #fdb01b;
}
.current-rating {
  background-color: #fbe7ff;
  color: #d626d9;
}
.reports {
  background-color: #ffebeb;
  color: #f71616;
}
.registration-date {
  background-color: #fff1e0;
  color: #ff6d03;
}
.firstItem {
  background-color: #ccf4f7;
  color: #16b2bb;
}
.secondItem {
  background-color: #fbe7ff;
  color: #d626d9;
}
.thirdItem {
  background-color: #ccf7d8;
  color: #067d27;
}

@media screen and (max-width: 1330px) {
  .trainee-icon,
  .course-icon,
  .credit-icon,
  .plan-icon {
    margin: 0;

    height: 70px;
  }
  .admin-dashboard-elements {
    &_body {
      &-appInfo {
        &-container {
          &-data {
            &-count {
              padding: 0;
              font-size: 22px;
            }
          }
          &-icon {
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1150px) {
  .trainee-icon,
  .course-icon,
  .credit-icon,
  .plan-icon {
    margin: 0;
    width: 90%;
    height: 80px;
  }
  .admin-dashboard-elements {
    &_body {
      &-appInfo {
        flex-wrap: wrap;
        &-container {
          padding: 2%;
          flex-direction: column-reverse;
          align-items: center;
          gap: 5px;
          width: 24%;
          margin-block-end: 10px;
          &-data {
            width: 100%;
            text-align: center;
            &-title {
              font-size: 14px;
            }
            &-count {
              padding: 0;
              font-size: 20px;
            }
          }
          &-icon {
          }
        }
      }
      &-cqcInfo {
        &-title {
          border-bottom: 1px solid rgba(5, 22, 124, 0.1803921569);
        }
        &-details {
          flex-wrap: wrap;
          &-ratings {
            width: 100%;
          }
          &-ratingbar {
            width: 50%;
            justify-content: center;
            padding: 0;
          }
          &-desc {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .admin-dashboard-elements-title {
    font-size: 24px;
    color: #05167c;
    font-weight: 600;
    position: sticky;
    top: 18px;
  }
  // Need to change the naming structure
  .admin-dashboard-elements_body-topInfo-courses-title, .admin-dashboard-elements_body-topInfo-trainees-title{
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
  }
  .admin-dashboard-elements_body-topInfo-courses-data-list-item-title, .admin-dashboard-elements_body-topInfo-trainees-data-list-item-title {
    width: 65%;
  }
  .admin-dashboard-elements_body-progressInfo-data-container-bar {
    width: 70%;
  }

}
@media screen and (max-width: 535px) {
  .trainee-icon,
  .course-icon,
  .credit-icon,
  .plan-icon {
    margin: 0;
    width: 90%;
    height: 80px;
  }
  .admin-dashboard-elements {
    padding: 0 20px;
    &-title {
      font-size: 18px;
      position: static;
    }
    &_body {
      &-appInfo {
        flex-wrap: wrap;
        &-container {
          padding: 2%;
          flex-direction: column-reverse;
          align-items: center;
          gap: 5px;
          width: 48%;
          margin-block-end: 10px;
          &-data {
            width: 100%;
            text-align: center;
            &-title {
              font-size: 14px;
            }
            &-count {
              padding: 0;
              font-size: 20px;
            }
          }
          &-icon {
          }
        }
      }
      &-cqcInfo {
        &-title {
        }
        &-details {
          
          &-ratings {
            font-size: 14px;
            &-title{
                padding-inline-start: 10px;
            }
          }
          &-ratingbar {
            font-size: 24px;
            width: 100%;
            height: 70px;
          }
          &-desc {
            font-size: 14px;
            width: 100%;
            &-list{
                margin: 0;
            }
          }
        }
      }
      &-topInfo{
        flex-wrap: wrap;
        gap: 20px;
        &-courses{
            width: 100%;
            &-data{
              &-list{
                &-item{
                  padding-inline: 5%;
                }
              }
            }
        }
        &-trainees{
            width: 100%;
        }
      }
      &-progressInfo{
        &-title{
          border-bottom: 1px solid rgba(5, 22, 124, 0.1803921569);
        }
        &-data-container-bar{
          width: 40%;
        }
      }
    }
  }
}
