.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(181 199 230 / 68%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  &_container {
    color: black;
    display: flex;
    flex-direction: column;
    background-color: #e8ebf0;
    padding: 30px;
    width: 50%;
    max-width: 400px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    border-radius: 10px;
    max-height: 80%;
    position: relative;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
    &-close_button {
      color: rgb(184 187 202);
      text-align: end;
      cursor: pointer;
      &:hover {
        color: grey;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .popup-modal {
    &_container {
      width: 80%;
    }
  }
}
@media screen and (max-width: 450px) {
  .popup-modal {
    &_container {
      width: 95%;
    }
  }
}
